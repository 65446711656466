import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "store/store";
import { IUser } from "types/admin/people";

interface IInitialState {
  users: {
    data: IUser[];
    total: number;
  };
}

const initialState: IInitialState = {
  users: {
    data: [],
    total: 0,
  },
};

const signupRequestsSlice = createSlice({
  name: "admin/signupRequests",
  initialState,
  reducers: {
    setUsersForRequests: (state, action) => {
      state.users.data = action.payload.data;
      state.users.total = action.payload.total;
    },
    resetUsersForRequests: (state) => {
      state.users.data = [];
      state.users.total = 0;
    },
  },
});

export const { setUsersForRequests, resetUsersForRequests } = signupRequestsSlice.actions;

export default signupRequestsSlice.reducer;

export const getUsersForRequests = ({ admin }: RootState) => admin.signupRequests.users;
