import { io } from "socket.io-client";

import { getLocalStorageItem } from "./localStorage";

import { domain } from "configuration/config";
import { STORAGES } from "constants/appConstants";
import store from "store/store";

const jwtToken = store.getState().auth.token;
const token = getLocalStorageItem({ key: STORAGES.token });

const socket = io(domain, {
  extraHeaders: {
    Authorization: `Bearer ${jwtToken || token}`,
  },
  autoConnect: false,
});

const getNewSocket = (newToken) => {
  return io(domain, {
    extraHeaders: {
      Authorization: `Bearer ${newToken}`,
    },
    autoConnect: false,
  });
};

export const CustomSocket = {
  socket,
  setNewSocket(newToken) {
    this.socket = getNewSocket(newToken);
  },
};
