import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { Option } from "Components/SelectField/types";
import { ASSIGNEDTO } from "constants/appConstants";

import { RootState } from "store/store";
import { IOnboarding } from "types/admin/onboarding";

interface IInitialState {
  screens: IOnboarding[];
  onboarding: IOnboarding;
  roles: Option[];
  total: number;
}

const initialState: IInitialState = {
  screens: [],
  roles: ASSIGNEDTO,
  onboarding: {
    _id: "",
    status: "",
    title: "",
    assignedTo: "",
    pages: [],
  },
  total: 0,
};

const onboardingsSlice = createSlice({
  name: "admin/onboardings",
  initialState,
  reducers: {
    setOnboardings: (state, action) => {
      if (action.payload && action.payload[0]) {
        state.screens = action.payload;
        state.total = action.payload.length;
      }
    },
    setOnboarding: (state, action) => {
      state.onboarding = action.payload;
    },
    setRoles: (state, action: PayloadAction<{ role: string; available: boolean }[]>) => {
      const roles = action.payload.map((item) => {
        if (!item.available || state.onboarding.assignedTo === item.role) {
          return item.role;
        }
      });
      state.roles = [
        ASSIGNEDTO[0],
        ...ASSIGNEDTO.filter((item) => roles.includes(item.value.toString())),
      ];
    },
    deleteOnboarding: (state, action) => {
      state.screens = state.screens.filter((item) => item._id !== action.payload);
    },
    unsetOnboarding: (state) => {
      state.onboarding = initialState.onboarding;
    },
  },
});

export const { setOnboardings, setOnboarding, unsetOnboarding, setRoles, deleteOnboarding } =
  onboardingsSlice.actions;

export default onboardingsSlice.reducer;

export const getOnboardingRoles = ({ admin }: RootState) => admin.onboarding.roles;
export const getOnboardings = ({ admin }: RootState) => admin.onboarding;
export const getOnboarding = ({ admin }: RootState) => admin.onboarding.onboarding;
