import { FC, useMemo } from "react";

import { useSelector } from "react-redux";

import { ThemeProvider } from "styled-components";

import BioLink from "./components/BioLink";
import DefaultBioLink from "./components/DefaultBioLink";
import Details from "./components/Details";
import Header from "./components/Header";
import Pattern from "./components/Pattern";
import { ProfilePreviewContainer } from "./style";

import { getImageTagFromFaviconBase64 } from "common/helpers/helpers";
import TabPanel from "Components/TabPanel";
import Tabs from "Components/Tabs";
import { DETAILSPROFILETABS, SocialIcons } from "constants/appConstants";
import { getPublicPlans, getPublicUser } from "store/slices/global/previewProfileSlice";
import { darkTheme, lightTheme } from "styles/newThemes";
import { IPublicLink } from "types/user";

interface Props {
  mobile?: boolean;
  wallpaper?: {
    pattern?: {
      icon: string;
      color: string;
      customIconUrl?: string;
    };
    background?: {
      color1: string;
      color2: string;
      theme: string;
    };
  };
  selectedAction?: number;
}

const ProfilePreview: FC<Props> = ({ mobile = false, wallpaper, selectedAction }) => {
  const plans = useSelector(getPublicPlans);
  const user = useSelector(getPublicUser);
  const theme = useMemo(() => {
    switch (user.bioTheme) {
      case "light":
        return lightTheme;

      case "dark":
        return darkTheme;

      default:
        return lightTheme;
    }
  }, [user]);

  const tabs = useMemo(
    () =>
      DETAILSPROFILETABS.map((item) => {
        return {
          ...item,
          count:
            item.key === 0 && user.publicLinks.length
              ? user.publicLinks.length
              : item.key === 0
              ? 1
              : plans.total,
        };
      }).filter((item) => item.count),
    [plans.total, user.publicLinks]
  );

  const links: IPublicLink[] = useMemo(
    () =>
      user.publicLinks.map((item) => {
        return {
          ...item,
          icon: item.customIcon
            ? getImageTagFromFaviconBase64(item.customIcon)
            : SocialIcons.find((el) => el.value === item.platform)!.label,
        };
      }),
    [user.publicLinks]
  );

  return (
    <ThemeProvider theme={theme}>
      <ProfilePreviewContainer
        mobile={mobile}
        gradient={
          wallpaper && wallpaper.background && selectedAction === 1
            ? wallpaper.background
            : { color1: "", color2: "", theme: "" }
        }
      >
        {wallpaper && wallpaper.pattern && (
          <Pattern pattern={wallpaper.pattern} mobile={mobile} show={selectedAction === 0} />
        )}
        <Header mobile={mobile} theme={user.bioTheme} backgroundSelected={selectedAction === 1} />
        <div className="banner">
          <div className="overlay" />
          <img
            src={user.banner ? user.banner.imageUrl_1200_0 : "/banner.png"}
            alt="banner"
            loading="lazy"
          />
        </div>
        <div className="profile">
          <Details mobile={mobile} backgroundSelected={selectedAction === 1} />
          <div className="unitTabs">
            <Tabs round activeTab={0} tabs={tabs} onClick={() => {}} />
            <TabPanel index={0} activeTab={0}>
              <div className="links">
                {links.length ? (
                  links.map((item) => <BioLink key={item._id} link={item} mobile={mobile} />)
                ) : (
                  <DefaultBioLink mobile={mobile} />
                )}
              </div>
            </TabPanel>
          </div>
        </div>
      </ProfilePreviewContainer>
    </ThemeProvider>
  );
};

export default ProfilePreview;
