import { Formik } from "formik";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import ChangeAvatar from "../ChangeAvatar";

import Input from "Components/Input";
import { LANGUAGES } from "constants/appConstants";
import { ALPHBETONLY_REGEX } from "constants/regex";
import ChangeTheme from "pages/creator/EditProfile/components/General/components/ChangeTheme";
import { ProfileForm, SaveButton, SubPageContainer } from "pages/creator/EditProfile/style";
import { updateFanProfielInfoAPI, updateProfilePic } from "services/UserServices";
import { setIsProfileUpdated } from "store/slices/global/globalSlice";
import { getUser } from "store/slices/global/userSlice";
import { BlockCard } from "styles/mixins";
import { IFormikGeneralFanValues } from "types/user";

const General = () => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const userDetails = useSelector(getUser);

  const schema = Yup.object().shape({
    name: Yup.string().required(t("common.required")).max(30, "Max length 30"),
    email: Yup.string().email(t("signup.invalid_email")).required(t("common.required")),
  });

  const isDisabled = useCallback((dirty: boolean) => {
    dispatch(setIsProfileUpdated(!dirty));
    return dirty;
  }, []);

  const handleSubmit = (values: IFormikGeneralFanValues) => {
    const mediaData = new FormData();
    mediaData.append("image", values.pictureUrl);

    if (values.pictureUrl.name) {
      updateProfilePic({ apiData: mediaData }, (res) => {
        updateFanProfielInfoAPI({
          displayname: values.name,
          email: values.email,
          languages: values.languages.value,
          picture: res.key,
        });
      });
    } else {
      updateFanProfielInfoAPI({
        displayname: values.name,
        email: values.email,
        languages: values.languages.value,
      });
    }
  };

  return (
    <SubPageContainer>
      <p className="title">{t("creator.profile.general")}</p>
      {userDetails && (
        <Formik<IFormikGeneralFanValues>
          enableReinitialize
          initialValues={{
            name: userDetails.displayname || "",
            email: userDetails.email ?? "",
            pictureUrl: userDetails.pictureUrl || "",
            languages:
              LANGUAGES.find((item) => item.value === userDetails.languages) || LANGUAGES[0],
          }}
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, dirty, handleBlur, handleSubmit, setFieldValue }) => (
            <ProfileForm onSubmit={handleSubmit}>
              <ChangeTheme />
              <ChangeAvatar pictureUrl={values.pictureUrl} />
              <BlockCard className="card">
                <p className="title">{t("common.editProfile")}</p>
                <Input
                  name="name"
                  label={t("common.user_profile.display_name")}
                  placeholder={t("common.user_profile.display_name")}
                  info={t("common.user_profile.display_name_msg")}
                  value={values.name}
                  handleChange={(e) => {
                    const dname = e.target.value.trimStart();
                    if (dname.match(ALPHBETONLY_REGEX) || dname === "") {
                      setFieldValue("name", dname);
                    }
                  }}
                  handleBlur={handleBlur}
                  maxLength={30}
                  error={errors.name && touched.name ? errors.name : ""}
                  required
                />
                {/* <Input
                name="email"
                label={t("common.email")}
                placeholder={t("common.email")}
                value={values.email}
                handleChange={(e) => setFieldValue("email", e.target.value)}
                handleBlur={handleBlur}
                maxLength={30}
                error={errors.email && touched.email ? errors.email : ""}
                required
              /> */}

                {/* <SingleSelect
                label={t("common.select_language")}
                value={values.languages}
                handleChange={(data) => {
                  setFieldValue("languages", data);
                }}
                placeholder={t("common.select_language")}
                options={LANGUAGES}
              /> */}
                <SaveButton variant="primary" disabled={isDisabled(!dirty)} type="submit">
                  {t("creator.profile.save")}
                </SaveButton>
              </BlockCard>
            </ProfileForm>
          )}
        </Formik>
      )}
    </SubPageContainer>
  );
};

export default General;
