import { FC, memo } from "react";

import { CircleContainer, Circle, Label, SwitchContainer } from "./style";

interface Props {
  padding?: number;
  label?: string;
  onChange: (value: boolean) => void;
  value: boolean;
  name?: string;
  size?: number;
  oncolor?: string;
  offColor?: string;
  bg?: string;
  toolTip?: string;
  disabled?: boolean;
}

const Switch: FC<Props> = memo(({ label, onChange, value, toolTip, disabled }) => (
  <SwitchContainer>
    {label && <Label>{label}</Label>}
    <CircleContainer
      state={value}
      disabled={disabled}
      onClick={() => !disabled && onChange(!value)}
    >
      <Circle state={value} />
      {toolTip && <span className="toolTip">{toolTip}</span>}
    </CircleContainer>
  </SwitchContainer>
));

export default Switch;
