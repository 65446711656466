import { FC } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import Button from "Components/Button";
import HookFormInput from "Components/HookFormInput";
import { Content, MfaModalContainer } from "pages/auth/Login/components/MfaModal/style";
import { validateTwoFactorCodeAPI } from "services/authServices";

type Inputs = {
  mfa: string;
};

interface ChooseRoleModalProps {
  show: boolean;
  userId: string;
  onCloseSuccess: () => void;
  onCloseError: () => void;
}
export const MfaModal: FC<ChooseRoleModalProps> = ({
  show,
  onCloseSuccess,
  onCloseError,
  userId,
}) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitted, isValid },
  } = useForm<Inputs>();

  const onSubmit = (data: Inputs) => {
    validateTwoFactorCodeAPI(
      {
        data: {
          id: userId,
          token: data.mfa,
        },
      },
      (status) => {
        if (!status) {
          setError("mfa", { type: "invalidCode", message: "Invalid code provided" });
          return;
        }
        onCloseSuccess();
      }
    );
  };

  return (
    <MfaModalContainer
      show={show}
      size="md"
      showCloseButton
      onHide={() => {
        onCloseError();
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="title">
          <p>{t("auth.authentication_code_modal_title")}</p>
        </div>
        <p className="description">{t("auth.authentication_code_modal_description")}</p>
        <Content>
          <HookFormInput
            className="auth_code_input"
            register={register("mfa", {
              required: t("common.required"),
              maxLength: {
                value: 6,
                message: t("auth.authentication_code_modal_code_length_error"),
              },
              minLength: {
                value: 6,
                message: t("auth.authentication_code_modal_code_length_error"),
              },
            })}
            type="number"
            label={t("auth.authentication_code_modal_label")}
            placeholder={t("auth.authentication_code_modal_placeholder")}
            error={errors.mfa?.message}
          />

          <div className="buttons_container">
            <Button
              variant="secondary"
              full
              type="button"
              onClick={() => {
                onCloseError();
              }}
            >
              {t("common.cancel")}
            </Button>
            <Button variant="primary" full type="submit" disabled={isSubmitted && !isValid}>
              {t("common.log_in")}
            </Button>
          </div>
        </Content>
      </form>
    </MfaModalContainer>
  );
};
