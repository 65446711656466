import getAxiosInst, { apiHandler, endpoints } from "./axios";

import { makeToast } from "common/helpers/helper";

import { STRINGS } from "constants/appConstants";
import { setLoader } from "store/slices/featureSlice";

import {
  resetFee,
  setAriHistory,
  setFee,
  setTokenGroups,
  setWallet,
  setWithdrawalsHistory,
} from "store/slices/walletSlice";
import store from "store/store";
import { GetWalletApiResp } from "types/api-response-types";

// get wallet details
export const getWalletAPI = (callback = () => {}) => {
  store.dispatch(setLoader({ name: "walletLoader", value: true }));
  apiHandler(() => getAxiosInst().get<GetWalletApiResp>(endpoints.wallet), {
    onSuccess: (res) => {
      callback();
      store.dispatch(setWallet(res.wallet));
      store.dispatch(setTokenGroups(res.tokenGroups));
    },
    onError: (error) => {
      callback();
      console.log("catch error ===>>>", error.response);
    },
    final: () => {
      store.dispatch(setLoader({ name: "walletLoader", value: false }));
    },
  });
};

export const getWithdrawFeeAPI = (apiData: { address: string; amount: number; token: string }) => {
  store.dispatch(setLoader({ name: "feeLoader", value: true }));

  apiHandler(() => getAxiosInst().post(endpoints.withdrawFee, apiData), {
    onSuccess: (res) => {
      store.dispatch(setFee(res));
    },
    onError: (error) => {
      store.dispatch(resetFee());

      if (typeof error.response?.data?.error === "string") {
        makeToast({
          message: error.response?.data?.error,
          type: STRINGS.toastType.error,
        });
      } else {
        makeToast({
          message: error.response?.data?.message,
          type: STRINGS.toastType.error,
        });
      }
    },
    final: () => {
      store.dispatch(setLoader({ name: "feeLoader", value: false }));
    },
  });
};

export const withdrawAPI = (
  apiData: {
    amount: string;
    address: string;
    // eslint-disable-next-line camelcase
    auth_code: string;
    token: string;
  },
  callback = () => {}
) => {
  store.dispatch(setLoader({ name: "withdrawLoader", value: true }));

  apiHandler(() => getAxiosInst().post(endpoints.withdraw, apiData), {
    onSuccess: (res) => {
      callback();
      makeToast({
        message: res?.data?.status || "Withdrawal request submitted",
        type: STRINGS.toastType.success,
      });
    },
    onError: (error) => {
      makeToast({
        message: error.response?.data?.message || "Unable to withdraw. Please try after some time.",
        type: STRINGS.toastType.error,
      });
    },
    final: () => {
      store.dispatch(setLoader({ name: "withdrawLoader", value: false }));
    },
  });
};

export const getWithdrawalsHistoryAPI = ({ limit, skip }: { limit: number; skip: number }) => {
  store.dispatch(setLoader({ name: "withdrawalHistoryLoader", value: true }));
  apiHandler(
    () =>
      getAxiosInst().get(endpoints.getWithdrawalHistory, {
        params: { limit, skip, sort: -1 },
      }),
    {
      onSuccess: (res) => {
        store.dispatch(
          setWithdrawalsHistory({
            total: res.Totaltransaction,
            data: res.result,
          })
        );
      },
      onError: (error) => {
        console.log("catch error ===>>>", error.response);
      },
      final: () => {
        store.dispatch(setLoader({ name: "withdrawalHistoryLoader", value: false }));
      },
    }
  );
};

export const getAriHistoryAPI = ({ limit, skip }: { limit: number; skip: number }) => {
  store.dispatch(setLoader({ name: "withdrawalHistoryLoader", value: true }));
  apiHandler(
    () =>
      getAxiosInst().get(endpoints.getAriHistory, {
        params: { limit, skip, sort: -1 },
      }),
    {
      onSuccess: (res) => {
        store.dispatch(
          setAriHistory({
            total: res.totalCount,
            data: res.result,
          })
        );
      },
      onError: (error) => {
        console.log("catch error ===>>>", error.response);
      },
      final: () => {
        store.dispatch(setLoader({ name: "withdrawalHistoryLoader", value: false }));
      },
    }
  );
};
