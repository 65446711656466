import { ReactNode, memo } from "react";
import { ModalBody, ModalProps } from "react-bootstrap";

import { ModalStyled } from "./style";

import Close from "assets/svg/Close";

interface Props extends ModalProps {
  children: ReactNode;
  showCloseButton?: boolean;
}

const Modal = memo(({ children, showCloseButton = false, ...props }: Props) => (
  <ModalStyled {...props}>
    <ModalBody>
      {showCloseButton && (
        <span role="button" tabIndex={0} className="close-button" onClick={props.onHide}>
          <Close size={24} />
        </span>
      )}
      {children}
    </ModalBody>
  </ModalStyled>
));

export default Modal;
