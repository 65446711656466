const Community = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="18"
    className={className}
    stroke="currentColor"
    viewBox="0 0 22 18"
    fill="none"
  >
    <path
      d="M16.6494 8.01875C18.2494 8.01875 19.5494 6.71875 19.5494 5.11875C19.5494 3.51875 18.2494 2.21875 16.6494 2.21875"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.9902 11.168C18.4702 11.198 18.9402 11.268 19.4102 11.378C20.0602 11.508 20.8402 11.768 21.1202 12.358C21.3002 12.728 21.3002 13.168 21.1202 13.538C20.8402 14.118 20.0602 14.388 19.4102 14.518"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.3502 8.01875C3.7502 8.01875 2.4502 6.71875 2.4502 5.11875C2.4502 3.51875 3.7502 2.21875 5.3502 2.21875"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.01988 11.168C3.53988 11.198 3.06988 11.268 2.59988 11.378C1.94988 11.508 1.16988 11.768 0.889883 12.358C0.709883 12.728 0.709883 13.168 0.889883 13.538C1.16988 14.118 1.94988 14.388 2.59988 14.518"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.9995 11.7991C14.2495 11.7991 17.0195 12.2891 17.0195 14.2591C17.0195 16.2291 14.2595 16.7291 10.9995 16.7291C7.73949 16.7291 4.97949 16.2391 4.97949 14.2691C4.97949 12.2991 7.73949 11.7891 10.9995 11.7891V11.7991Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.9996 8.98953C8.85965 8.98953 7.13965 7.26953 7.13965 5.12953C7.13965 2.98953 8.85965 1.26953 10.9996 1.26953C13.1396 1.26953 14.8596 2.98953 14.8596 5.12953C14.8596 7.26953 13.1396 8.98953 10.9996 8.98953Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Community;
