import React, { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaRegCopy } from "react-icons/fa";
import { SkeletonTheme } from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { AuthStyled, Heading, DetailsSection, SubHeading, RecoverCodeBox } from "./style";

import { copyToClipboard, makeToast } from "common/helpers/helper";
import Button from "Components/Button";
import CheckboxField from "Components/CheckboxField";
import { frontendDomain } from "configuration/config";
import { STRINGS } from "constants/appConstants";
import titles from "constants/titles";
import useRemoveToast from "customHooks/useRemoveToast";
import useTitle from "customHooks/useTitle";
import { ROUTES } from "routes/routesData";
import { recoverNewCodeAPI, apiHandler } from "services/axios";
import { getDefaultLandingPages } from "store/slices/admin/adminSlice";
import { getToken, login } from "store/slices/authSlice";

import { colors } from "styles/theme";

const RecoveryCode = () => {
  const { t } = useTranslation();
  // set title of the page
  useTitle(titles.recovery2FA);

  // it removes toast on unmount
  useRemoveToast();

  const dispatch = useDispatch();

  const { state } = useLocation();
  const navigate = useNavigate();

  const jwtToken = useSelector(getToken);
  const defaultLandingPages = useSelector(getDefaultLandingPages);

  const [checked, setChecked] = useState(false);
  const [code, setCode] = useState("");
  const [newKey, setNewKey] = useState(null);

  // it redirect user based on role
  const redirectUser = () => {
    let route;
    defaultLandingPages.forEach((item) => {
      if (item.role.toLowerCase() === state.userInfo.role.toLowerCase()) route = item.defaultURl;
    });

    navigate(route.replace(frontendDomain, ""));
  };

  useEffect(() => {
    if (!state?.page) {
      navigate(ROUTES.login);
    }
  }, [state]);

  // it logins the user then redirect to their landing page
  const submit = () => {
    if (state.page === STRINGS.login) {
      dispatch(
        login({
          accountType: state.userInfo.role,
          token: jwtToken,
          userId: state.userInfo._id,
        })
      );
      redirectUser();
    } else navigate(ROUTES.login);
  };

  //  it genrates the new secret key for 2FA
  const recoverNewCode = () => {
    apiHandler(() => recoverNewCodeAPI({ data: { id: state.userInfo._id, key: code } }), {
      onSuccess: (data) => {
        makeToast({
          message: "Successfully Generated new key",
          type: STRINGS.toastType.success,
        });
        setNewKey(data.key);
      },
      onError: (error) => {
        console.log(error.response);
        if (error.response.status === 401) {
          makeToast({
            message: error.response.data.message,
            type: STRINGS.toastType.error,
          });
        }
      },
    });
  };

  return (
    <SkeletonTheme baseColor={colors.blue.primary} highlightColor={colors.blue.border}>
      <AuthStyled>
        <DetailsSection>
          <Heading>{t("auth.two_factor_auth")}</Heading>
          <Heading>(MFA)</Heading>
          <SubHeading>{newKey ? t("auth.enter_the_key") : t("auth.we_will_generate")}</SubHeading>

          <RecoverCodeBox>
            {state?.code || newKey ? (
              <div className="key">
                <span>{state?.code || newKey}</span>
                <FaRegCopy
                  size={25}
                  className="ms-3 cursor-pointer"
                  color={colors.green.secondary}
                  title={t("creator.copy")}
                  onClick={() => copyToClipboard(state?.code || newKey)}
                />
              </div>
            ) : (
              <input
                value={code}
                onChange={(e) => setCode(e.target.value)}
                placeholder={t("auth.enter_your_code_here")}
                autoFocus
                type="text"
              />
            )}
          </RecoverCodeBox>

          {newKey && (
            <CheckboxField
              color={colors.blue.primary}
              handleChange={() => setChecked(!checked)}
              value={checked}
              label={t("auth.safely_recorded")}
            />
          )}

          <Button
            variant="primary"
            full
            disabled={(newKey && !checked) || code === ""}
            onClick={newKey ? submit : recoverNewCode}
          >
            {t("common.continue")}
          </Button>
        </DetailsSection>
      </AuthStyled>
    </SkeletonTheme>
  );
};

export default memo(RecoveryCode);
