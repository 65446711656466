import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "store/store";

interface IInitialState {
  feed: {
    data: any[];
    total: number;
  };
  suggestedCreators: {
    data: any[];
    total: number;
  };
}

const initialState: IInitialState = {
  feed: {
    data: [],
    total: 0,
  },
  suggestedCreators: {
    data: [],
    total: 0,
  },
};

const activityFeedSlice = createSlice({
  name: "activityFeed",
  initialState,
  reducers: {
    setFanFeed: (state, action) => {
      state.feed.data = [...state.feed.data, ...action.payload.data];
      state.feed.total = action.payload.total;
    },
    setSuggestedCreators: (state, action) => {
      state.suggestedCreators.data = action.payload.data;
      state.suggestedCreators.total = action.payload.total;
    },
    resetFanFeed: (state) => {
      state.feed.data = [];
      state.feed.total = 0;
    },
    resetFanActivityFeed: () => {
      return { ...initialState };
    },
  },
});

export const { setFanFeed, setSuggestedCreators, resetFanFeed, resetFanActivityFeed } =
  activityFeedSlice.actions;

export default activityFeedSlice.reducer;

export const getFanFeed = ({ fan }: RootState) => fan.activity.feed;
export const getSuggestedCreators = ({ fan }: RootState) => fan.activity.suggestedCreators;
