import { STRINGS, EARNINGREPORT } from "constants/appConstants";
import { EMAIL_REGEX, URLRegex, YOUTUBE_VIDEO_LINK } from "constants/regex";
import tokens, { currency } from "constants/tokens";
import getAxiosInst from "services/axios";
import { resetAdminSlice } from "store/slices/admin/adminSlice";
import { resetCreatorSlice } from "store/slices/creator/creatorSlice";
import {
  createToast,
  deleteToast,
  hideToast,
  resetFeatureSlice,
  setFullScreenLoader,
  showToast,
} from "store/slices/featureSlice";
import { resetGlobalSlice } from "store/slices/global/globalSlice";
import { resetUser } from "store/slices/global/userSlice";
import { resetUserVisitedPagesSlice } from "store/slices/userVisitedPagesSlice";
import { resetWallet } from "store/slices/walletSlice";
import store from "store/store";

export const clearSlicesData = () => {
  store.dispatch(resetAdminSlice());
  store.dispatch(resetCreatorSlice());
  store.dispatch(resetFeatureSlice());
  store.dispatch(resetGlobalSlice());
  store.dispatch(resetWallet());
  store.dispatch(resetUser());
  store.dispatch(resetUserVisitedPagesSlice());
};

export const emitAnalyticsRegisterEvent = () => {
  // eslint-disable-next-line no-undef
  fbq("track", "CompleteRegistration");
  // eslint-disable-next-line no-undef
  twq("event", "tw-o8xab-ojl5q", {});
  window.dataLayer.push({ event: "CompleteRegistration" });
  window.gtag("event", "CompleteRegistration");
  window.ttq.track("CompleteRegistration");
};

export const dataURLtoFile = (dataurl, filename) => {
  const arr = dataurl.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export const isURLValid = (url) => URLRegex.test(url);

export const startFullScreenLoader = () => store.dispatch(setFullScreenLoader(true));
export const stopFullScreenLoader = () => store.dispatch(setFullScreenLoader(false));

export const getFormattedDate = (date) => {
  date = new Date(date);
  return `${date.getFullYear()}-${
    (date.getMonth() + 1).toString().length < 2 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  }-${date.getDate().toString().length < 2 ? `0${date.getDate()}` : date.getDate()} ${
    date.getHours().toString().length < 2 ? `0${date.getHours()}` : date.getHours()
  }:${date.getMinutes().toString().length < 2 ? `0${date.getMinutes()}` : date.getMinutes()}`;
};

export const convertArrForReactSelect = (arr) =>
  arr.map((item) => ({
    value: item,
    label: item,
  }));

export const convertLabelandValArrForReactSelect = (arr) =>
  arr &&
  arr.map((item) => ({
    value: item.value,
    label: item.label,
  }));

/**
 *
 * @param {{level}} param0 {level}
 * @returns [upload video limit / week, stake limit for next level, campaign limit for current level]
 */
export const checkVideoAllowance = async ({ level }) => {
  const data = await getAxiosInst().get("/level/getalllevels", {
    params: {
      sort: 1,
    },
  });
  const userLevels = data.data.data;
  for (let i = 0; i < userLevels.length; i++)
    if (userLevels[i].level === level)
      return [
        userLevels[i].videoLimit,
        userLevels[i + 1]?.lewkstakeLimit,
        userLevels[i].campaignLimit,
      ];
};

export const makeToast = ({ message, duration = 5000, type }) => {
  const id = Date.now();

  if (!store.getState().feature.toast.find((item) => item.message === message)) {
    store.dispatch(createToast({ id, message, type }));

    setTimeout(() => {
      store.dispatch(showToast(id));
    }, 0);

    setTimeout(() => {
      store.dispatch(hideToast(id));
    }, duration);

    setTimeout(() => {
      store.dispatch(deleteToast(id));
    }, duration + 150);
  }
};

export const setAndRemoveErr = ({ setErr, err, msg, name }) => {
  setErr((oldVal) => ({ ...oldVal, [name]: msg }));
  setTimeout(
    () =>
      setErr((oldVal) => {
        const obj = err;
        for (const prop in oldVal) {
          obj[prop] = "";
        }
        return obj;
      }),
    4000
  );
};

export const substringLongWord = ({ string, length, dots, fromEnd }) => {
  if (!string) return "";
  if (fromEnd === true) {
    return string.length > length ? string.slice(-length) : string;
  }
  return string.length > length
    ? `${string.substring(0, length)}${dots === false ? "" : "..."}`
    : string;
};

export const getDaysTillNow = ({ time }) => {
  const date = new Date(time);
  const currentDate = new Date();
  const diffTime = Math.abs(currentDate - date);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

export const scrollTop = () => window.scrollTo(0, 0);

export const convertValForReactSelect = (data) => ({
  value: data,
  label: data,
});

export const convertValAndLabelReactSelect = (label, value) => ({
  value,
  label,
});

export const validateEmail = ({ email }) => String(email).toLowerCase().match(EMAIL_REGEX);

export const getYoutubeVideoIdFromLink = (url) => {
  const match = url.match(YOUTUBE_VIDEO_LINK);
  return match && match[7].length === 11 ? match[7] : "";
};

export const validateIfShortenedTiktokUrl = (url) => {
  const shortenedTiktokUrlRegexp = /^https:\/\/vm\.tiktok\.com\/[a-zA-Z0-9]{9}\/?$/;
  return shortenedTiktokUrlRegexp.test(url);
};

export const validateAndExtractTikTokID = (url) => {
  const tikTokUrlPattern = /^https:\/\/www\.tiktok\.com\/@[\w.-]+\/video\/(\d+)/;
  const match = url.match(tikTokUrlPattern);

  if (match) {
    return match[1].length === 19 ? match[1] : ""; // Returns the ID part of the URL
  }
  return ""; // Indicates the URL is not a valid TikTok URL
};

export const validateAndExtractInstagramPostID = (url) => {
  const instagramUrlPattern = /^https:\/\/www\.instagram\.com\/p\/([\w-]+)\/?/;
  const match = url.match(instagramUrlPattern);

  if (match) {
    const postID = match[1] || "";
    return postID.length === 11 ? postID : "";
  }
  return ""; // Indicates the URL is not a valid Instagram post URL
};

export const validateFaceBookPostUrl = (url) => {
  const facebookUrlRegex =
    /^https:\/\/www\.facebook\.com\/(?:[a-zA-Z0-9.]+\/)?posts\/pfbid\d+|https:\/\/www\.facebook\.com\/\d+\/posts\/\d+$/;

  return facebookUrlRegex.test(url);
};

export const validateFaceBookReelUrl = (url) => {
  const facebookUrlPattern = /^https:\/\/www\.facebook\.com\/reel\/([\w-]+)\/?/;
  return facebookUrlPattern.test(url);
};

export const validateIfShortenedFaceBookUrl = (url) => {
  const shortenedTiktokUrlRegexp =
    /^https:\/\/www\.facebook\.com\/share\/[a-zA-Z]\/[A-Za-z0-9]{16}\/?/;
  return shortenedTiktokUrlRegexp.test(url);
};

export const validateAndExtractReelPostID = (url) => {
  const instagramUrlPattern = /^https:\/\/www\.instagram\.com\/reel\/([\w-]+)\/?/;
  const match = url.match(instagramUrlPattern);

  if (match) {
    const postID = match[1] || "";
    return postID.length === 11 ? postID : "";
  }
  return ""; // Indicates the URL is not a valid Instagram reel URL
};

export const copyToClipboard = async (onSuccess, onError, text = "") => {
  try {
    if (navigator.clipboard && window.isSecureContext) {
      // navigator clipboard api method'
      await navigator.clipboard.writeText(text);
      makeToast({ message: "Copied ", type: STRINGS.toastType.success });
      onSuccess && onSuccess();
    } else {
      // text area method
      const textArea = document.createElement("textarea");
      textArea.value = text;
      // make the textarea out of viewport
      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      document.execCommand("copy") && onSuccess && onSuccess();
      textArea.remove();
      makeToast({ message: "Copied ", type: STRINGS.toastType.success });
    }
  } catch (error) {
    onError && onError();
  }
};

export const download = async (fileName, fileUrl) => {
  const a = document.createElement("a");
  a.href = fileUrl;
  a.setAttribute("download", fileName);
  a.click();
};

export const getToken = (assetId) => {
  switch (assetId) {
    case tokens.LEWK:
      return "LEWK";
    case tokens.BUSD:
      return "BUSD";
    case tokens.USDC:
      return "USDC";
    case tokens.USDT:
      return "USDT";
    case tokens.ETH:
      return "ETH";
    case tokens.TRX:
      return "TRX";
    case tokens.USDT_ETH:
      return "USDT_ETH";
    case tokens.FAKET:
      return "FAKET";
    default:
      break;
  }
};

export const createFileFromBase64 = async (base64Uri) => {
  const createdFile = await fetch(base64Uri)
    .then((res) => res.blob())
    .then((blob) => {
      const file = new File([blob], "thumbnail.png", { type: "image/png" });
      return file;
    });
  return createdFile;
};

export const getScreenShot = async (videoRef) => {
  const canvas = document.createElement("canvas");
  // get the width height of snapshot
  const ratio = videoRef.current.videoWidth / videoRef.current.videoHeight;
  const myWidth = videoRef.current.videoWidth - 100;
  const myHeight = parseInt(myWidth / ratio, 10);
  canvas.width = myWidth;
  canvas.height = myHeight;

  const ctx = canvas.getContext("2d");
  ctx.fillRect(0, 0, myWidth, myHeight);
  ctx.drawImage(videoRef.current, 0, 0, myWidth, myHeight);
  return canvas.toDataURL("image/png", 0.5);
};

export const setTokenvalues = (enabledTokens) => {
  if (enabledTokens && enabledTokens?.reference_value) {
    const tokenArray = [];
    enabledTokens?.reference_value?.map((singleToken) => {
      const tokenRow = {
        label: currency[singleToken],
        value: singleToken,
      };
      tokenArray.push(tokenRow);
    });
    return tokenArray;
  }
};

export const localizeEarningReport = (earningReport) => {
  return earningReport.map((val) => {
    switch (val.type.toLowerCase()) {
      case EARNINGREPORT.GIFT.toLowerCase():
        return { ...val, title: "creator.dashboard.gift" };
      case EARNINGREPORT.SUBSCRIPTION.toLowerCase():
        return { ...val, title: "creator.dashboard.subscription" };
      case EARNINGREPORT.CHALLENGE_REWARD.toLowerCase():
        return { ...val, title: "creator.dashboard.trend_reward" };
      case EARNINGREPORT.PAID_MESSAGE.toLowerCase():
        return { ...val, title: "creator.dashboard.paidMessage" };
      default:
        return val;
    }
  });
};
