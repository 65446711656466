import { memo, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import Drawer from "./components/Drawer";

import HeaderMenu from "./components/HeaderMenu";
import {
  AuthLink,
  DrawerBtn,
  HeaderComp,
  HeaderHeading,
  LinkStyled,
  Logo,
  LogoutButton,
  NavItems,
  Right,
} from "./style";

import { authPaths, getDrawerData } from "../../constants/sidebarData";

import Exit from "assets/svg/header/Exit";
import MenuBurger from "assets/svg/MenuBurger";
import { clearSlicesData, makeToast, scrollTop } from "common/helpers/helper";

import { CustomSocket } from "common/helpers/socket";
import { ROLES, STRINGS } from "constants/appConstants";
import { ROUTES } from "routes/routesData";
import { fetchUserDetailsAPI } from "services/axios";

import {
  getChatStatusesCounter,
  getUnreadChatsCountApi,
  markMessagesAsReadById,
} from "services/newMessagesService";
import { getNotficationCountAPI, getUnreadNotificationApi } from "services/notification";

import { getWalletAPI } from "services/walletServices";
import { getLoggedInStatus, getRole, getToken, logout } from "store/slices/authSlice";
import { emitUserLogoutEvent } from "store/slices/eventBusSlice";
import {
  addChat,
  getCurrentTab,
  getOpenedChat,
  getUnreadChats,
  getUnreadMessages,
  setChatsTotalCount,
  setOpenedChat,
  setUnreadChats,
  setUnreadMessages,
  updateOneChat,
} from "store/slices/global/chatsSlice";
import {
  addNotification,
  getUnreadNotification,
  setUnreadNotification,
} from "store/slices/global/notificationSlice";
import { getUser } from "store/slices/global/userSlice";
import { addMessage, updateOneMessage } from "store/slices/newMessages/messagesSlice";
import { INewMessageSocket } from "types/new-messages.types";

const Header = memo(() => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(getUser);
  const accountType = useSelector(getRole);
  const authToken = useSelector(getToken);
  const isLoggedIn = useSelector(getLoggedInStatus);
  const unreadNotification = useSelector(getUnreadNotification);
  const openedChat = useSelector(getOpenedChat);
  const currentTab = useSelector(getCurrentTab);
  const unreadChat = useSelector(getUnreadChats);
  const unreadMessages = useSelector(getUnreadMessages);

  const [drawer, setDrawer] = useState(false);
  const closeDrawer = () => setDrawer(false);
  const location = useLocation();
  const [menu, setMenu] = useState(false);

  const handleLogoClick = () => {
    scrollTop();
    setMenu(false);
  };

  const openDrawer = () => {
    setMenu(false);
    setDrawer(true);
  };

  useEffect(() => {
    if (authToken) {
      CustomSocket.setNewSocket(authToken);
    }
  }, [authToken]);

  useEffect(() => {
    if (!CustomSocket.socket.connected && isLoggedIn) {
      CustomSocket.socket.connect();
    }

    CustomSocket.socket.on("new_thread", (res) => {
      makeToast({
        message: `${res.message.user.displayname || res.message.user.username} ${t(
          "common.messageFrom"
        )}${res.message.textOrPathToFile}`,
        type: STRINGS.toastType.success,
      });
    });

    return () => {
      CustomSocket.socket.off("connect");
      CustomSocket.socket.off("disconnect");
    };
  }, []);

  useEffect(() => {
    CustomSocket.socket.on("notification", (res) => {
      if (
        (location.pathname === "/creator/notifications" ||
          location.pathname === "/fan/notifications") &&
        "notification" in res
      ) {
        dispatch(addNotification(res.notification));
      }
      if ("notification" in res) dispatch(setUnreadNotification(unreadNotification + 1));
    });

    return () => {
      CustomSocket.socket.off("notification");
    };
  }, [unreadNotification]);

  useEffect(() => {
    if (user?.username) {
      CustomSocket.socket.emit("online", { username: user?.username });
    }
  }, [user]);

  useEffect(() => {
    if (isLoggedIn) {
      getWalletAPI();
    }
  }, []);

  useEffect(() => {
    if (user) return;
    if (isLoggedIn) {
      fetchUserDetailsAPI((res) => {
        getUnreadChatsCountApi(
          res.user.role === "fan" ? "creator" : "fan",
          ["active", "pending_approval"],
          (res) => {
            dispatch(setUnreadChats(res));
          }
        );
        getUnreadNotificationApi(res.user.role);
        if (res.user.role === "creator") {
          getUnreadChatsCountApi("creator", ["active"], (res) => {
            dispatch(setUnreadMessages(res));
          });
        }
      });
    }
  }, [user, isLoggedIn]);

  useEffect(() => {
    CustomSocket.socket.on("newMessage", (data: INewMessageSocket) => {
      if (data.isNewChat) {
        if (currentTab.status === data.chat.status) {
          dispatch(addChat({ ...data.chat, lastMessage: data.message }));

          if (
            (data.chat.anotherUser.role === "creator" && user?.role === "fan") ||
            (data.chat.anotherUser.role === "fan" && user?.role === "creator")
          )
            dispatch(setUnreadChats(unreadChat + 1));
          else dispatch(setUnreadMessages(unreadMessages + 1));
        }
        getChatStatusesCounter((data) => dispatch(setChatsTotalCount(data)));
      } else {
        dispatch(
          updateOneChat({
            id: data.chat._id,
            changes: {
              ...data.chat,
              unreadMark: !openedChat || openedChat._id !== data.chat._id,
            },
          })
        );
        if (!openedChat || openedChat._id !== data.chat._id) {
          if (
            (data.chat.anotherUser.role === "creator" && user?.role === "fan") ||
            (data.chat.anotherUser.role === "fan" && user?.role === "creator")
          )
            dispatch(setUnreadChats(unreadChat + 1));
          else dispatch(setUnreadMessages(unreadMessages + 1));
        }

        if (!openedChat || openedChat._id !== data.chat._id) return;

        dispatch(setOpenedChat({ ...openedChat, lastMessage: { ...data.message } }));

        if (openedChat._id === data.chat._id) {
          markMessagesAsReadById(data.chat._id, [data.message._id]);
          dispatch(addMessage(data.message));
        }
      }
    });
    CustomSocket.socket.on("messagePaid", (data: INewMessageSocket) => {
      dispatch(
        updateOneMessage({
          id: data.message._id,
          changes: {
            isPaid: true,
          },
        })
      );
    });
    return () => {
      CustomSocket.socket.off("newMessage");
      CustomSocket.socket.off("messagePaid");
    };
  }, [openedChat, unreadChat, unreadMessages, user]);

  const navItems = useMemo(() => getDrawerData(accountType), []);

  const signOut = () => {
    clearSlicesData();
    dispatch(logout());
    dispatch(emitUserLogoutEvent({ randomId: Math.random() }));
  };

  useEffect(() => {
    isLoggedIn && user && getNotficationCountAPI();
  }, [user, isLoggedIn]);

  const toTheBaseRoute = () => {
    if (!accountType || !isLoggedIn) return;
    navigate(
      accountType === ROLES.admin
        ? ROUTES.adminSearchPeople
        : accountType === ROLES.judge
        ? ROUTES.judgeSearchCreators
        : accountType === ROLES.publisher
        ? ROUTES.publisherStandaloneCampaign
        : `/${accountType}${ROUTES.dashboard}`
    );
  };

  const handleClick = (onClick?: () => void) => {
    setMenu(false);
    onClick && onClick();
  };

  return (
    <>
      <HeaderComp>
        <div className="content">
          <Logo onClick={handleLogoClick}>
            <DrawerBtn onClick={openDrawer}>
              <MenuBurger />
            </DrawerBtn>
            {user && user.generalTheme === "dark" ? (
              <img
                className="cursor-pointer light"
                onClick={toTheBaseRoute}
                src="/logoLight.svg"
                alt="LEWK"
              />
            ) : (
              <img
                className="cursor-pointer dark"
                onClick={toTheBaseRoute}
                src="/logoDark.svg"
                alt="LEWK"
              />
            )}
            {isLoggedIn && (
              <HeaderHeading>
                {t("header.welcome_dashboard")} <span>{user?.displayname || user?.username}</span>
              </HeaderHeading>
            )}
          </Logo>
          <Right>
            <NavItems>
              {isLoggedIn
                ? navItems?.map((item, index) => (
                    <LinkStyled
                      key={index}
                      className={item.className}
                      to={item.to}
                      onClick={() => handleClick(item.onClick)}
                      title={t(item.text)}
                    >
                      {item.icon}
                      {item.to.includes("chat") && !!unreadChat && (
                        <div className="chatCounter">{unreadChat}</div>
                      )}
                      {item.to.includes("notifications") && !!unreadNotification && (
                        <div className="chatCounter">{unreadNotification}</div>
                      )}
                    </LinkStyled>
                  ))
                : authPaths.map((item) => <AuthLink href={item.to}>{t(item?.text)}</AuthLink>)}
              {user?.role !== ROLES.admin && <HeaderMenu menu={menu} setMenu={setMenu} />}
            </NavItems>
            {isLoggedIn && (
              <LogoutButton onClick={signOut} className="nav-link text-center hide">
                {t("header.logout")}
                {"  "}
                <Exit />
              </LogoutButton>
            )}
          </Right>
        </div>
      </HeaderComp>
      <Drawer drawer={drawer} closeDrawer={closeDrawer} />
    </>
  );
});

export default Header;
