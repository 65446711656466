import { Dispatch, FC, SetStateAction, useCallback, useMemo, useState } from "react";

import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";

import { SocialContainer } from "./style";

import ArrowDownSmall from "assets/svg/ArrowDownSmall";
import Close from "assets/svg/Close";
import Drag from "assets/svg/Drag";

import LinkClick from "assets/svg/LinkClick";
import { makeToast } from "common/helpers/helper";
import Accordion2 from "Components/Accordion2";
import { STRINGS } from "constants/appConstants";
import SocialExpanded from "pages/creator/Bio/BioLink/components/Social/SocialExpanded";
import { IPublicLink } from "types/user";

interface CollapsedProps {
  item: IPublicLink;
  isAccordionOpened: boolean;
  socialOpenedEvent?: (opened: boolean) => void;
  elementToDragFor: DraggableProvidedDragHandleProps | null | undefined;
  onDelete: (value: IPublicLink) => void;
  setAccordionOpened: Dispatch<SetStateAction<boolean>>;
}

const CollapsedContent: FC<CollapsedProps> = ({
  item,
  onDelete,
  elementToDragFor,
  isAccordionOpened,
  setAccordionOpened,
  socialOpenedEvent,
}) => {
  const { t } = useTranslation();
  const showDisabledDragMessage = useCallback(() => {
    if (isAccordionOpened) {
      makeToast({
        message: t("common.drag_is_disabled"),
        type: STRINGS.toastType.error,
      });
    }
  }, [isAccordionOpened]);

  // TODO enable for all other implemented socials
  const isExpandDisabled = useMemo(() => {
    if (item.platform === "youtube") return false;
    if (item.platform === "instagram") return false;
    if (item.platform === "facebook") return false;
    return item.platform !== "tiktok";
  }, [item]);

  return (
    <div className="collapsed_link">
      <div
        className="drag"
        {...elementToDragFor}
        onTouchStartCapture={() => {
          showDisabledDragMessage();
        }}
        onMouseDownCapture={(ev) => {
          if (ev.buttons && isAccordionOpened) {
            showDisabledDragMessage();
          }
        }}
      >
        <Drag />
      </div>
      <div className="icon">{item.icon}</div>
      <div className="info">
        <span className="title">{item.title}</span>
        <span className="link">
          {t("common.toVisit")}
          <LinkClick />
        </span>
      </div>
      <div className="actions">
        <div className="close" onClick={() => onDelete(item)}>
          <Close size={24} />
        </div>
        <div className="divider" />
        <div
          className={`arrow ${isAccordionOpened && "arrow_opened"} ${
            isExpandDisabled && "arrow_disabled"
          }`}
          onClick={() => {
            if (isExpandDisabled) return;
            setAccordionOpened((opened) => {
              socialOpenedEvent && socialOpenedEvent(!opened);
              return !opened;
            });
          }}
        >
          <ArrowDownSmall />
        </div>
      </div>
    </div>
  );
};

interface Props {
  item: IPublicLink;
  socialItemIndex: number;
  socialOpenedEvent?: (opened: boolean) => void;
  elementToDragFor: DraggableProvidedDragHandleProps | null | undefined;
  onDelete: (value: IPublicLink) => void;
  previewErrorStateChanged: (key: string, hasError: boolean) => void;
}

const Social: FC<Props> = ({
  item,
  onDelete,
  elementToDragFor,
  socialItemIndex,
  socialOpenedEvent,
  previewErrorStateChanged,
}) => {
  const [isAccordionOpened, setAccordionOpened] = useState<boolean>(false);

  const socialExpanded = useMemo(() => {
    return (
      <SocialExpanded
        links={item.contents}
        socialItemIndex={socialItemIndex}
        previewErrorStateChanged={previewErrorStateChanged}
      />
    );
  }, [item, socialItemIndex]);

  return (
    <SocialContainer>
      <Accordion2
        show={isAccordionOpened}
        className="accordion_wr"
        collapsedContent={
          <CollapsedContent
            item={item}
            isAccordionOpened={isAccordionOpened}
            setAccordionOpened={setAccordionOpened}
            onDelete={onDelete}
            elementToDragFor={elementToDragFor}
            socialOpenedEvent={socialOpenedEvent}
          />
        }
        expandedContent={socialExpanded}
      />
    </SocialContainer>
  );
};

export default Social;
