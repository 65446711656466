import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  skipCount: 0,
  filterData: {
    searchWord: "",
    category: null,
    contentType: null,
    navigatedTo: null,
  },
  subscribers: {
    data: [],
    total: 0,
  },
  paymentHistory: {
    data: [],
    total: 0,
  },
  feedSkipCount: 0,
  feedFilterData: {
    navigatedTo: null,
  },
};

const fanSlice = createSlice({
  name: "fan",
  initialState,
  reducers: {
    setSkipCount: (state, action) => {
      state.skipCount = action.payload.skipCount;
    },
    setSubscribers: (state, action) => {
      state.subscribers.data = action.payload.result;
      state.subscribers.total = action.payload.totalUser;
    },
    setFilterData: (state, action) => {
      state.filterData = action.payload.filterData;
    },
    setFeedSkipCount: (state, action) => {
      state.feedSkipCount = action.payload.skipCount;
    },
    setFeedFilterData: (state, action) => {
      state.feedFilterData = action.payload.filterData;
    },
  },
});

export const { setSkipCount, setFilterData, setFeedSkipCount, setFeedFilterData, setSubscribers } =
  fanSlice.actions;

export default fanSlice.reducer;
export const getSkipCount = ({ fan }) => fan.fan.skipCount;
export const getSubscribers = ({ fan }) => fan.fan.subscribers;
export const getFilterData = ({ fan }) => fan.fan.filterData;
export const getFeedSkipCount = ({ fan }) => fan.fan.feedSkipCount;
export const getFeedFilterData = ({ fan }) => fan.fan.feedFilterData;
