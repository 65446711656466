import { createSlice } from "@reduxjs/toolkit";

const getUniqueVideos = ({ append, oldData, newData }) => {
  if (append) {
    // creates new array of new videos
    const newArr = [];

    // loop over new videos
    for (let j = 0; j < newData.length; j++) {
      let obj = true;

      // loop over old videos
      for (let i = 0; i < oldData.length; i++) {
        // check in every old video ID is equal to any new video ID or not
        if (oldData[i]._id === newData[j]._id) {
          obj = false;
          break;
        }
      }

      // if any id does not matche of new and old people then only add people from the new data array
      if (obj) newArr.push(newData[j]);
    }
    return [...oldData, ...newArr];
  }
  return newData;
};

const initialState = {
  earningReport: {
    data: [],
  },
  approvedVideosOfCurrentWeek: null,
  summaryTableVideos: {
    totalVideos: "",
    data: null,
  },
  videoScreen: {
    totalVideos: "",
    data: [],
  },
  CampaignVideoScreen: {
    totalVideos: "",
    data: [],
  },
  allvideos: {
    totalVideos: "",
    data: [],
  },
  videosDetails: {
    data: [],
  },
  creators: {
    totalCreators: "",
    data: [],
  },
  verification: {
    verify: false,
  },
  videosPage: {
    videos: [],
    filterOptions: {
      status: "all",
      statusLabel: "",
      date: "all",
      dateLabel: "",
    },
  },
  campaignVideosPage: {
    videos: [],
    filterOptions: {
      status: "all",
      statusLabel: "",
      date: "all",
      dateLabel: "",
    },
  },
  ideaCampaignVideosPage: {
    videos: [],
    filterOptions: {
      status: "all",
      statusLabel: "",
      date: "all",
      dateLabel: "",
    },
  },
  loadmore: false,
  community: {
    total: "",
    data: [],
  },
  profileGrowth: [],
  popularMonetizedVideos: {
    total: "",
    data: null,
  },
  editProfileInputs: {},
  skipCount: 0,
  filterData: {
    searchWord: "",
    minPrice: "",
    maxPrice: "",
    level: null,
    dateRanges: null,
    sortType: null,
    sortFrom: null,
    navigatedTo: null,
  },
  campaignFilterData: {
    searchWord: "",
    sortType: null,
    sortFrom: null,
    navigatedTo: null,
    period: null,
    status: null,
    category: null,
  },
  skipMyNftCount: 0,
  filterMyNftData: {
    searchWord: "",
    status: null,
    dateRanges: null,
    orderBy: null,
    navigatedTo: null,
  },
  campaignVideosDetails: {
    title: "Hymn for the weekend",
    status: "Approved",
    category: "Design ",
    submitDate: "01/07/22",
    hashTags: "#design, #enjoy #meetups",
    ammountReceived: "1,000 Lewk",
    videoLink: "https://www.figma.com/file/wIXafyMy9jk9GObOyIzwPH/Lewk?node-id=594%3A1816",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, commodo Read More...",
  },
  myFeed: {
    data: [],
    total: 0,
  },
  campaign: [],
  campaignCount: 0,
  skipCampaignCount: 0,
  campaignCreatorVideoFilterData: {
    searchWord: "",
    submittedDate: null,
    navigatedTo: null,
    status: null,
  },
  campaignCreatorVideo: [],
  contentMediaType: null,
  campaignCreatorVideoCount: 0,
  skipCampaignCreatorVideoCount: 0,
  singleCampaignDetails: {},
  subscriptionManage: {
    data: [],
    total: 0,
  },
  subscribersList: {
    data: [],
    total: 0,
  },
  subscriptionRefundReqList: [],
  subscriptionRefundReqCount: 0,
  skipSubscriptionRefundReqCount: 0,
  earnings: {
    data: [],
    total: 0,
  },
  totalEarning: 0,
  monthlyEarning: 0,
  skipGiftingCount: 0,
  giftingFilterData: {
    type: null,
    navigatedTo: null,
  },
  publisherIdeas: [],
  campaignIdeas: [],
  campaignIdeasCount: 0,
  skipCampaignIdeasCount: 0,
  campaignIdeasFilterData: {
    searchWord: "",
    navigatedTo: null,
    category: null,
    sortType: null,
    sortFrom: null,
  },
  activeCampaignIdeas: [],
  activeCampaignIdeasCount: 0,
  skipActiveCampaignIdeasCount: 0,
  activeCampaignIdeasFilterData: {
    searchWord: "",
    navigatedTo: null,
    type: null,
    period: null,
    sortType: null,
    sortFrom: null,
  },
  skipMyProposalsCount: 0,
  myProposalsFilterData: {
    searchWord: "",
    navigatedTo: null,
  },
  rewardList: [],
  rewardListCount: 0,
  skipRewardListCount: 0,
};

const creatorSlice = createSlice({
  name: "creator",
  initialState,
  reducers: {
    setEditProfileInputs: (state, action) => {
      state.editProfileInputs[action.payload.name] = action.payload.value;
    },
    setSkipMyProposalsCount: (state, action) => {
      state.skipMyProposalsCount = action.payload.skipCount;
    },
    setMyProposalsFilterData: (state, action) => {
      state.myProposalsFilterData = action.payload.filterData;
    },
    setSubscriptionPlan: (state, action) => {
      state.subscriptionManage.data = [
        ...state.subscriptionManage.data,
        ...action.payload?.result?.data,
      ];
      state.subscriptionManage.total = action.payload.totalPlans;
    },
    resetSubscriptionPlan: (state) => {
      state.subscriptionManage.data = [];
      state.subscriptionManage.total = 0;
    },
    editEnableSubscriptionPlan: (state, action) => {
      state.subscriptionManage.data = state.subscriptionManage.data.map((item) => {
        if (item._id === action.payload.id) {
          return {
            ...item,
            active: action.payload.active,
          };
        }
        return item;
      });
    },
    setActiveCampaignIdeas: (state, action) => {
      state.activeCampaignIdeas = action.payload?.data;
      state.activeCampaignIdeasCount = action.payload?.count;
    },
    setPublisherCampaignIdeas: (state, action) => {
      state.publisherIdeas = action.payload?.result;
    },
    setSkipActiveCampaignIdeasCount: (state, action) => {
      state.skipActiveCampaignIdeasCount = action.payload.skipCount;
    },
    setActiveCampaignIdeasFilterData: (state, action) => {
      state.activeCampaignIdeasFilterData = action.payload.filterData;
    },
    setCampaignIdeas: (state, action) => {
      state.campaignIdeas = action.payload?.data;
      state.campaignIdeasCount = action.payload?.totalCampaign;
    },
    setSkipCampaignIdeasCount: (state, action) => {
      state.skipCampaignIdeasCount = action.payload.skipCount;
    },
    setCampaignIdeasFilterData: (state, action) => {
      state.campaignIdeasFilterData = action.payload.filterData;
    },
    setEarnings: (state, action) => {
      state.earnings.data = action.payload?.data?.records;
      state.earnings.total = action.payload?.data?.totalDocument;
      state.totalEarning = action?.payload?.data?.totalEarned;
      state.monthlyEarning = action?.payload?.data?.totalLast30daysEarned;
    },
    setGiftingFilterData: (state, action) => {
      state.giftingFilterData = action.payload.filterData;
    },
    setMyFeed: (state, action) => {
      state.myFeed.data = [...state.myFeed.data, ...action.payload.result];
      state.myFeed.total = action.payload.totalCount;
    },
    deletePost: (state, action) => {
      state.myFeed.data = state.myFeed.data.filter((item) => item._id !== action.payload);
    },
    changeStatusPost: (state, action) => {
      state.myFeed.data = state.myFeed.data.map((item) => {
        if (item._id === action.payload.id) {
          return {
            ...item,
            enabled: action.payload.enabled,
          };
        }
        return item;
      });
    },
    resetMyFeed: (state) => {
      state.myFeed.data = [];
      state.myFeed.total = 0;
    },
    setCampaigns: (state, action) => {
      state.campaign = action.payload.data;
      state.campaignCount = action.payload.totalCampaign;
    },
    setSubscriptionRefundReq: (state, action) => {
      state.subscriptionRefundReqList = action.payload.result;
      state.subscriptionRefundReqCount = action.payload.totalCount;
    },
    setSkipSubscriptionRefundReqCount: (state, action) => {
      state.skipSubscriptionRefundReqCount = action.payload.skipCount;
    },
    setSubscribers: (state, action) => {
      state.subscribersList.data = action.payload.data;
      state.subscribersList.total = action.payload.count;
    },
    setSingleCampaignDetails: (state, action) => {
      state.singleCampaignDetails = action.payload.data;
    },
    setContentMediaType: (state, action) => {
      state.contentMediaType = action.payload;
    },
    setEarningReport: (state, action) => {
      state.earningReport = action.payload;
    },
    setPopularMonetizedVideos: (state, action) => {
      state.popularMonetizedVideos.total = action.payload.total;
      action.payload === null
        ? (state.popularMonetizedVideos.data = null)
        : (state.popularMonetizedVideos.data = {
            ...state.popularMonetizedVideos.data,
            [`page${[action.payload.page]}`]: action.payload.data,
          });
    },
    setProfileGrowth: (state, action) => {
      state.profileGrowth = action.payload;
    },
    setCommunity: (state, action) => {
      const { total, append, data } = action.payload;

      state.community.total = total;
      state.community.data = getUniqueVideos({
        append,
        oldData: state.community.data,
        newData: data,
      });
    },
    setCampaignVideosDetails: (state, action) => {
      state.campaignVideosDetails = action.payload;
    },
    setApprovedVideosOfCurrentWeek: (state, action) => {
      state.approvedVideosOfCurrentWeek = action.payload;
    },
    setVideoScreenData: (state, action) => {
      state.videoScreen.totalVideos = action.payload.totalVideos;
      state.videoScreen.data = getUniqueVideos({
        append: action.payload.append,
        oldData: state.videoScreen.data,
        newData: action.payload.data,
      });
    },
    setCampaignVideoScreenData: (state, action) => {
      state.CampaignVideoScreen.totalVideos = action.payload.totalVideos;
      state.CampaignVideoScreen.data = getUniqueVideos({
        append: action.payload.append,
        oldData: state.CampaignVideoScreen.data,
        newData: action.payload.data,
      });
    },
    setVideos: (state, action) => {
      state.summaryTableVideos.totalVideos = action.payload.totalVideos;
      action.payload === null
        ? (state.summaryTableVideos.data = null)
        : (state.summaryTableVideos.data = {
            ...state.summaryTableVideos.data,
            [`page${[action.payload.page]}`]: action.payload.data,
          });
    },
    setAllVideos: (state, action) => {
      state.allvideos.totalVideos = action.payload.totalVideos;
      state.allvideos.data = getUniqueVideos({
        append: action.payload.append,
        oldData: state.allvideos.data,
        newData: action.payload.videos,
      });
    },
    setVideosDetails: (state, action) => {
      state.videosDetails.data = getUniqueVideos({
        append: action.payload.append,
        oldData: state.videosDetails.data,
        newData: action.payload.videos,
      });
    },
    setVideosFilterOptions: (state, action) => {
      state.videosPage.filterOptions[action.payload.name] = action.payload.value;
    },
    setCampaignVideosFilterOptions: (state, action) => {
      state.campaignVideosPage.filterOptions[action.payload.name] = action.payload.value;
    },
    setIdeaCampaignVideosFilterOptions: (state, action) => {
      state.ideaCampaignVideosPage.filterOptions[action.payload.name] = action.payload.value;
    },
    setCreators: (state, action) => {
      state.creators.totalCreators = action.payload.total;
      action.payload === null
        ? (state.creators.data = null)
        : action.payload.newData
        ? (state.creators.data = {
            [`page${[action.payload.page]}`]: action.payload.data,
          })
        : (state.creators.data = {
            ...state.creators.data,
            [`page${[action.payload.page]}`]: action.payload.data,
          });
    },
    setVerification: (state, action) => {
      state.verification.verify = action.payload;
    },
    resetCreatorSlice: (state) => {
      state = initialState;
    },

    setCampaignFilterData: (state, action) => {
      state.campaignFilterData = action.payload.filterData;
    },
    setSkipCampaignCount: (state, action) => {
      state.skipCampaignCount = action.payload.skipCount;
    },
    setMyNftSkipCount: (state, action) => {
      state.skipMyNftCount = action.payload.skipCount;
    },
    setMyNftFilterData: (state, action) => {
      state.filterMyNftData = action.payload.filterData;
    },
    setCampaignsCreatorVideo: (state, action) => {
      state.campaignCreatorVideo = action.payload.result;
      state.campaignCreatorVideoCount = action.payload.totalCount;
    },
    setCampaignCreatorVideoFilterData: (state, action) => {
      state.campaignCreatorVideoFilterData = action.payload.filterData;
    },
    setSkipCampaignCreatorVideoCount: (state, action) => {
      state.skipCampaignCreatorVideoCount = action.payload.skipCount;
    },
  },
});

export const {
  setVideos,
  setEditProfileInputs,
  setEarningReport,
  setCreators,
  setCampaignVideosDetails,
  setVerification,
  setAllVideos,
  setMyFeed,
  changeStatusPost,
  resetMyFeed,
  setVideosDetails,
  setVideosFilterOptions,
  setCampaignVideosFilterOptions,
  resetCreatorSlice,
  setUploadVideoLoader,
  setVideoScreenData,
  setCampaignVideoScreenData,
  editEnableSubscriptionPlan,
  setApprovedVideosOfCurrentWeek,
  setCommunity,
  setProfileGrowth,
  setPopularMonetizedVideos,
  setSingleCampaignDetails,
  setContentMediaType,
  setMyNftSkipCount,
  setMyNftFilterData,
  setCampaigns,
  setCampaignFilterData,
  setSkipCampaignCount,
  setCampaignsCreatorVideo,
  setIdeaCampaignVideosFilterOptions,
  setCampaignCreatorVideoFilterData,
  setSkipCampaignCreatorVideoCount,
  setSubscribers,
  setSubscriptionRefundReq,
  setSkipSubscriptionRefundReqCount,
  setSubscriptionPlan,
  resetSubscriptionPlan,
  setEarnings,
  deletePost,
  setGiftingFilterData,
  setCampaignIdeas,
  setCampaignIdeasFilterData,
  setSkipCampaignIdeasCount,
  setActiveCampaignIdeas,
  setActiveCampaignIdeasFilterData,
  setSkipActiveCampaignIdeasCount,
  setPublisherCampaignIdeas,
  setSkipMyProposalsCount,
  setMyProposalsFilterData,
} = creatorSlice.actions;
export default creatorSlice.reducer;

export const getEditProfileInputs = ({ creator }) => creator.main.editProfileInputs;
export const getVideos = ({ creator }) => creator.main.summaryTableVideos;
export const getEarningReport = ({ creator }) => creator.main.earningReport;
export const getPopularMonetizedVideos = ({ creator }) => creator.main.popularMonetizedVideos;
export const getCampaignVideosDetails = ({ creator }) => creator.main.campaignVideosDetails;
export const getProfileGrowth = ({ creator }) => creator.main.profileGrowth;
export const getCreators = ({ creator }) => creator.main.creators;
export const getVideosDetails = ({ creator }) => creator.main.videosDetails;
export const getVerification = ({ creator }) => creator.main.verification.verify;
export const getAllVideos = ({ creator }) => creator.main.allvideos;
export const getApprovedVideosOfCurrentWeek = ({ creator }) =>
  creator.main.approvedVideosOfCurrentWeek;
export const getVideosFilterOptions = ({ creator }) => creator.main.videosPage.filterOptions;
export const getCampaignVideosFilterOptions = ({ creator }) =>
  creator.main.campaignVideosPage.filterOptions;
export const getIdeaCampaignVideosFilterOptions = ({ creator }) =>
  creator.main.ideaCampaignVideosPage.filterOptions;
export const getVideoScreenData = ({ creator }) => creator.main.videoScreen;
export const getCampaignVideoScreenData = ({ creator }) => creator.main.CampaignVideoScreen;
export const getCommunity = ({ creator }) => creator.main.community;
export const getSkipCount = ({ creator }) => creator.main.skipCount;
export const getFilterData = ({ creator }) => creator.main.filterData;
export const getMyNftSkipCount = ({ creator }) => creator.main.skipMyNftCount;
export const getCampaignFilterData = ({ creator }) => creator.main.campaignFilterData;
export const getSkipCampaignCount = ({ creator }) => creator.main.skipCampaignCount;
export const getMyFeed = ({ creator }) => creator.main.myFeed;
export const getCampaignsList = ({ creator }) => creator.main.campaign;
export const getCampaignCount = ({ creator }) => creator.main.campaignCount;
export const getMyNftFilterData = ({ creator }) => creator.main.filterMyNftData;
export const getContentMediaType = ({ creator }) => creator.main.contentMediaType;
export const getCampaignCreatorVideoFilterData = ({ creator }) =>
  creator.main.campaignCreatorVideoFilterData;
export const getSkipCampaignCreatorVideoCount = ({ creator }) =>
  creator.main.skipCampaignCreatorVideoCount;
export const getCampaignsCreatorVideoList = ({ creator }) => creator.main.campaignCreatorVideo;
export const getCampaignCreatorVideoCount = ({ creator }) => creator.main.campaignCreatorVideoCount;
export const getSingleCampaignDetails = ({ creator }) => creator.main.singleCampaignDetails;
export const getSubscribersList = ({ creator }) => creator.main.subscribersList;
export const getSkipSubscriptionRefundReqCount = ({ creator }) =>
  creator.main.skipSubscriptionRefundReqCount;
export const getSubscriptionRefundReqList = ({ creator }) => creator.main.subscriptionRefundReqList;
export const getSubscriptionRefundReqCount = ({ creator }) =>
  creator.main.subscriptionRefundReqCount;
export const getSubscriptionManage = ({ creator }) => creator.main.subscriptionManage;
export const getEarnings = ({ creator }) => creator.main.earnings;
export const getTotalEarnings = ({ creator }) => creator.main.totalEarning;
export const getMonthlyEarnings = ({ creator }) => creator.main.monthlyEarning;
export const getGiftingFilterData = ({ creator }) => creator.main.giftingFilterData;
export const getCampaignIdeas = ({ creator }) => creator.main.campaignIdeas;
export const getCampaignIdeasCount = ({ creator }) => creator.main.campaignIdeasCount;
export const getSkipCampaignIdeasCount = ({ creator }) => creator.main.skipCampaignIdeasCount;
export const getCampaignIdeasFilterData = ({ creator }) => creator.main.campaignIdeasFilterData;
export const getActiveCampaignIdeas = ({ creator }) => creator.main.activeCampaignIdeas;
export const getActiveCampaignIdeasCount = ({ creator }) => creator.main.activeCampaignIdeasCount;
export const getSkipActiveCampaignIdeasCount = ({ creator }) =>
  creator.main.skipActiveCampaignIdeasCount;
export const getActiveCampaignIdeasFilterData = ({ creator }) =>
  creator.main.activeCampaignIdeasFilterData;
export const getPublisherCampaignIdeas = ({ creator }) => creator.main.publisherIdeas;
export const getSkipMyProposalsCount = ({ creator }) => creator.main.skipMyProposalsCount;
export const getMyProposalsFilterData = ({ creator }) => creator.main.myProposalsFilterData;
export const getRewardsList = ({ creator }) => creator.main.rewardList;
export const getSkipRewardsListCount = ({ creator }) => creator.main.skipRewardListCount;
export const getRewardsListCount = ({ creator }) => creator.main.rewardListCount;
