import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "store/store";
import { LANGUAGES } from "types/language.types";

interface IInititalStateGlobal {
  isProfileUpdated: boolean;
  playerVolume: string;
  supportedPlatforms: string[];
  supportedLanguages: any[];
  currencies?: any[] | null;
  availableCurrencies: null;
  changeCurrenciesIDs: any[];
  viewPicture: {
    show: boolean;
    type: string;
    src: string;
  };
  enabledTokens: any[];
  countries: any[];
}

const initialState: IInititalStateGlobal = {
  isProfileUpdated: false,
  playerVolume: "1",
  supportedPlatforms: ["tiktok", "twitter"],
  supportedLanguages: [LANGUAGES.english, LANGUAGES.russian, LANGUAGES.ukrainian],
  currencies: null,
  availableCurrencies: null,
  changeCurrenciesIDs: [],
  viewPicture: {
    show: false,
    type: "image",
    src: "",
  },
  enabledTokens: [],
  countries: [],
};

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setViewPicture: (state, action) => {
      const { src, show, type } = action.payload;
      state.viewPicture.src = src;
      state.viewPicture.type = type ?? "image";
      state.viewPicture.show = show;
    },
    setCurrencies: (state, action) => {
      state.currencies = action.payload;
    },
    setIsProfileUpdated: (state, action) => {
      state.isProfileUpdated = action.payload;
    },
    setCountries: (state, action) => {
      state.countries = action.payload;
    },
    setVolume: (state, action) => {
      state.playerVolume = action.payload;
    },
    setAvailableCurrencies: (state, action) => {
      state.availableCurrencies = action.payload;
    },
    changeCurrencies: (state, action) => {
      const { name, value, id } = action.payload;
      const newArr = state.currencies?.map((item) =>
        item._id === id ? { ...item, [name]: value } : item
      );
      if (!state.changeCurrenciesIDs.includes(id))
        state.changeCurrenciesIDs = [...state.changeCurrenciesIDs, id];
      state.currencies = newArr;
    },
    setSupportedPlatforms: (state, action) => {
      state.supportedPlatforms = action.payload;
    },
    setSupportedLanguages: (state, action) => {
      state.supportedLanguages = action.payload;
    },
    resetGlobalSlice: (state) => {
      state = initialState;
    },
  },
});

export const {
  setViewPicture,
  changeCurrencies,
  setCurrencies,
  setSupportedPlatforms,
  resetGlobalSlice,
  setAvailableCurrencies,
  setCountries,
  setVolume,
  setIsProfileUpdated,
} = globalSlice.actions;

export default globalSlice.reducer;

export const getViewPicture = ({ global }: RootState) => global.global.viewPicture;
export const getVolume = ({ global }: RootState) => global.global.playerVolume;
export const getCurrencyStatus = ({ global }: RootState) => global.global.currencies;
export const getSupportedPlatforms = ({ global }: RootState) => global.global.supportedPlatforms;
export const getSupportedLanguages = ({ global }: RootState) => global.global.supportedLanguages;
export const getAvailabeCurrencyStatus = ({ global }: RootState) =>
  global.global.availableCurrencies;
export const getEnabledTokens = ({ global }: RootState) => global.global.enabledTokens;
export const getCountryList = ({ global }: RootState) => global.global.countries;
export const getIsProfileUpdated = ({ global }: RootState) => global.global.isProfileUpdated;
