import getAxiosInst, { apiHandler, endpoints } from "./axios";

import { makeToast } from "common/helpers/helper";
import { STRINGS } from "constants/appConstants";
import { setFanFeed, setSuggestedCreators } from "store/slices/fan/activityFeedSlice";
import { setSubscribers } from "store/slices/fan/fanSlice";
import { setMyTransactions } from "store/slices/fan/myTransactionsSlice";
import { setAllSubscriptions, setSubscriptionsForYou } from "store/slices/fan/subscriptionsSlice";
import { setLoader } from "store/slices/featureSlice";
import { setFeed, setPlans, setPublicUser } from "store/slices/global/previewProfileSlice";
import store from "store/store";

export const fetchFeeds = (apiData) => {
  const { skip, limit, filter } = apiData;
  store.dispatch(setLoader({ name: "contentLoader", value: true }));
  apiHandler(
    () =>
      getAxiosInst().get(`${endpoints.fanFeeds}`, {
        params: {
          skip,
          limit,
          sort: filter.sort,
          search: filter.search,
          contentTypes: filter.content,
          privacy: filter.privacy,
        },
      }),
    {
      onSuccess: (res) => {
        store.dispatch(setFanFeed({ data: res.result, total: res.totalData }));
      },
      onError: (error) => {
        console.log(error);
      },
      final: () => {
        store.dispatch(setLoader({ name: "contentLoader", value: false }));
      },
    }
  );
};

export const fetchFeedsByCreator = (apiData, callback = (res) => {}) => {
  store.dispatch(setLoader({ name: "campaignLoader", value: true }));
  const apiPoint =
    apiData.role === "fan" ? endpoints.fanFeedsByCreator : endpoints.creatorFeedsByCreator;
  apiHandler(
    () =>
      getAxiosInst().get(
        `${endpoints.creatorFeedsByCreator}?creatorId=${apiData.id}&limit=${apiData.limit}&skip=${apiData.skip}`,
        apiData.filterParams
      ),
    {
      onSuccess: (res) => {
        callback(res);
        store.dispatch(
          setFeed({
            data: res.result.reverse(),
            total: res.feedCounts,
            skip: apiData.skip + apiData.limit,
          })
        );
      },
      onError: (error) => {
        callback(error);
      },
      final: () => {
        store.dispatch(setLoader({ name: "campaignLoader", value: false }));
      },
    }
  );
};

export const fetchFeedsBySubscriptionId = (apiData, callback = (res) => {}) => {
  apiHandler(
    () =>
      getAxiosInst().get(
        `${endpoints.fanFeedsBySubscription}?creatorId=${apiData.creatorId}&limit=${apiData.limit}&skip=${apiData.skip}&subscriptionId=${apiData.subscriptionId}`,
        apiData.filterParams
      ),
    {
      onSuccess: (res) => {
        callback(res);
      },
      onError: (error) => {
        callback(error);
      },
    }
  );
};

export const fetchCreators = (apiData, callback = () => {}) => {
  apiHandler(
    () =>
      getAxiosInst().post(
        `${endpoints.findCreators}?limit=${apiData.limit}&skip=${apiData.skip}`,
        apiData.filterParams
      ),
    {
      onSuccess: (res) => {
        callback(res);
      },
      onError: (error) => {
        callback(error);
      },
    }
  );
};

export const deleteSubscription = (apiData, callback = () => {}) => {
  apiHandler(
    () =>
      getAxiosInst().post(
        `${endpoints.deleteSubscriptionPlanAsFan}?plan_id=${apiData.id}`,
        apiData.data
      ),
    {
      onSuccess: (data) => {
        makeToast({
          message: "Subscription canceled successfully",
          type: STRINGS.toastType.success,
        });
        callback(data);
      },
      onError: (error) => {
        makeToast({
          message: error?.response?.data?.message || "Unable to Cancel Subscription",
          type: STRINGS.toastType.error,
        });
        callback(error);
      },
    }
  );
};

export const fetchPlans = (apiData, replaceAllPlans = false) => {
  const { limit, skip, creatorId } = apiData;
  store.dispatch(setLoader({ name: "campaignLoader", value: true }));
  const apiPoint = apiData.role === "fan" ? endpoints.planList : endpoints.creatorPlanList;
  const creatorid = apiData.role === "fan" ? "creatorId" : "creator_id";
  apiHandler(
    () =>
      getAxiosInst().get(`${apiPoint}`, {
        params: {
          limit,
          skip,
          [creatorid]: creatorId,
        },
      }),
    {
      onSuccess: (res) => {
        store.dispatch(
          setPlans({
            data: res.result,
            total: res.planCount,
            subscribedFeedCount: res.subscribedFeedCount,
            skip: apiData.skip + apiData.limit,
            replaceAllPlans,
          })
        );
      },
      onError: (error) => {
        console.log(error);
      },
      final: () => {
        store.dispatch(setLoader({ name: "campaignLoader", value: false }));
      },
    }
  );
};

export const fetchUserDetails = (apiData, callback = (res) => {}) => {
  store.dispatch(setLoader({ name: "creatorProfileLoader", value: true }));

  apiHandler(
    () => getAxiosInst().get(`/creator${endpoints.creatorProfile}?creatorId=${apiData.creatorId}`),
    {
      onSuccess: (res) => {
        store.dispatch(setPublicUser(res.result));
        callback(res);
      },
      onError: (error) => {
        callback(error);
      },
      final: () => {
        store.dispatch(setLoader({ name: "creatorProfileLoader", value: false }));
      },
    }
  );
};

export const fetchFeedDetails = (apiData) => {
  store.dispatch(setLoader({ name: "campaignLoader", value: true }));

  const api = apiData?.accountType ? endpoints.fanSingleFeed : endpoints.fanSinglePublicFeed;
  apiHandler(() => getAxiosInst().get(`${api}?feedId=${apiData.feedId}`), {
    onSuccess: (res) => {
      store.dispatch(setFeed({ data: res.result, total: res.feedCount }));
    },
    onError: (error) => {
      makeToast({
        message: error.response?.data?.message || "Fetching feed failed",
        type: STRINGS.toastType.error,
      });
    },
    final: () => {
      store.dispatch(setLoader({ name: "campaignLoader", value: false }));
    },
  });
};

export const fetchFeedUpdating = (apiData, callback = () => {}) => {
  apiHandler(() => getAxiosInst().post(`${endpoints.feedCount}?feedId=${apiData.feedId}`), {
    onSuccess: (res) => {
      callback(res);
    },
    onError: (error) => {
      callback(error);
    },
  });
};

export const purchaseSubscriptionPlan = (apiData, callback = (data) => {}) => {
  store.dispatch(setLoader({ name: "giftingLoader", value: true }));
  apiHandler(
    () =>
      getAxiosInst().post(
        `${endpoints.buySubscription}?subscriptionId=${apiData.id}`,
        apiData.params
      ),
    {
      onSuccess: (data) => {
        makeToast({
          message: data?.message,
          type: STRINGS.toastType.success,
        });
        callback(data.data.subscription);
      },
      onError: (error) => {
        makeToast({
          message: error?.response?.data?.message || "Unable to purchase subscription plan!",
          type: STRINGS.toastType.error,
        });
      },
      final: () => {
        store.dispatch(setLoader({ name: "giftingLoader", value: false }));
      },
    }
  );
};

export const getPaymentHistoryList = ({ skip, limit, filter }) => {
  store.dispatch(setLoader({ name: "paymentHistoryLoader", value: true }));
  apiHandler(
    () =>
      getAxiosInst().get(`${endpoints.getFanPaymentHistory}`, {
        params: {
          limit,
          skip,
          isGifting: filter.type === "gifting" ? 1 : undefined,
          isSubscription: filter.type === "subscription" ? 1 : undefined,
          search: filter.search,
          amountLte: filter.to,
          amountGte: filter.from,
        },
      }),
    {
      onSuccess: (res) => {
        store.dispatch(setMyTransactions(res));
      },
      onError: (error) => {
        console.log("Error:", error.response);
      },
      final: () => {
        store.dispatch(setLoader({ name: "paymentHistoryLoader", value: false }));
      },
    }
  );
};

export const getSuggestedCreatorsApi = (apiData) => {
  store.dispatch(setLoader({ name: "nftLoader", value: true }));
  apiHandler(
    () =>
      getAxiosInst().get(
        `${endpoints.suggestedCreators}?limit=${apiData.limit}&skip=${apiData.skip}`
      ),
    {
      onSuccess: (res) => {
        store.dispatch(setSuggestedCreators({ data: res.result, total: res.result.length }));
      },
      onError: (error) => {
        console.log(error);
      },
      final: () => {
        store.dispatch(setLoader({ name: "nftLoader", value: false }));
      },
    }
  );
};

export const getSubscribedCreators = (apiData, callback = () => {}) => {
  apiHandler(
    () =>
      getAxiosInst().get(
        `${endpoints.subscribedCreators}?limit=${apiData.limit}&skip=${apiData.skip}`
      ),
    {
      onSuccess: (res) => {
        callback(res);
      },
      onError: (error) => {
        callback(error);
      },
    }
  );
};

export const getSubscriptionList = (apiData) => {
  store.dispatch(setLoader({ name: "subscriberLoader", value: true }));
  apiHandler(
    () =>
      getAxiosInst().get(
        `${endpoints.fanSubscriptionList}?limit=${apiData.limit}&skip=${apiData.skip}`
      ),
    {
      onSuccess: (res) => {
        store.dispatch(setSubscribers(res));
      },
      onError: (error) => {
        console.log("Error:", error.response);
      },
      final: () => {
        store.dispatch(setLoader({ name: "subscriberLoader", value: false }));
      },
    }
  );
};

export const getSubscriptionListById = (apiData, callback = (res) => {}) => {
  apiHandler(
    () =>
      getAxiosInst().get(
        `${endpoints.feedsBySubscriptionPlan}?creatorId=${apiData.creatorId}&limit=${apiData.limit}&skip=${apiData.skip}`
      ),
    {
      onSuccess: (res) => {
        callback(res);
      },
      onError: (error) => {
        callback(error);
      },
    }
  );
};

export const getAllSubscriptionApi = (apiData) => {
  const { skip, limit, filter } = apiData;
  store.dispatch(setLoader({ name: "subscriptionPlan", value: true }));
  apiHandler(
    () =>
      getAxiosInst().get(`${endpoints.allSubscriptions}`, {
        params: {
          skip,
          limit,
          sort: filter.sort,
          search: !filter.search ? undefined : filter.search,
          IsSubscribed: filter.isSubscribe,
        },
      }),
    {
      onSuccess: (res) => {
        store.dispatch(setAllSubscriptions({ data: res.result.data, total: res.totalPlans }));
      },
      onError: (error) => {
        makeToast({
          message: error.message || "Failed to load Subscriction plans",
          type: STRINGS.toastType.error,
        });
      },
      final: () => {
        store.dispatch(setLoader({ name: "subscriptionPlan", value: false }));
      },
    }
  );
};

export const getSubscriptionForYouApi = (apiData) => {
  const { limit } = apiData;
  store.dispatch(setLoader({ name: "subscriptionPlan", value: true }));
  apiHandler(
    () =>
      getAxiosInst().get(`${endpoints.allSubscriptions}`, {
        params: {
          skip: 0,
          limit,
          sort: "PostsHighToLow",
          IsSubscribed: 0,
        },
      }),
    {
      onSuccess: (res) => {
        store.dispatch(setSubscriptionsForYou({ data: res.result.data, total: res.totalPlans }));
      },
      onError: (error) => {
        makeToast({
          message: error.message || "Failed to load Subscriction plans",
          type: STRINGS.toastType.error,
        });
      },
      final: () => {
        store.dispatch(setLoader({ name: "subscriptionPlan", value: false }));
      },
    }
  );
};

export const getRefundList = (apiData, callback = () => {}) => {
  apiHandler(
    () =>
      getAxiosInst().get(`${endpoints.fanRefundList}?limit=${apiData.limit}&skip=${apiData.skip}`),
    {
      onSuccess: (res) => {
        callback(res);
      },
      onError: (error) => {
        console.log("Error:", error.response);
        callback(error);
      },
    }
  );
};
export const refundNote = (apiData, callback = () => {}) => {
  apiHandler(
    () => getAxiosInst().post(`${endpoints.fanRefundNote}?reportId=${apiData.id}`, apiData.data),
    {
      onSuccess: (data) => {
        makeToast({
          message: "Report Send successfully",
          type: STRINGS.toastType.success,
        });
        callback(data);
      },
      onError: (error) => {
        makeToast({
          message: error?.response?.data?.message || "Unable to Send Report",
          type: STRINGS.toastType.error,
        });
        callback(error);
      },
    }
  );
};
