import { FC } from "react";
import { createPortal } from "react-dom";

import { Container } from "./style";

import MiniLoader from "../MiniLoader";

interface Props {
  isLoading: boolean;
  className?: string;
}

const OverLayLoader: FC<Props> = ({ isLoading, className = "" }) => (
  <>
    {createPortal(
      <Container show={isLoading} className={className}>
        {isLoading && <MiniLoader size="40px" />}
      </Container>,
      document.getElementById("root") as HTMLElement
    )}
  </>
);

export default OverLayLoader;
