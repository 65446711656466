import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RootState } from "store/store";

import { IChallenges, IParticipant } from "types/challenges";

interface IInitialState {
  challenge: IChallenges;
  participants: IParticipant[];
  total: number;
}

const initialState: IInitialState = {
  challenge: {
    _id: "",
    title: "",
    duration: 30,
    createdBy: "",
    type: "",
    videolink: "",
    view_count: 0,
    views: [],
    thumbnail: "",
    description: "",
    rules: "",
    country: [],
    hashtags: [],
    participants: [],
    social_media_type: "",
    total_paid: 0,
    isJudgeAllowed: false,
    isActive: true,
    reward: "",
    currency: "",
    milestones: [{ views: 0, prize: 0 }],
    createdAt: "",
    updatedAt: "",
    expectedParticipants: 0,
    creator: {
      _id: "",
      picture: "",
      displayname: "",
      pictureUrl_80_80: "",
      username: "",
    },
    participantCount: 0,
    trendparticipants: [],
  },
  participants: [],
  total: 0,
};

const challengeDetailsSlice = createSlice({
  name: "challengeDetails",
  initialState,
  reducers: {
    setChallenge: (state, action: PayloadAction<IChallenges>) => {
      state.challenge = action.payload;
    },
    setParticipates: (state, action) => {
      state.participants = [...state.participants, ...action.payload.participants];
      state.total = action.payload.total;
    },
    resetDetails: () => {
      return { ...initialState };
    },
  },
});

export const { setChallenge, setParticipates, resetDetails } = challengeDetailsSlice.actions;

export default challengeDetailsSlice.reducer;

export const getChallenge = ({ creator: { challenging } }: RootState) =>
  challenging.details.challenge;
export const getParticipates = ({ creator: { challenging } }: RootState) => {
  return { data: challenging.details.participants, total: challenging.details.total };
};
