import { createSlice } from "@reduxjs/toolkit";

import { ROLES, STRINGS } from "constants/appConstants";

const removeItemFromWholeList = ({ id, oldData, totalRows }) => {
  let newData = {};

  for (const prop in oldData) {
    // if data is not found
    if (oldData[prop].findIndex((innerObj) => innerObj._id === id) === -1) {
      newData = { ...newData, [prop]: oldData[prop] };
    }
    // if data is found
    else {
      // data is removed from array
      newData = {
        ...newData,
        [prop]: oldData[prop].filter((innerObj) => {
          if (innerObj._id !== id) {
            return innerObj;
          }
        }),
      };
    }
  }

  // shift data from next array and delete the same data from next array
  let p = 1; // 4
  for (const prop in newData) {
    p++;
    if (newData[prop].length !== totalRows) {
      if (newData[`page${p}`]?.length >= 1) {
        newData[prop] = [...newData[prop], newData[`page${p}`][0]];
        const tempId = newData[`page${p}`][0]._id;
        newData[`page${p}`] = newData[`page${p}`].filter((item) => item._id !== tempId && item);
      }
    }
  }

  return newData;
};

const initialState = {
  allPeoples: {
    data: [],
    total: 0,
    all: 0,
  },
  searchPeopleInputs: {
    nameOrEmail: "",
    role: "creator",
    status: "inactive",
  },
  allPeoplesFilterInputs: {
    role: ROLES.creator,
    status: STRINGS.inActive,
  },
  newPeoples: null,
  totalNewUser: 0,
  userLevel: null,
  changedUserLevelIDs: [],
  customizePlatforms: null,
  changeCustomizePlatformsIDs: [],
  defaultLandingPages: [],
  changeDefaultLandingPagesIDs: [],
  maintenanceInputs: {
    msg: "",
    switch: null,
    registration: null,
    id: "",
  },
  referralCodes: [],
  monetizationLimits: {},
  monetizationLimitsIDs: [],
  timeIntervel: [],
  timeIntervelLowerLevel: [],
  averageView: [],
  minimumView: [],
  categoryReports: null,
  categories: [],
  nfts: [],
  nftCount: 0,
  skipCount: 0,
  campaign: [],
  campaignCount: 0,
  skipCampaignCount: 0,
  campaignFilterData: {
    searchWord: "",
    navigatedTo: null,
    period: null,
    status: null,
    category: null,
  },
  campaignDetails: {},
  creatorVideos: [],
  creatorVideosCount: 0,
  skipCreatorVideosCount: 0,
  creatorVideosFilterData: {
    searchWord: "",
    navigatedTo: null,
    admin_status: null,
    publisher_status: null,
    creator_status: null,
    submittedDate: null,
  },
  skipPaymentCount: 0,
  paymentFilterData: {
    searchWord: "",
    paymentStatus: null,
    searchType: null,
    transferredDate: null,
  },
  gifting: [],
  giftingCount: 0,
  skipGiftingCount: 0,
  messageReport: [],
  messageReportCount: 0,
  skipMessageReportCount: 0,
  transactionExplore: [],
  transactionExploreCount: 0,
  skipTransactionExploreCount: 0,
  transactionExploreFilterData: {
    searchWord: "",
    sortType: null,
    sortFrom: null,
    navigatedTo: null,
    date: null,
    transactionType: null,
  },
  feeInformation: [],
  feeInformationCount: 0,
  skipFeeInformationCount: 0,
  feeInformationFilterData: {
    date: null,
    category: null,
    navigatedTo: null,
    role: null,
    month: [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date()],
  },
  totalFee: 0,
  monthlyFee: 0,
  feeGraph: [],
  balance: [],
  balanceCount: 0,
  skipBalanceCount: 0,
  balanceTotal: 0,
  balanceFilterData: {
    searchWord: "",
  },
  emailTemplates: [],
  emailCount: 0,
};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setBalance: (state, action) => {
      state.balance = action.payload.result;
      state.balanceCount = action.payload.counts;
      state.balanceTotal = action.payload.totalBalace;
    },
    setSkipBalanceCount: (state, action) => {
      state.skipBalanceCount = action.payload.skipCount;
    },
    setBalanceFilterData: (state, action) => {
      state.balanceFilterData = action.payload.filterData;
    },
    setFeeInformation: (state, action) => {
      state.feeInformation = action.payload.result;
      state.feeInformationCount = action.payload.counts;
    },
    setMonthlyFeeInformation: (state, action) => {
      state.totalFee = action.payload.totalFees;
      state.monthlyFee = action.payload.monthFees;
      state.feeGraph = action.payload.result;
    },
    setSkipFeeInformationCount: (state, action) => {
      state.skipFeeInformationCount = action.payload.skipCount;
    },
    setFeeInformationFilterData: (state, action) => {
      state.feeInformationFilterData = {
        ...state.feeInformationFilterData,
        ...action.payload.filterData,
      };
    },
    setTransactionExplore: (state, action) => {
      state.transactionExplore = action.payload.result;
      state.transactionExploreCount = action.payload.counts;
    },
    setSkipTransactionExploreCount: (state, action) => {
      state.skipTransactionExploreCount = action.payload.skipCount;
    },
    setTransactionExploreFilterData: (state, action) => {
      state.transactionExploreFilterData = action.payload.filterData;
    },
    setTimeIntervel: (state, action) => {
      state.timeIntervel = action.payload;
    },
    setAverageView: (state, action) => {
      state.averageView = action.payload;
    },
    setMinimumView: (state, action) => {
      state.minimumView = action.payload;
    },
    setTimeIntervelLowerLevel: (state, action) => {
      state.timeIntervelLowerLevel = action.payload;
    },
    setMonetizationLimits: (state, action) => {
      state.monetizationLimits = action.payload;
    },
    setCategoryReports: (state, action) => {
      state.categoryReports = action.payload;
    },
    setCampaignDetails: (state, action) => {
      state.campaignDetails = action.payload?.data;
    },
    setMessageReport: (state, action) => {
      state.messageReport = action.payload.result;
      state.messageReportCount = action.payload.counts;
    },
    setSkipMessageReportCount: (state, action) => {
      state.skipMessageReportCount = action.payload.skipCount;
    },
    setCampaigns: (state, action) => {
      state.campaign = action.payload.data;
      state.campaignCount = action.payload.totalCampaign;
    },
    setSkipCampaignCount: (state, action) => {
      state.skipCampaignCount = action.payload.skipCount;
    },
    setCampaignFilterData: (state, action) => {
      state.campaignFilterData = action.payload.filterData;
    },
    setCreatorVideos: (state, action) => {
      state.creatorVideos = action.payload.result;
      state.creatorVideosCount = action.payload.totalCount;
    },
    setSkipCreatorVideosCount: (state, action) => {
      state.skipCreatorVideosCount = action.payload.skipCount;
    },
    setCreatorVideosFilterData: (state, action) => {
      state.creatorVideosFilterData = action.payload.filterData;
    },
    setSkipPaymentCount: (state, action) => {
      state.skipPaymentCount = action.payload.skipPaymentCount;
    },
    setPaymentFilterData: (state, action) => {
      state.paymentFilterData = action.payload.paymentFilterData;
    },
    updateMonetizationLimit: (state, action) => {
      const { name, value, id } = action.payload;
      state.monetizationLimits[name] = value;

      if (state.monetizationLimitsIDs.length === 0) state.monetizationLimitsIDs = [id];
    },
    setReferralCodes: (state, action) => {
      state.referralCodes = action.payload;
    },
    setMaintenanceInputs: (state, action) => {
      state.maintenanceInputs = action.payload;
    },
    setDefaultLandingPages: (state, action) => {
      state.defaultLandingPages = action.payload;
    },
    changeDefaultLandingPages: (state, action) => {
      const { name, value, id } = action.payload;
      const newArr = state.defaultLandingPages?.map((item) =>
        item._id === id ? { ...item, [name]: value } : item
      );
      if (!state.changeDefaultLandingPagesIDs.includes(id))
        state.changeDefaultLandingPagesIDs = [...state.changeDefaultLandingPagesIDs, id];
      state.defaultLandingPages = newArr;
    },
    setUserLevel: (state, action) => {
      state.userLevel = action.payload;
    },
    changeUserLevel: (state, action) => {
      const { name, value, id } = action.payload;
      const newArr = state.userLevel?.map((item) =>
        item._id === id ? { ...item, [name]: value } : item
      );
      if (!state.changedUserLevelIDs.includes(id))
        state.changedUserLevelIDs = [...state.changedUserLevelIDs, id];
      state.userLevel = newArr;
    },
    setCustomizePlatforms: (state, action) => {
      state.customizePlatforms = action.payload;
    },
    changeCustomizePlatforms: (state, action) => {
      const { name, value, id } = action.payload;
      const newArr = state.customizePlatforms?.map((item) =>
        item._id === id ? { ...item, [name]: value } : item
      );
      if (!state.changeCustomizePlatformsIDs.includes(id))
        state.changeCustomizePlatformsIDs = [...state.changeCustomizePlatformsIDs, id];
      state.customizePlatforms = newArr;
    },
    setAllPeoples: (state, action) => {
      state.allPeoples.data = action.payload.data;
      state.allPeoples.total = action.payload.total;
      state.allPeoples.all = action.payload.all;
    },
    resetAllPeoples: (state) => {
      state.allPeoples.data = [];
      state.allPeoples.total = 0;
      state.allPeoples.all = 0;
    },

    setNewPeoples: (state, action) => {
      state.totalNewUser = action.payload.totalUser;
      action.payload === null
        ? (state.newPeoples = null)
        : (state.newPeoples = {
            ...state.newPeoples,
            [`page${[action.payload.page]}`]: action.payload.data,
          });
    },
    setSearchPeopleInputs: (state, action) => {
      state.searchPeopleInputs[action.payload.name] = action.payload.data;
    },
    setAllPeoplesFilterInputs: (state, action) => {
      if (action.payload.arr) {
        action.payload.arr.forEach((item) => {
          state.allPeoplesFilterInputs[item.name] = item.value;
        });
      } else {
        state.allPeoplesFilterInputs[action.payload.name] = action.payload.value;
      }
    },
    resetAdminSlice: () => {
      return { ...initialState };
    },
    removePeopleData: (state, action) => {
      state.allPeoples = removeItemFromWholeList({
        ...action.payload,
        oldData: state.allPeoples,
      });
      state.totalUsersInDB -= 1;
    },
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    setNfts: (state, action) => {
      state.nfts = action.payload.result;
      state.nftCount = action.payload.totalCount;
    },
    setAdminGifting: (state, action) => {
      state.gifting = action.payload?.result;
      state.giftingCount = action.payload?.totalCount;
    },
    setSkipGiftingCount: (state, action) => {
      state.skipGiftingCount = action.payload.skipCount;
    },
    setTemplates: (state, action) => {
      if (action.payload.data && action.payload.data[0])
        state.emailTemplates = action.payload.data[0]?.data;
      state.emailCount = action.payload.data[0]?.metadata[0]?.total;
    },
  },
});

export const {
  setCategoryReports,
  setAllPeoples,
  setTimeIntervel,
  resetAllPeoples,
  setTimeIntervelLowerLevel,
  setAverageView,
  setMinimumView,
  setMonetizationLimits,
  updateMonetizationLimit,
  setNewPeoples,
  setSearchPeopleInputs,
  setCustomizePlatforms,
  changeCustomizePlatforms,
  setAllPeoplesFilterInputs,
  resetAdminSlice,
  removePeopleData,
  setUserLevel,
  changeUserLevel,
  changeDefaultLandingPages,
  setDefaultLandingPages,
  setMaintenanceInputs,
  setReferralCodes,
  setCategories,
  setNfts,
  setCampaigns,
  setSkipCampaignCount,
  setCampaignFilterData,
  setCampaignDetails,
  setCreatorVideos,
  setSkipCreatorVideosCount,
  setCreatorVideosFilterData,
  setPaymentFilterData,
  setSkipPaymentCount,
  setAdminGifting,
  setSkipGiftingCount,
  setMessageReport,
  setSkipMessageReportCount,
  setTransactionExplore,
  setSkipTransactionExploreCount,
  setTransactionExploreFilterData,
  setFeeInformation,
  setSkipFeeInformationCount,
  setFeeInformationFilterData,
  setMonthlyFeeInformation,
  setBalance,
  setBalanceFilterData,
  setSkipBalanceCount,
  setTemplates,
} = adminSlice.actions;

export default adminSlice.reducer;

export const getAllPeoples = ({ admin }) => admin.admin.allPeoples;
export const getCategoryReports = ({ admin }) => admin.admin.categoryReports;
export const getMonetizationLimits = ({ admin }) => admin.admin.monetizationLimits;
export const getUserLevel = ({ admin }) => admin.admin.userLevel;
export const getCustomizePlatforms = ({ admin }) => admin.admin.customizePlatforms;
export const getSearchPeopleInputs = ({ admin }) => admin.admin.searchPeopleInputs;
export const getNewPeoples = ({ admin }) => admin.admin.newPeoples;
export const getTotalNewPeoples = ({ admin }) => admin.admin.totalNewUser;
export const getDefaultLandingPages = ({ admin }) => admin.admin.defaultLandingPages;
export const getAllPeoplesFilterInputs = ({ admin }) => admin.admin.allPeoplesFilterInputs;
export const getMaintenanceInputs = ({ admin }) => admin.admin.maintenanceInputs;
export const getReferralCodes = ({ admin }) => admin.admin.referralCodes;
export const getCategoryLists = ({ admin }) => admin.admin.categories;
export const getTimeInterval = ({ admin }) => admin.admin.timeIntervel;
export const getTimeIntervalLowerLevel = ({ admin }) => admin.admin.timeIntervelLowerLevel;
export const getAverageView = ({ admin }) => admin.admin.averageView;
export const getMinimumView = ({ admin }) => admin.admin.minimumView;
export const getNftLists = ({ admin }) => admin.admin.nfts;
export const getNftCount = ({ admin }) => admin.admin.nftCount;
export const getSkipCount = ({ admin }) => admin.admin.skipCount;
export const getCampaignFilterData = ({ admin }) => admin.admin.campaignFilterData;
export const getSkipCampaignCount = ({ admin }) => admin.admin.skipCampaignCount;
export const getCampaignsList = ({ admin }) => admin.admin.campaign;
export const getCampaignCount = ({ admin }) => admin.admin.campaignCount;
export const getCampaignDetails = ({ admin }) => admin.admin.campaignDetails;
export const getCreatorVideosFilterData = ({ admin }) => admin.admin.creatorVideosFilterData;
export const getSkipCreatorVideosCount = ({ admin }) => admin.admin.skipCreatorVideosCount;
export const getCreatorVideosList = ({ admin }) => admin.admin.creatorVideos;
export const getCreatorVideosCount = ({ admin }) => admin.admin.creatorVideosCount;
export const getPaymentFilterData = ({ admin }) => admin.admin.paymentFilterData;
export const getSkipPaymentCount = ({ admin }) => admin.admin.skipPaymentCount;
export const getGifting = ({ admin }) => admin.admin.gifting;
export const getGiftingCount = ({ admin }) => admin.admin.giftingCount;
export const getSkipGiftingCount = ({ admin }) => admin.admin.skipGiftingCount;
export const getMessageReport = ({ admin }) => admin.admin.messageReport;
export const getMessageReportCount = ({ admin }) => admin.admin.messageReportCount;
export const getSkipMessageReportCount = ({ admin }) => admin.admin.skipMessageReportCount;
export const getTransactionExploreFilterData = ({ admin }) =>
  admin.admin.transactionExploreFilterData;
export const getSkipTransactionExploreCount = ({ admin }) =>
  admin.admin.skipTransactionExploreCount;
export const getTransactionExploreList = ({ admin }) => admin.admin.transactionExplore;
export const getTransactionExploreCount = ({ admin }) => admin.admin.transactionExploreCount;
export const getFeeInformationFilterData = ({ admin }) => admin.admin.feeInformationFilterData;
export const getSkipFeeInformationCount = ({ admin }) => admin.admin.skipFeeInformationCount;
export const getFeeInformationList = ({ admin }) => admin.admin.feeInformation;
export const getFeeInformationCount = ({ admin }) => admin.admin.feeInformationCount;
export const getTotalFee = ({ admin }) => admin.admin.totalFee;
export const getMonthlyFee = ({ admin }) => admin.admin.monthlyFee;
export const getFeeGraph = ({ admin }) => admin.admin.feeGraph;
export const getBalanceFilterData = ({ admin }) => admin.admin.balanceFilterData;
export const getSkipBalanceCount = ({ admin }) => admin.admin.skipBalanceCount;
export const getBalanceList = ({ admin }) => admin.admin.balance;
export const getBalanceCount = ({ admin }) => admin.admin.balanceCount;
export const getBalanceTotal = ({ admin }) => admin.admin.balanceTotal;
export const getTemplateLists = ({ admin }) => admin.admin.emailTemplates;
export const getTemplateCount = ({ admin }) => admin.admin.emailCount;
