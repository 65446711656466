import { createSlice } from "@reduxjs/toolkit";

import { setLocalStorageItems } from "common/helpers/localStorage";

import { STORAGES } from "constants/appConstants";
import { RootState } from "store/store";
import { IQrDetails } from "types/auth-types";
import { IUser } from "types/user";
import { UserRole } from "types/user-role";

interface IInitialStateAuth {
  isLoggedIn: boolean;
  accountType: string;
  token: string;
  registrationFormFields: {
    [x: string]: any;
    accountType: UserRole;
    verifyCaptcha: boolean;
  };
  userId: string;
  timer: boolean;
  registrationSubmitDetails: null;
  registrationStatus: {
    type: string;
    status: boolean | null;
    maintenance: boolean | null;
    message: string;
    createdAt: string;
  };
  loginDetailsVerified: boolean;
  authenticationBarCodeSecret: string;
  verifiedLoginDetails: IUser | null;
  qrDetails: IQrDetails | null;
  forgotPwdUserType: UserRole | null;
}

const initialState: IInitialStateAuth = {
  isLoggedIn: false,
  accountType: "",
  token: "",
  registrationFormFields: {
    accountType: "creator",
    verifyCaptcha: false,
  },
  userId: "",
  timer: false,
  registrationSubmitDetails: null,
  registrationStatus: {
    type: "",
    status: null,
    maintenance: null,
    message: "",
    createdAt: "",
  },
  loginDetailsVerified: false,
  authenticationBarCodeSecret: "",
  verifiedLoginDetails: null,
  qrDetails: null,
  forgotPwdUserType: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setQrDetails: (state, action) => {
      state.qrDetails = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setVerifiedLoginDetails: (state, action) => {
      state.verifiedLoginDetails = action.payload;
    },
    setAuthenticationBarCodeSecret: (state, action) => {
      state.authenticationBarCodeSecret = action.payload;
    },
    setLoginDetailsVerified: (state, action) => {
      state.loginDetailsVerified = action.payload;
    },
    setRegistrationStatus: (state, action) => {
      state.registrationStatus = action.payload;
    },
    startTimer: (state) => {
      state.timer = true;
    },
    stopTimer: (state) => {
      state.timer = false;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setforgotPwdUserType: (state, action) => {
      state.forgotPwdUserType = action.payload;
    },
    setRegistrationFormFields: (state, action) => {
      state.registrationFormFields[action.payload.name] = action.payload.value;
    },
    setRegistrationSubmitDetails: (state, action) => {
      state.registrationSubmitDetails = action.payload;
    },
    clearRegistrationFields: (state) => {
      for (const prop in state.registrationFormFields) {
        state.registrationFormFields[prop] = "";
      }
    },
    login: (state, action) => {
      state.isLoggedIn = true;
      state.accountType = action.payload.accountType;
      state.token = action.payload.token;
      setLocalStorageItems([
        { key: STORAGES.isLoggedIn, value: true },
        {
          key: STORAGES.token,
          value: action.payload.token,
        },
        {
          key: STORAGES.accountType,
          value: action.payload.accountType,
        },
      ]);
    },
    logout: (state) => {
      state.isLoggedIn = false;
      localStorage.clear();
      state.token = "";
      state.accountType = "";
    },
    resetAuthSlice: (state) => {
      state = initialState;
    },
  },
});

export const {
  setRegistrationFormFields,
  setQrDetails,
  startTimer,
  stopTimer,
  login,
  setRegistrationStatus,
  logout,
  setLoginDetailsVerified,
  setRegistrationSubmitDetails,
  clearRegistrationFields,
  resetAuthSlice,
  setUserId,
  setAuthenticationBarCodeSecret,
  setVerifiedLoginDetails,
  setToken,
  setforgotPwdUserType,
} = authSlice.actions;

export default authSlice.reducer;

export const getRole = ({ auth }: RootState) => auth.accountType;
export const getQrDetails = ({ auth }: RootState) => auth.qrDetails;
export const getRegistrationFields = ({ auth }: RootState) => auth.registrationFormFields;
export const getUserId = ({ auth }: RootState) => auth.userId;
export const getLoggedInStatus = ({ auth }: RootState) => auth.isLoggedIn;
export const getTimer = ({ auth }: RootState) => auth.timer;
export const getRegistrationStatus = ({ auth }: RootState) => auth.registrationStatus;
export const getLoginDetailsVerified = ({ auth }: RootState) => auth.loginDetailsVerified;
export const getAuthenticationBarCodeSecret = ({ auth }: RootState) =>
  auth.authenticationBarCodeSecret;
export const getVerifiedLoginDetails = ({ auth }: RootState) => auth.verifiedLoginDetails;
export const getToken = ({ auth }: RootState) => auth.token;
export const getForgotPwdUserType = ({ auth }: RootState) => auth.forgotPwdUserType;
