import { useMemo, useState, MouseEvent, useEffect, FC, SetStateAction, Dispatch } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

import LanguageSelect from "./components/LanguageSelect";
import { HeaderMenuContainer, MenuItem } from "./style";

import ArrowDownSmall from "assets/svg/ArrowDownSmall";
import Message from "assets/svg/header/Message";
import Profile from "assets/svg/header/Profile";
import Menu from "assets/svg/Menu";
import Moon from "assets/svg/Moon";
import { MenuStyled } from "Components/Header/style";
import Switch from "Components/Switch";
import { ROLES } from "constants/appConstants";
import FilterOverlay from "pages/creator/Bio/Analytics/components/AnalyticsFilter/components/FilterOverlay";
import { updateProfileInfoAPI } from "services/UserServices";
import { getUnreadMessages } from "store/slices/global/chatsSlice";
import { getUser } from "store/slices/global/userSlice";

interface Props {
  menu: boolean;
  setMenu: Dispatch<SetStateAction<boolean>>;
}

const HeaderMenu: FC<Props> = ({ menu, setMenu }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector(getUser);
  const unread = useSelector(getUnreadMessages);
  const [theme, setTheme] = useState<"dark" | "light">("light");

  useEffect(() => {
    if (!user) return;
    setTheme(user.generalTheme);
  }, [user]);

  const handleChange = (theme: "dark" | "light") => {
    setTheme(theme);

    updateProfileInfoAPI({
      data: {
        generalTheme: theme,
      },
      part: "general",
    });
  };

  const options = useMemo(() => {
    return user
      ? [
          {
            onClick: (e: MouseEvent) => {
              e.stopPropagation();
              setMenu(false);
              navigate(`/${user.role}/messages`);
            },
            icon: <Message />,
            text: t("common.messages"),
            action: (
              <div className="arrow">
                <ArrowDownSmall />
              </div>
            ),
            count: unread,
            className: user.role === ROLES.fan ? "hide" : "",
          },
          {
            onClick: (e: MouseEvent) => {
              e.stopPropagation();
              setMenu(false);
              navigate(`/${user.role}/editProfile/general`);
            },
            icon: <Profile />,
            text: t("common.profile"),
            action: (
              <div className="arrow">
                <ArrowDownSmall />
              </div>
            ),
          },
          {
            onClick: (e: MouseEvent) => {
              e.stopPropagation();
              handleChange(theme === "dark" ? "light" : "dark");
            },
            icon: <Moon />,
            text: t("common.darkMode"),
            action: <Switch value={theme === "dark"} onChange={() => {}} />,
          },
        ]
      : [];
  }, [user, t, theme, unread]);

  return (
    <MenuStyled active={menu} onClick={() => setMenu(!menu)}>
      <div className="icon">
        <Menu />
      </div>
      <HeaderMenuContainer show={menu}>
        {options.map((item, index) => (
          <MenuItem key={index} onClick={(e) => item.onClick(e)} className={item.className}>
            <div className="itemContent">
              <div className="icon">
                {item.icon}
                {!!item.count && <div className="counter">{item.count}</div>}
              </div>
              <p>{item.text}</p>
            </div>
            <div className="action">{item.action}</div>
          </MenuItem>
        ))}
        <LanguageSelect onSelect={() => setMenu(false)} />
      </HeaderMenuContainer>
      <FilterOverlay show={menu} onClick={() => setMenu(false)} />
    </MenuStyled>
  );
};

export default HeaderMenu;
