import { memo, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { MovingCard, NewPasswordBox, ResendMsg, SendEmailCard } from "./style";

import ArrowLeft from "assets/svg/ArrowLeft";
import { validateEmail } from "common/helpers/helper";
import Button from "Components/Button";
import Timer from "Components/CustomTimer";
import Input from "Components/Input";
import MiniLoader from "Components/MiniLoader";
import Password from "Components/Password";
import SocialIcons from "Components/SocialIcons";
import { REACT_APP_GOOGLE_RECAPTCHA_KEY } from "configuration/config";
import { STRINGS } from "constants/appConstants";
import { PASSWORD_REGEX } from "constants/regex";
import titles from "constants/titles";
import useTitle from "customHooks/useTitle";
import { ROUTES } from "routes/routesData";
import { resetPasswordAPI, sendVerificationEmailAPI } from "services/axios";

import { getForgotPwdUserType, getTimer } from "store/slices/authSlice";

import { getPasswordLoader } from "store/slices/featureSlice";
import { AuthActions, AuthContainer, AuthWrapper } from "styles/auth";

const ForgotPassword = memo(() => {
  useTitle(titles.forgotPassword);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const time = useSelector(getTimer);
  const forgotUserType = useSelector(getForgotPwdUserType);

  const [captchaVerified, setCaptchaVerified] = useState(null);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const loading = useSelector(getPasswordLoader);
  const [step, setStep] = useState(0);
  const [err, setErr] = useState({ email: "" });
  const [pwdError, setPwdError] = useState(null);
  const [formValues, setFormValues] = useState({
    email: "",
    verifyCode: "",
    newPassword: "",
  });
  const handleFormValues = ({ value, name }) => {
    setErr({ email: "" });
    setFormValues((oldVal) => ({ ...oldVal, [name]: value }));
  };

  const sendEmail = () => {
    if (!validateEmail({ email: formValues.email })) {
      setErr({ email: t("signup.invalid_email") });
      return;
    }
    setErr({ email: "" });

    sendVerificationEmailAPI({ data: { email: formValues.email } }, () => {
      setStep(1);
    });
  };

  const resetPassword = () => {
    resetPasswordAPI(
      {
        data: {
          email: formValues.email,
          password: formValues.newPassword,
          otp: formValues.verifyCode,
        },
      },
      () => {
        forgotUserType && forgotUserType === "admin"
          ? navigate(ROUTES.adminLogin, { state: { passwordModal: true } })
          : navigate(ROUTES.login, { state: { passwordModal: true } });
        handleFormValues({
          name: "verifyCode",
          value: "",
        });
      }
    );
  };

  const handleReCaptchaChange = async (value) => setCaptchaVerified(value);

  return (
    <AuthWrapper>
      <div className="content">
        <AuthContainer>
          <div className="left">
            <MovingCard>
              <SendEmailCard
                style={{
                  transform: step === 1 ? "translateX(-100%)" : "translateX(0)",
                }}
              >
                <p className="title">{t("common.forgotPass")}</p>
                <p className="text">{t("common.whatAccount")}</p>
                <Input
                  label={t("signup.email")}
                  type="email"
                  required
                  placeholder="creator@example.com"
                  handleChange={(e) => handleFormValues({ name: "email", value: e.target.value })}
                  value={formValues.email}
                  error={err.email}
                  maxLength={50}
                />
                <ReCAPTCHA
                  sitekey={REACT_APP_GOOGLE_RECAPTCHA_KEY}
                  onChange={handleReCaptchaChange}
                />
                <div className="resetActions">
                  <Button
                    className="back"
                    variant="secondary"
                    onClick={() => (step === 0 ? navigate(-1) : setStep(0))}
                  >
                    <ArrowLeft />
                  </Button>
                  <Button
                    full
                    variant="primary"
                    onClick={sendEmail}
                    disabled={loading || formValues.email === "" || !captchaVerified}
                  >
                    {loading ? <MiniLoader /> : t("auth.send_verification_email")}
                  </Button>
                </div>
              </SendEmailCard>
              <NewPasswordBox
                style={{
                  transform: step === 1 ? "translateX(-100%)" : "translateX(0)",
                }}
              >
                <p className="title">{t("auth.updatePass")}</p>
                <p className="text">{t("auth.verification")}</p>
                <Input
                  label={t("auth.verification_code")}
                  type="text"
                  required
                  placeholder="123456"
                  handleChange={(e) => {
                    const digitRegex = /^(|\d)+$/;
                    if (digitRegex.test(e.target.value)) {
                      handleFormValues({
                        name: "verifyCode",
                        value: e.target.value,
                      });
                    }
                  }}
                  maxLength={10}
                  noNeedError
                  value={formValues.verifyCode}
                />
                <div className="timer">
                  <ResendMsg
                    disabled={loading || time}
                    onClick={loading || time ? null : () => sendEmail()}
                  >
                    {t("auth.resend_otp")}
                  </ResendMsg>
                  <Timer disabled={loading || time} />
                </div>
                <Password
                  label={t("common.user_profile.new_pass")}
                  show={passwordVisible}
                  showPassword={() => setPasswordVisible((prevState) => !prevState)}
                  type={passwordVisible ? "text" : STRINGS.password}
                  required
                  placeholder={t("auth.newPassword")}
                  maxLength={50}
                  autoComplete="new-password"
                  handleChange={(e) => {
                    if (PASSWORD_REGEX.test(e.target.value)) {
                      setPwdError(null);
                    } else {
                      setPwdError(t("auth.min_8_characters"));
                    }
                    handleFormValues({
                      name: "newPassword",
                      value: e.target.value,
                    });
                  }}
                  value={formValues.newPassword}
                />
                <div className="resetActions">
                  <Button
                    className="back"
                    variant="secondary"
                    onClick={() => (step === 0 ? navigate(-1) : setStep(0))}
                  >
                    <ArrowLeft />
                  </Button>
                  <Button
                    full
                    variant="primary"
                    onClick={resetPassword}
                    disabled={
                      loading ||
                      pwdError ||
                      formValues?.verifyCode.length < 6 ||
                      formValues.newPassword < 8
                    }
                  >
                    {loading ? <MiniLoader /> : t("creator.campaign.submit")}
                  </Button>
                </div>
              </NewPasswordBox>
            </MovingCard>
            <div className="lines">
              <div className={`line ${step >= 0 ? "active" : ""}`} />
              <div className={`line ${step >= 1 ? "active" : ""}`} />
            </div>
          </div>
          <div className="right">
            <img src="/password.png" alt="login" loading="lazy" />
          </div>
        </AuthContainer>
        <AuthActions>
          {/* <div className="buttons" /> */}
          <div className="socials">
            <SocialIcons />
          </div>
          <div className="step">
            {step <= 1 && (
              <p>
                {t("common.step")} <span>{step + 1}</span> {t("common.of")} 2
              </p>
            )}
          </div>
        </AuthActions>
      </div>
    </AuthWrapper>
  );
});

export default ForgotPassword;
