import { memo, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";

import EditBlock from "./components/EditBlock";
import { ChangeAvatarContainer } from "./style";

import Avatar from "Components/Avatar";
import { onFanImageRemove, updateProfileInfoAPI, updateProfilePic } from "services/UserServices";
import {
  getUser,
  removeCardBackgroundUrl,
  removeProfilePicture,
} from "store/slices/global/userSlice";
import { BlockCard } from "styles/mixins";

const ChangeAvatar = memo(() => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [imageFile, setImageFile] = useState<any>();
  const user = useSelector(getUser);
  if (!user) return null;

  const { username, aboutMe, displayname, banner, pictureUrl, role, generalTheme } = user;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const mockup = useMemo(() => {
    switch (generalTheme) {
      case "dark":
        return "/MockupSettingDark.svg";
      case "light":
        return "/MockupSettingLight.svg";
      default:
        return "/MockupSettingLight.svg";
    }
  }, [generalTheme]);

  const handleSubmitAvatar = (image: File) => {
    setImageFile(image);
    const mediaData = new FormData();
    mediaData.append("image", image);

    updateProfilePic({ apiData: mediaData }, (res: any) => {
      updateProfileInfoAPI({
        data: {
          pictureUrl: res.key,
        },
        part: "general",
      });
    });
  };

  const handleSubmitBackground = (image: File) => {
    const mediaData = new FormData();
    mediaData.append("image", image);

    updateProfilePic({ apiData: mediaData }, (res: any) => {
      updateProfileInfoAPI({
        data: {
          banner: { image: res.key },
        },
        part: "customizations",
      });
    });
  };

  const handleDeleteAvatar = () => {
    onFanImageRemove();
    dispatch(removeProfilePicture());
    setImageFile(null);
  };

  const handleDeleteBackground = () => {
    updateProfileInfoAPI({
      data: {
        banner: null,
      },
      part: "customizations",
    });
    dispatch(removeCardBackgroundUrl());
  };

  return (
    <ChangeAvatarContainer>
      <BlockCard className="card">
        <div className="preview">
          <p className="title">{t("common.editAppearance")}</p>
          <div className="infoContainer">
            <EditBlock
              aspectHeight={1}
              aspectWidth={1}
              pictureUrl={pictureUrl}
              downScaleToWidth={200}
              title={t("common.customAvatar")}
              description1={t("common.avatarDesc1")}
              description2={t("common.avatarDesc2")}
              handleSubmit={handleSubmitAvatar}
              handleDelete={handleDeleteAvatar}
            />
            <EditBlock
              minImageWidth={1200}
              maxImageWidth={2048}
              aspectHeight={1}
              aspectWidth={6}
              pictureUrl={banner?.imageUrl_1200_0}
              title={t("common.bioPageBanner")}
              description1={t("common.backDesc1")}
              handleSubmit={handleSubmitBackground}
              handleDelete={handleDeleteBackground}
            />
          </div>
        </div>
        <div className="image">
          <p className="title">{t("common.editAppearance")}</p>
          <div className="mockup">
            <img src={mockup} alt="mockup" />
            <div className="avatarContainer">
              <Avatar src={pictureUrl} alt="avatar" border />
            </div>
            {banner?.imageUrl_1200_0 && (
              <div className="banner">
                <img src={banner?.imageUrl_1200_0} alt="banner" />
              </div>
            )}
            <div className="userInfo">
              <p>{displayname || username}</p>
              <p className="active">{t("common.today")}</p>
              {aboutMe && <p>{aboutMe}</p>}
            </div>
          </div>
        </div>
      </BlockCard>
    </ChangeAvatarContainer>
  );
});

export default ChangeAvatar;
