/* eslint-disable no-extra-boolean-cast */
import { useFormikContext } from "formik";
import { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { AddContentModalForm } from "..";

import CirclePlay from "assets/svg/CirclePlay";
import Close from "assets/svg/Close";
import { ImageOutlined } from "assets/svg/ImageOutLined";
import Sound from "assets/svg/Sound";
import { isSupportedExtension } from "common/helpers/file-helpers";
import Button from "Components/Button";
import { messageSupportedImageExtensions } from "constants/file-extensions";
import { ContentMessagePreviewStyles } from "pages/public/chat/Components/Chat/AddContentModal/ContentMessagePreview/styles";
import { EmptyContentInput } from "pages/public/chat/Components/Chat/AddContentModal/EmptyContentInput";
import { ContentInfoItem } from "pages/public/chat/Components/Chat/ContentInfoitem";

interface Props {
  sumOfVideosSize: string;
  sumOfAudiosSize: string;
  sumOfImagesSize: string;
  imageFilesCount: number;
  videoFilesCount: number;
  audioFilesCount: number;
  onPreviewChange: (file: File) => void;
  onPreviewRemove: () => void;
  file: File | undefined;
}
export const ContentMessagePreview = memo(
  ({
    sumOfVideosSize,
    sumOfAudiosSize,
    imageFilesCount,
    videoFilesCount,
    audioFilesCount,
    onPreviewChange,
    file,
    onPreviewRemove,
  }: Props) => {
    const { t } = useTranslation();
    const [imagePreview, setImagePreview] = useState<string | undefined>(undefined);
    const { values, errors } = useFormikContext<AddContentModalForm>();

    const transformFileToImagePreview = useCallback((file: File) => {
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }, []);

    useEffect(() => {
      if (!file) return;
      if (!isSupportedExtension(file.name, messageSupportedImageExtensions)) return;
      transformFileToImagePreview(file);
    }, [file]);

    return (
      <ContentMessagePreviewStyles>
        <div className="preview">
          {file && !errors.contentPreview ? (
            // eslint-disable-next-line jsx-a11y/label-has-associated-control
            <label className="thumbnail" style={{ backgroundImage: `url(${imagePreview})` }}>
              <div
                className="remove_content"
                onClick={(ev) => {
                  ev.stopPropagation();
                  ev.preventDefault();
                  onPreviewRemove();
                }}
              >
                <Close size={24} />
              </div>
              <input
                type="file"
                accept={messageSupportedImageExtensions.join(",")}
                onChange={(ev) => {
                  const file = ev.target.files![0] as File;
                  onPreviewChange(file);
                }}
              />
            </label>
          ) : (
            <EmptyContentInput
              className={errors.contentPreview ? "error" : ""}
              hasError={errors.contentPreview}
              buttonText={t("new_chat.add_content_modal.add_preview")}
              onFileSelected={(file) => onPreviewChange(file)}
              acceptExtensions={messageSupportedImageExtensions.join(",")}
            />
          )}
        </div>
        <div className="content_info">
          <div className="content_items">
            <ContentInfoItem
              icon={<ImageOutlined className="content_info_icon" />}
              contentCount={imageFilesCount}
            />
            <ContentInfoItem
              icon={<CirclePlay className="content_info_icon" />}
              contentInfo={sumOfVideosSize}
              contentCount={videoFilesCount}
            />
            <ContentInfoItem
              icon={<Sound className="content_info_icon" />}
              contentInfo={sumOfAudiosSize}
              contentCount={audioFilesCount}
            />
          </div>
          <Button variant="primary" className="action_button">
            {values.price && Number(values.price) !== 0 ? (
              <>
                {t("new_chat.unlockFor")}
                {` ${values.price}${values.currency ? ` ${values.currency?.label}` : ""}`}
              </>
            ) : (
              <>{t("common.free")}</>
            )}
          </Button>
        </div>
      </ContentMessagePreviewStyles>
    );
  }
);
