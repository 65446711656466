import styled from "styled-components";

import Modal from "Components/Modal/Modal";
import { deviceQuery } from "styles/mediaSizes";

export const ChooseRoleModalContainer = styled(Modal)`
  .modal-content {
    max-width: 460px;
    padding: 20px;
    border-radius: 20px;
    background: ${(p) => p.theme.colors.background.secondary};

    .modal-body {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 0;

      .close-button {
        background-color: unset;
        right: 0;
        top: 0;
        svg {
          transform: scale(1.5);
        }
      }

      .title {
        font-family: ${(p) => p.theme.ff.outfit.regular};
        text-align: start;
        font-size: ${(p) => p.theme.typography.text3};

        padding: 0 20px 20px;
        margin: 0 -20px;
        border-bottom: 1px solid ${(p) => p.theme.colors.divider};

        .description {
          margin-top: 4px;
          font-size: ${(p) => p.theme.typography.subText5};
          color: ${(p) => p.theme.colors.text.secondary};
        }
      }
    }
  }

  ${deviceQuery.mobileM} {
    .modal-dialog {
      padding: 16px;
    }

    .modal-body {
      .modal-content {
        padding: 12px;

        .title {
          font-family: ${(p) => p.theme.ff.outfit.regular};
          text-align: start;
          font-size: ${(p) => p.theme.typography.text3};

          padding: 0 12px 12px;
          margin: 0 -12px;
        }
      }
    }
  }
`;

export const Content = styled.div`
  .radio_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }

  .aboutCard {
    display: flex;
    flex-direction: column;
    gap: 16px;

    br {
      display: none;
    }

    .titleCard {
      display: flex;
      align-items: center;
      gap: 12px;

      border-bottom: 1px solid ${(p) => p.theme.colors.divider};
      padding-bottom: 16px;
      text-align: center;
      font-size: ${(p) => p.theme.typography.subText5};

      .titleRole {
        color: ${(p) => p.theme.colors.green.primary};
      }
    }

    .desc {
      display: flex;
      flex-direction: column;
      gap: 16px;
      font-size: ${(p) => p.theme.typography.subText5};

      .item {
        display: flex;
        gap: 10px;
      }
    }
  }

  .read_more_container {
    display: flex;
    margin-left: auto;
    width: max-content;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 16px;
    text-decoration: none;
    color: ${(p) => p.theme.colors.text.third};
    font-size: ${(p) => p.theme.typography.subText5};
  }

  .buttons_container {
    display: flex;
    gap: 16px;
    padding: 16px 20px 0;
    margin: 20px -20px 0;
    border-top: 1px solid ${(p) => p.theme.colors.divider};
  }

  ${deviceQuery.mobileM} {
    .radio_container {
      grid-template-columns: unset;
      grid-template-rows: 1fr 1fr;
    }

    .buttons_container {
      padding: 12px 12px 0;
      margin: 12px -12px 0;
    }
  }
`;
