import { GoogleOAuthProvider } from "@react-oauth/google";
import * as Sentry from "@sentry/browser";

import React, { memo, useEffect, useMemo } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import { Provider, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import App from "./App";
import ScrollToTop from "./common/scrollToTop";
import ViewContent from "./Components/ViewContent";
import "./i18n";
import reportWebVitals from "./reportWebVitals";
import store from "./store/store";
import GlobalStyle from "./styles/globalStyle";

import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-loading-skeleton/dist/skeleton.css";
import { changeLanguageByShort } from "common/helpers/language";
import { REACT_APP_DSN, REACT_APP_GOOGLE_CLIENT_ID } from "configuration/config";
import { getUser } from "store/slices/global/userSlice";
import { darkTheme, lightTheme } from "styles/newThemes";

Sentry.init({ dsn: REACT_APP_DSN });

const Root = () => {
  const { ready } = useTranslation();
  const user = useSelector(getUser);
  const theme = useMemo(() => {
    if (!user) return lightTheme;
    switch (user.generalTheme) {
      case "light":
        return lightTheme;

      case "dark":
        return darkTheme;

      default:
        return lightTheme;
    }
  }, [user]);

  const userLanguage = navigator.language || navigator.userLanguage;

  useEffect(() => {
    changeLanguageByShort(userLanguage.slice(0, 2));
  }, []);

  return ready ? (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <App />
      <ViewContent />
    </ThemeProvider>
  ) : (
    <></>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <Provider store={store}>
        <GoogleOAuthProvider clientId={REACT_APP_GOOGLE_CLIENT_ID}>
          <Root />
        </GoogleOAuthProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
