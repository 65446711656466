import { Link } from "react-router-dom";
import styled from "styled-components";

import { deviceQuery } from "styles/mediaSizes";
import { OutfitSemibold, GreenDot, OutfitLight } from "styles/mixins";

export const HeaderMark = styled.h2`
  ${OutfitSemibold};
  color: ${(p) => p.theme.colors.text.primary};
  background-color: ${(p) => p.theme.colors.green.primary};
  width: fit-content;
  border-radius: 5px;
  font-size: 1.3rem;
  padding: 5px 20px;
  margin-bottom: 1rem;
  text-transform: uppercase;

  ${deviceQuery.laptopS} {
    margin: 0 auto 1rem;
  }
`;

export const Image = styled.img`
  width: ${(p) => (p.hero && "75%") || (p.logo && "80px")};
  object-fit: contain;
  margin: 0 auto;
`;

export const SubHeading = styled.h4`
  font-size: 0.9rem;
  color: ${(p) => p.theme.colors.icon.secondary};
  ${OutfitLight};
  display: flex;
  margin-top: 5px;

  p {
    cursor: pointer;
  }

  p:hover {
    color: ${(p) => p.theme.colors.text.third};
  }
`;

export const Heading = styled.h2`
  font-size: 3rem;
  text-transform: capitalize;
  color: ${(p) => p.theme.colors.text.primary};
  position: relative;
  width: fit-content;
  font-family: my;
  margin: 0 auto 0 0;
  font-family: ${(p) => p.theme.ff.outfit.bold};
  letter-spacing: -1px;

  &::before {
    ${GreenDot};
    right: -10px;
    bottom: 15px;
  }

  ${deviceQuery.laptopS} {
    margin: 0 auto;
  }
`;

export const Para = styled.p`
  margin: 2rem 0;
  color: ${(p) => p.theme.colors.text.primary};
  font-family: ${(p) => p.theme.ff.outfit.light};

  .green {
    color: ${(p) => p.theme.colors.green.primary};
    font-weight: bold;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  ${deviceQuery.laptopS} {
    margin: 1rem 0;
  }
`;

export const ForgotPass = styled(Link)`
  color: ${(p) => p.theme.colors.icon.secondary};
  display: block;
  text-align: left;
  font-family: ${(p) => p.theme.ff.outfit.regular};

  &:hover {
    color: ${(p) => p.theme.colors.green.primary};
  }
`;

export const Form = styled.form`
  max-width: 400px;
`;
