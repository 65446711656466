import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getChatMessagesApi,
  getChatsApi,
  getChatStatusesCounter,
  getChatsWithSearch,
} from "services/newMessagesService";
import { GetChatMessagesResp, GetChatsResp } from "types/api-response-types";
import {
  GetNewChatsRequestData,
  GetNewMessagesRequestData,
  GetNewMessagesSearchRequestData,
  NewChatItem,
  SearchChatItem,
  TotalChatCount,
} from "types/new-messages.types";

export const getChatMessagesByChatId = createAsyncThunk(
  "chat/getChatMessages",
  async (requestData: GetNewMessagesRequestData) => {
    const resp = await new Promise<GetChatMessagesResp>((res, rej) => {
      getChatMessagesApi(
        requestData,
        (data) => res(data),
        (err) => rej(err)
      );
    });

    return { data: resp.data, requestData };
  }
);

export const getChatsByStatusAndRole = createAsyncThunk(
  "chat/getChats",
  async (requestData: GetNewChatsRequestData) => {
    const resp = await new Promise<GetChatsResp>((res, rej) => {
      getChatsApi(
        requestData,
        (data) => res(data),
        (err) => rej(err)
      );
    });

    return { data: resp.data };
  }
);

export const getChatsCountByStatus = createAsyncThunk("chat/getChatsStatusesCounter", async () => {
  const resp = await new Promise<TotalChatCount>((res, rej) => {
    getChatStatusesCounter(
      (data) => res(data),
      () => rej()
    );
  });

  return resp;
});

export const getChatsWithSearchThunk = createAsyncThunk(
  "chat/getChatsWithSearch",
  async (requestData: GetNewMessagesSearchRequestData) => {
    const resp = await new Promise<SearchChatItem[]>((res, rej) => {
      getChatsWithSearch(
        requestData,
        (data) => res(data),
        () => rej()
      );
    });

    return { data: transformSearchedChatsToPlain(resp, requestData.search), requestData };
  }
);

const transformSearchedChatsToPlain = (
  searchedChats: SearchChatItem[],
  lastUnreadMessageText: string
): NewChatItem[] => {
  return searchedChats.map((searchedChat) => {
    return {
      _id: searchedChat._id,
      anotherUser: searchedChat.anotherUser,
      status: searchedChat.status,
      createdAt: searchedChat.createdAt,
      firstUnreadMessage: {
        _id: searchedChat.searchMessages[searchedChat.searchMessages.length - 1]?._id || "",
      },
      lastMessage: {
        messageType: "text",
        _id: searchedChat.searchMessages[searchedChat.searchMessages.length - 1]?._id || "",
        createdAt: searchedChat.createdAt,
        text: lastUnreadMessageText,
        user: searchedChat.anotherUser,
      },
      unreadMark: false,
      updatedAt: searchedChat.updatedAt,
      searchedMessagesId: searchedChat.searchMessages.reverse().map((message) => message._id),
    };
  });
};
