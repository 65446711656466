import styled from "styled-components";

import { deviceQuery } from "styles/mediaSizes";

import { flexCenter } from "styles/mixins";
import zIndexs from "styles/zIndex";

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  ${flexCenter};
  padding: 48px 224px;
  z-index: 1100;
  background: #070711c0;
  transition: 0.2s cubic-bezier(0.82, 0.24, 0.09, 0.77);

  &:focus {
    outline: none;
  }

  * {
    border: none;
    outline: none;
  }

  .times {
    color: ${(p) => p.theme.colors.text.primary};
    border-radius: 50%;
    padding: 8px;
    background: ${(p) => p.theme.colors.background.primary};
    text-align: right;
    position: absolute;
    top: 48px;
    right: 48px;
    cursor: pointer;
  }

  img,
  video {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  ${deviceQuery.laptopL} {
    padding: 48px 180px;
  }

  ${deviceQuery.laptopS} {
    padding: 48px 130px;
  }

  ${deviceQuery.tabletM} {
    padding: 36px 80px;

    .times {
      top: 36px;
      right: 36px;
    }
  }

  ${deviceQuery.mobileL} {
    padding: 24px;

    .times {
      top: 24px;
      right: 24px;
    }
  }
`;
