import { colors } from "styles/theme";

export const getHighlightedSearchedText = (originalString: string, searchValue: string) => {
  if (!searchValue) return originalString;

  // Escape special characters in the search value
  const escapedSearchValue = searchValue.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  // Create a regular expression with the escaped search value and case-insensitive flag
  const regex = new RegExp(`(${escapedSearchValue})`, "gi");

  // Split the original string by the search value and join it back with the search value wrapped in a span for highlighting
  const highlightedString = originalString.replace(
    regex,
    (match) => `<span style="color: ${colors.green.primary}">${match}</span>`
  );

  return <span dangerouslySetInnerHTML={{ __html: highlightedString }} />;
};
