import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RootState } from "store/store";
import { IAnalyticsCountries, IAnalyticsLinks } from "types/analytics";

interface IInitialState {
  links: IAnalyticsLinks[];
  platform: IAnalyticsLinks[];
  clicks: IAnalyticsLinks[];
  unique: IAnalyticsCountries[];
  views: IAnalyticsCountries[];
}

const initialState: IInitialState = {
  links: [],
  platform: [],
  unique: [],
  views: [],
  clicks: [],
};

const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    setAnalytics: (state, action: PayloadAction<IInitialState>) => {
      state.clicks = action.payload.clicks;
      state.links = action.payload.links;
      state.platform = action.payload.platform;
      state.unique = action.payload.unique;
      state.views = action.payload.views;
    },
    resetAnalytics: () => {
      return { ...initialState };
    },
  },
});

export const { setAnalytics, resetAnalytics } = analyticsSlice.actions;

export default analyticsSlice.reducer;

export const getAnalytics = ({ creator }: RootState) => creator.analytics;
