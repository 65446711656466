import { useLayoutEffect, useRef } from "react";

import { RollingTabsContainer } from "./style";

import { TabItem } from "types/tabs.types";

interface Props<T> {
  full?: boolean;
  options: TabItem<T>[];
  selectedAction: number;
  onTabClicked: (tabIndex: number, tab: TabItem<T>) => void;
  className?: string;
}

export function RollingTabs<T>({
  full = false,
  options,
  selectedAction,
  onTabClicked,
  className,
}: Props<T>) {
  const ref = useRef<HTMLDivElement>(null);
  const refSelected = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (refSelected.current && ref.current) {
      refSelected.current.style.width = `${ref.current.clientWidth}px`;
      refSelected.current.style.left = `${ref.current.offsetLeft}px`;
      refSelected.current.style.top = `${ref.current.offsetTop}px`;
      refSelected.current.style.height = `${ref.current.clientHeight}px`;
    }
  }, [selectedAction]);

  return (
    <RollingTabsContainer className={className} full={full}>
      <div ref={refSelected} className="selected" />
      {options.map((item, index) => (
        <div
          key={index}
          onClick={() => onTabClicked(index, item)}
          ref={selectedAction === index ? ref : null}
          className={`item ${selectedAction === index ? "active" : ""}`}
        >
          <p>{item.name}</p>
          {typeof item.count === "number" && <div className="number">{item.count}</div>}
        </div>
      ))}
    </RollingTabsContainer>
  );
}

export default RollingTabs;
