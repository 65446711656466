import { FC } from "react";

import { TabsContainer } from "./style";
import { ITabs } from "./types";

interface Props {
  tabs: ITabs[];
  activeTab: number;
  orientation?: "vertial" | "horizontal";
  withLine?: boolean;
  onClick: (value: ITabs) => void;
  className?: string;
  tabClassName?: string;
  round?: boolean;
}

const Tabs: FC<Props> = ({
  tabs,
  activeTab,
  onClick,
  withLine = false,
  orientation = "horizontal",
  className,
  tabClassName,
  round = false,
}) => (
  <TabsContainer round={round} className={className} withLine={withLine} orientation={orientation}>
    <ul className="tabs">
      {tabs.map((tab) => (
        <li
          key={tab.key}
          className={`tab ${tabClassName} ${activeTab === tab.key ? "active" : ""}`}
        >
          <button disabled={tab.disabled} className="button" onClick={() => onClick(tab)}>
            <span className="text">{tab.label}</span>
            {Number.isInteger(tab.count) && <span className="number">{tab.count}</span>}
          </button>
          {withLine && <div className="line" />}
        </li>
      ))}
    </ul>
    {withLine && <div className="line" />}
  </TabsContainer>
);

export default Tabs;
