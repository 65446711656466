import getAxiosInst, { endpoints, apiHandler } from "./axios";

import { getLocalStorageItem } from "common/helpers/localStorage";
import { STORAGES } from "constants/appConstants";
import { setLoader } from "store/slices/featureSlice";
import {
  setNotificationCount,
  setNotifications,
  setLatestNotifications,
  readNotification,
  setUnreadNotification,
} from "store/slices/global/notificationSlice";
import store from "store/store";

export const getNotficationCountAPI = () => {
  const role = getLocalStorageItem({ key: STORAGES.accountType });

  apiHandler(
    () =>
      getAxiosInst().get(endpoints.getNotificationCount, {
        params: {
          date: store.getState().global.userDetails.lastnotificationReadAt,
          role,
        },
      }),
    {
      onSuccess: (data) => {
        store.dispatch(setNotificationCount(data.count));
      },
      onError: (error) => {
        console.log("catch error ===>>>", error.response);
      },
    }
  );
};

export const getUnreadNotificationApi = (role) => {
  apiHandler(
    () =>
      getAxiosInst().get(endpoints.getNotificationCount, {
        params: {
          role,
        },
      }),
    {
      onSuccess: (res) => {
        store.dispatch(setUnreadNotification(res.count));
      },
      onError: (error) => {
        console.log("catch error ===>>>", error, error.response);
      },
    }
  );
};

export const updateNotficationsAPI = (date) => {
  apiHandler(
    () =>
      getAxiosInst().post(endpoints.updateNotification, {
        params: {
          date,
        },
      }),
    {
      onError: (error) => {
        console.log("catch error ===>>>", error.response);
      },
    }
  );
};

export const readNotficationAPI = (id) => {
  apiHandler(() => getAxiosInst().put(`/notification/${id}/read`), {
    onSuccess: () => {
      store.dispatch(readNotification(id));
    },
    onError: (error) => {
      console.log("catch error ===>>>", error.response);
    },
  });
};

export const getNotficationsAPI = ({ limit, skip }) => {
  const role = getLocalStorageItem({ key: STORAGES.accountType });
  const user = store.getState().global.user;
  store.dispatch(setLoader({ name: "notificationLoader", value: true }));

  apiHandler(
    () =>
      getAxiosInst().get(endpoints.getNotifications, {
        params: {
          role,
          limit,
          skip,
          date: user && user.createdAt,
        },
      }),
    {
      onSuccess: (res) => {
        store.dispatch(
          setNotifications({
            data: res.data,
            total: res.Totalnotification,
          })
        );
      },
      onError: (error) => {
        console.log("catch error ===>>>", error, error.response);
      },
      final: () => {
        store.dispatch(setLoader({ name: "notificationLoader", value: false }));
      },
    }
  );
};

export const getLatestNotficationsAPI = ({ date }) => {
  const role = getLocalStorageItem({ key: STORAGES.accountType });

  apiHandler(
    () =>
      getAxiosInst().get(endpoints.getNotifications, {
        params: {
          role,
          date,
        },
      }),
    {
      onSuccess: (res) => {
        store.dispatch(
          setLatestNotifications({
            data: res.data,
            total: res.Totalnotification,
          })
        );
      },
      onError: (error) => {
        console.log("catch error ===>>>", error, error.response);
      },
    }
  );
};
