import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "store/store";
import { IReward } from "types/challengeRewards";
import { IChallenges, ISubmission } from "types/challenges";

interface IInitialState {
  data: IChallenges[];
  total: number;
  mostClosed: {
    data: IChallenges[];
    total: number;
  };
  creatorSubmissions: {
    data: ISubmission[];
    total: number;
  };
  mostExpensive: {
    data: IChallenges[];
    total: number;
  };
  challengesRewards: {
    data: IReward[];
    total: number;
  };
}

const initialState: IInitialState = {
  data: [],
  total: 0,
  mostClosed: {
    data: [],
    total: 0,
  },
  creatorSubmissions: {
    data: [],
    total: 0,
  },
  mostExpensive: {
    data: [],
    total: 0,
  },
  challengesRewards: {
    data: [],
    total: 0,
  },
};

const challengeSlice = createSlice({
  name: "challenges",
  initialState,
  reducers: {
    setChallenges: (state, action) => {
      const { data, total } = action.payload;
      state.data = Array.isArray(data)
        ? [...state.data.filter((item) => item !== null), ...data]
        : [data, ...state.data.filter((item) => item !== null)];
      state.total = total;
    },
    deleteChallenge: (state, action) => {
      state.data = state.data.filter(
        (item) => item === null || item._id !== action.payload.trendId
      );

      state.total = state.total === 0 ? state.total : state.total - 1;
    },
    setMostClosed: (state, action) => {
      const { data, total } = action.payload;
      state.mostClosed.data = data;
      state.mostClosed.total = total;
    },
    setChallengesRewards: (state, action) => {
      state.challengesRewards.data = action.payload.data;
      state.challengesRewards.total = action.payload.count;
    },
    setMostExpensive: (state, action) => {
      const { data, total } = action.payload;
      state.mostExpensive.data = data;
      state.mostExpensive.total = total;
    },
    setCreatorSubmissions: (state, action) => {
      const { data, total } = action.payload;
      state.creatorSubmissions.data = data;
      state.creatorSubmissions.total = total;
    },
    resetChallenges: (state) => {
      state.data = [];
      state.total = 0;
      state.creatorSubmissions = {
        data: [],
        total: 0,
      };
    },
    resetMostClosedChallenges: (state) => {
      state.mostClosed = {
        data: [],
        total: 0,
      };
    },
    resetMostExpensiveChallenges: (state) => {
      state.mostClosed = {
        data: [],
        total: 0,
      };
    },
  },
});

export const {
  setChallenges,
  deleteChallenge,
  setChallengesRewards,
  resetChallenges,
  setCreatorSubmissions,
  setMostClosed,
  resetMostClosedChallenges,
  resetMostExpensiveChallenges,
  setMostExpensive,
} = challengeSlice.actions;

export default challengeSlice.reducer;

export const getChallenges = ({ creator: { challenging } }: RootState) => {
  return {
    data: challenging.challenges.data,
    total: challenging.challenges.total,
  };
};
export const getMostClosedChallenges = ({ creator: { challenging } }: RootState) =>
  challenging.challenges.mostClosed;
export const getMostExpensiveChallenges = ({ creator: { challenging } }: RootState) =>
  challenging.challenges.mostExpensive;
export const getCreatorSubmissions = ({ creator: { challenging } }: RootState) =>
  challenging.challenges.creatorSubmissions;
export const getChallengesRewards = ({ creator: { challenging } }: RootState) =>
  challenging.challenges.challengesRewards;
