import { Formik } from "formik";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import ChangeAvatar from "./components/ChangeAvatar";

import ChangeTheme from "./components/ChangeTheme";

import { ProfileForm, SaveButton, SubPageContainer } from "../../style";

import Copy from "assets/svg/Copy";
import { convertArrForReactSelect, makeToast } from "common/helpers/helper";
import Input from "Components/Input";
import { frontendDomain } from "configuration/config";
import { CONTENTPRIVACY, LANGUAGES, SOCIALMEDIAS, STRINGS } from "constants/appConstants";
import { ALPHBETONLY_REGEX } from "constants/regex";
import { getCategories } from "services/adminServices";
import { updateProfileInfoAPI, updateUsername } from "services/UserServices";
// import { getCategoryLists } from "store/slices/admin/adminSlice";
import { setLoader } from "store/slices/featureSlice";
import { setIsProfileUpdated } from "store/slices/global/globalSlice";
import { getUser } from "store/slices/global/userSlice";
import { BlockCard } from "styles/mixins";
import { LANGUAGES as LANGUAGESTYPE } from "types/language.types";
import { IFormikGeneralValues } from "types/user";

const General = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector(getUser);
  const schema = Yup.object().shape({
    name: Yup.string().max(30, "Max length 30"),
    about: Yup.string(),
  });

  const tmpUsername = user?.username || "";

  const isDisabled = useCallback((dirty: boolean) => {
    dispatch(setIsProfileUpdated(!dirty));
    return dirty;
  }, []);

  const handleSubmit = (values: IFormikGeneralValues) => {
    if (!user) return;
    dispatch(setLoader({ name: "editProfileLoader", value: true }));

    updateProfileInfoAPI({
      data: {
        displayname: values.name,
        aboutMe: values.about,
        favTopic: values.interestedTopics.map((item) => item.value as string),
        languages: values.languages.value as LANGUAGESTYPE,
        mostActiveOn: values.mostActiveOn.value as string,
        privacy: values.privacy.value as string,
      },
      part: "general",
    });
    user.allowUpdateUsername &&
      tmpUsername !== values.username &&
      updateUsername({ username: values.username });
  };

  const copyTextToClipboard = async (text: string) => {
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(text);
      makeToast({ message: "Copied ", type: STRINGS.toastType.success });
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <SubPageContainer>
      <p className="title">{t("creator.profile.general")}</p>
      {user && (
        <Formik<IFormikGeneralValues>
          enableReinitialize
          initialValues={{
            name: user.displayname || "",
            username: user.username || "",
            about: user.aboutMe || "",
            interestedTopics: convertArrForReactSelect(user.favTopic || []),
            socialPlatformURL: `${frontendDomain}/@${user.username}` || "",
            languages: LANGUAGES.find((item) => item.value === user.languages) || LANGUAGES[0],
            mostActiveOn:
              SOCIALMEDIAS.find((item) => item.value === user.mostActiveOn) || SOCIALMEDIAS[0],
            privacy:
              CONTENTPRIVACY.find((item) => item.value === user.mostActiveOn) || CONTENTPRIVACY[0],
          }}
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            dirty,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <ProfileForm onSubmit={handleSubmit}>
              <ChangeTheme />
              <ChangeAvatar />
              <BlockCard className="card">
                <p className="title">{t("common.editProfile")}</p>
                <Input
                  name="name"
                  label={t("common.user_profile.display_name")}
                  placeholder={t("common.user_profile.display_name")}
                  info={t("common.user_profile.display_name_msg")}
                  value={values.name}
                  handleChange={(e) => {
                    const dname = e.target.value.trimStart();
                    if (dname.match(ALPHBETONLY_REGEX) || dname === "") {
                      setFieldValue("name", dname);
                    }
                  }}
                  handleBlur={handleBlur}
                  maxLength={30}
                  error={errors.name && touched.name ? errors.name : ""}
                />
                <Input
                  readOnly={!user.allowUpdateUsername}
                  label={t("common.username")}
                  type="text"
                  name="username"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.username}
                  info={t("common.user_profile.username_info")}
                />
                {/* <MultiSelect
                label={`${t("common.user_profile.topics_i_create")}(${t("common.category")})`}
                name="interestedTopics"
                placeholder={`${t("common.user_profile.small_topics_i_create")}(${t(
                  "common.category"
                )})`}
                value={values.interestedTopics.map((item) => ({
                  value: item.value,
                  label: capitalize(item.label),
                }))}
                handleChange={(data) => setFieldValue("interestedTopics", data)}
                handleBlur={handleBlur}
                options={categoryList}
              /> */}
                <Input
                  label={t("common.user_profile.profile_url")}
                  readOnly
                  type="text"
                  name="socialPlatformURL"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.socialPlatformURL}
                  info={t("common.user_profile.profile_link_msg")}
                  right={
                    <div
                      className="cursor-pointer"
                      onClick={() => copyTextToClipboard(values.socialPlatformURL)}
                    >
                      <Copy size={24} />
                    </div>
                  }
                />
                {/* <SingleSelect
                styles={selectStyle}
                label={t("common.select_language")}
                value={values.languages}
                handleChange={(data) => {
                  setFieldValue("languages", data);
                }}
                placeholder={t("common.select_language")}
                options={LANGUAGES}
              /> */}
                {/* <SingleSelect
                styles={selectStyle}
                label={t("common.user_profile.most_active_on")}
                value={values.mostActiveOn}
                options={SOCIALMEDIAS}
                placeholder={t("common.user_profile.small_select_platform")}
                handleChange={(data) => setFieldValue("mostActiveOn", data)}
              />
              <SingleSelect
                styles={selectStyle}
                label={t("creator.campaign_ideas.account_type")}
                value={values.privacy}
                options={CONTENTPRIVACY}
                handleChange={(data) => setFieldValue("privacy", data)}
              /> */}
                <Input
                  textarea
                  name="about"
                  label={t("common.user_profile.about_me")}
                  placeholder={t("common.user_profile.about_me")}
                  info={t("common.user_profile.about_me_msg")}
                  maxLength={140}
                  handleChange={(e) => {
                    if (e.target.value.trimStart().length <= 140) {
                      setFieldValue("about", e.target.value.trimStart());
                    }
                  }}
                  handleBlur={handleBlur}
                  value={values.about}
                  error={errors.about && touched.about ? errors.about : ""}
                />
                <div className="saveContainer">
                  <SaveButton variant="primary" disabled={isDisabled(!dirty)} type="submit">
                    {t("creator.profile.save")}
                  </SaveButton>
                </div>
              </BlockCard>
            </ProfileForm>
          )}
        </Formik>
      )}
    </SubPageContainer>
  );
};

export default General;
