import { debounce } from "lodash";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { FacebookContentPreviewContainer } from "./style";

import { validateIfShortenedFaceBookUrl } from "common/helpers/helper";
import { getUnShortenedUrl } from "services/UserServices";

interface Props {
  url: string;
  errorStateChanged?: (hasError: boolean) => void;
  className?: string;
  iframeClassName?: string;
}
const minFaceBookSupportedWidth = 346;

export const FacebookContentPreviewCard: FC<Props> = ({ url, errorStateChanged }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [frameWidth, setFrameWidth] = useState(0);
  const [frameScale, setScale] = useState<number>(1);

  const [urlFromShortened, setUrlFromShortened] = useState<string>("");

  useEffect(() => {
    errorStateChanged && errorStateChanged(false);
  }, [url]);

  const getWidth = (): void => {
    if (!ref.current) return;

    const clientWidth = ref.current.clientWidth;
    setFrameWidth(clientWidth < minFaceBookSupportedWidth ? 350 : clientWidth);

    setScale(clientWidth < minFaceBookSupportedWidth ? clientWidth / 350 : 1);
  };

  useEffect(() => {
    const debouncedResize = debounce(getWidth, 300, { maxWait: 1000 });
    debouncedResize();

    window.addEventListener("resize", debouncedResize);
    return () => window.removeEventListener("resize", debouncedResize);
  }, []);

  const urlChangedWithDebounce = useCallback((url: string) => {
    getUnShortenedUrl(
      url,
      (resp: { status: boolean; data: { url: string } }) => {
        if (!resp.status) {
          errorStateChanged && errorStateChanged(true);
          return;
        }

        const fullUrl = resp.data.url;
        if (!fullUrl) return;

        setUrlFromShortened(fullUrl);
      },
      (err: any) => {
        errorStateChanged && errorStateChanged(true);
      }
    );
  }, []);

  useEffect(() => {
    // If url isn't shortened, skip server request, means url already full size
    if (!validateIfShortenedFaceBookUrl(url)) return;
    const debouncedUrl = debounce(urlChangedWithDebounce, 400);

    debouncedUrl(url);

    return () => {
      debouncedUrl.cancel();
    };
  }, [url, urlChangedWithDebounce]);

  const memoUrl = useMemo<string>(() => {
    return urlFromShortened || url;
  }, [urlFromShortened, url]);

  return (
    <FacebookContentPreviewContainer ref={ref}>
      <iframe
        title={url}
        className="iframe"
        frameBorder={0}
        src={`/facebook_iframe.html?url=${memoUrl}&width=${frameWidth}&scale=${frameScale}`}
        allowFullScreen
        scrolling="no"
        allow="encrypted-media;"
      />
    </FacebookContentPreviewContainer>
  );
};
