import { useDispatch, useSelector } from "react-redux";

import { CloseIcon, ToastContainer, ToastsContainer } from "./style";

import Check from "assets/svg/Check";
import Close from "assets/svg/Close";
import { deleteToast, getToast, hideToast } from "store/slices/featureSlice";
import { colors } from "styles/theme";

const Toast = () => {
  const toast = useSelector(getToast);
  const dispatch = useDispatch();

  const hide = (id: string) => {
    dispatch(hideToast(id));

    setTimeout(() => {
      dispatch(deleteToast(id));
    }, 150);
  };

  return (
    <ToastsContainer clicked={!!toast.length}>
      {toast.map((item) => (
        <ToastContainer
          key={item.id}
          style={{
            transform: item.show ? "translateX(0)" : "translateX(170%)",
          }}
          type={item.type}
        >
          <div className="icon_wrapper">
            {item.type === "success" && <Check strokeColor={colors.white.primary} />}
            {item.type === "error" && <Close size={24} />}
          </div>

          <div>{item.message}</div>
          <CloseIcon onClick={() => hide(item.id)}>
            <Close size={24} />
          </CloseIcon>
        </ToastContainer>
      ))}
    </ToastsContainer>
  );
};

export default Toast;
