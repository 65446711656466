import styled from "styled-components";

import { deviceQuery } from "styles/mediaSizes";

export const TutorialAccordion = styled.div`
  background-color: ${(p) => p.theme.colors.background.secondary};
  padding: 18px 16px;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.03);
  border-radius: 16px;
`;

export const CollapsedContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;

  .left_block {
    display: flex;
    gap: 12px;
    align-items: center;
  }
`;

export const ExpandedContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
