import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import FooterCategory from "./components/FooterCategory";
import FooterSocials from "./components/FooterSocials";
import { FooterContainer, JoinButton } from "./style";

import FullLogo from "assets/svg/FullLogo";
import { FOOTERCONTACT, FOOTERRESOURCES } from "constants/appConstants";
import { getLoggedInStatus } from "store/slices/authSlice";
import { getPublicUser } from "store/slices/global/previewProfileSlice";

const Footer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isLogin = useSelector(getLoggedInStatus);
  const user = useSelector(getPublicUser);

  return (
    <FooterContainer isLogin={isLogin || !!user._id}>
      <div className="content">
        <div className="categoryContainer">
          <FooterCategory
            className="small"
            title={`${t("home.ready_to")} ${t("home.start")}`}
            subTitle={t("home.start_capitalizing")}
            join={
              <JoinButton variant="primary" onClick={() => navigate("/register")}>
                {t("common.join")}
              </JoinButton>
            }
          />
          <FooterCategory
            title={t("home.contact_us")}
            subTitle={t("home.questions_inquiries")}
            items={FOOTERCONTACT}
          />
          <FooterCategory
            title={t("home.resources")}
            subTitle={t("home.subTitle_resources")}
            items={FOOTERRESOURCES}
          />
          <FooterSocials />
        </div>
        <div className="divider" />
        <div className="logo">
          <FullLogo />
          <span>Copyright © LewkLabs, 2022</span>
        </div>
      </div>
    </FooterContainer>
  );
};

export default Footer;
