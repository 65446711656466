import { Formik } from "formik";
// eslint-disable-next-line import/no-unresolved
import { memo, useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import Fields from "./components/Fields";

import { emitAnalyticsRegisterEvent } from "common/helpers/helper";
import Button from "Components/Button";
import { GoogleLoginButton } from "Components/GoogleLoginButton";
import MiniLoader from "Components/MiniLoader";
import OverlayLoader from "Components/OverlayLoader";
import SocialIcons from "Components/SocialIcons";

import { REACT_APP_GOOGLE_RECAPTCHA_KEY } from "configuration/config";
import { ROLES, STRINGS } from "constants/appConstants";
import { PASSWORD_REGEX } from "constants/regex";
import { AUTH_ROUTES } from "constants/routes";
import { ChooseRoleModal } from "pages/auth/Register/components/RegistrationForm/components/ChooseRoleModal";
import { ROUTES } from "routes/routesData";
import {
  createNewUserAPI,
  getUserLoginInfoByGoogleToken,
  registerUserByGoogleToken,
} from "services/authServices";
import { getRegistrationFields, setRegistrationFormFields, setToken } from "store/slices/authSlice";
import { getRegistrationLoader, setLoader } from "store/slices/featureSlice";
import store from "store/store";
import { AuthActions, AuthContainer, AuthForm } from "styles/auth";
import { LANGUAGES_SHIRT_TO_LONG } from "types/language.types";

const RegistrationForm = memo(() => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();

  const [captchaVerified, setCaptchaVerified] = useState(false);
  const { accountType } = useSelector(getRegistrationFields);
  const registrationLoader = useSelector(getRegistrationLoader);
  const startLoader = () => dispatch(setLoader({ name: "registrationLoader", value: true }));
  const stopLoader = () => dispatch(setLoader({ name: "registrationLoader", value: false }));

  const [roleModalOpened, setRoleModalOpened] = useState(false);
  const [googleAuthToken, setGoogleAuthToken] = useState(null);
  const [registrationType, setRegistrationType] = useState(undefined); // 'regular' | 'googleAfterLogin' |undefined

  const isDisabled = (values) => {
    return (
      registrationLoader ||
      !captchaVerified ||
      values.email === "" ||
      values.password === "" ||
      values.confirmPassword === ""
    );
  };

  const RegistrationSchema = Yup.object()
    .shape({
      name:
        accountType === ROLES.admin || accountType === ROLES.judge
          ? Yup.string().required(t("common.required"))
          : Yup.string(),
      password: Yup.string()
        .required(t("common.required"))
        .matches(PASSWORD_REGEX, t("auth.min_8_characters")),
      confirmPassword: Yup.string()
        .required(t("common.required"))
        .oneOf([Yup.ref("password"), null], t("auth.passwords_should_match")),
      newsLetter: Yup.boolean(),
      phoneCode:
        accountType === ROLES.admin || accountType === ROLES.judge
          ? Yup.string().required("Select country code")
          : Yup.string(),
      email: Yup.string().required(t("common.required")).email(t("signup.invalid_email")),
    })
    .test("myCustomTest", null, async (obj) => {
      return true;
    });

  const handleRegister = async (values, role, resetForm) => {
    let registrationDetails =
      accountType === ROLES.admin || accountType === ROLES.judge
        ? {
            displayname: values.name,
          }
        : accountType === ROLES.creator
        ? {
            aboutMe: "",
            interest: "",
            industry: values.industry,
            companyname: values.companyName,
            companywebsite: values.companyWebsite,
            newsletter: values.newsLetter,
          }
        : {
            aboutMe: "",
            interest: "",
            industry: values.industry,
            companyname: values.companyName,
            companywebsite: values.companyWebsite,
            newsletter: values.newsLetter,
          };

    registrationDetails = {
      ...registrationDetails,
      email: values.email,
      password: values.password,
      role,
      languages: LANGUAGES_SHIRT_TO_LONG[i18n.language] || LANGUAGES_SHIRT_TO_LONG.en,
      mobile: values.companyNumber || "9999999999",
    };

    createNewUserAPI({ data: registrationDetails }, (res) => {
      resetForm();
      navigate("/login");
      emitAnalyticsRegisterEvent();
    });
  };

  const submitAndTriggerRoleSelect = async () => {
    setRegistrationType("regular");
    setRoleModalOpened(true);
  };

  const triggerGoogleLoginOrRegister = async (token) => {
    getUserLoginInfoByGoogleToken(
      { googleToken: token },
      (data) => {
        store.dispatch(setToken(data.Auth));
        navigate("/login");
      },
      (err) => {
        // navigates to the same page but with token in order to trigger registration
        navigate(ROUTES.register, {
          state: {
            registerAfterGoogleLogin: {
              token,
            },
          },
        });
      }
    );
  };

  const handleReCaptchaChange = async (value) => {
    dispatch(
      setRegistrationFormFields({
        name: STRINGS.verifyCaptcha,
        value: !!value,
      })
    );
    setCaptchaVerified(value);
  };

  useEffect(() => {
    handleUserRegistrationAfterLogin();
  }, [state]);

  const handleUserRegistrationAfterLogin = () => {
    if (!state?.registerAfterGoogleLogin) return;

    // saves token for current session
    const { token } = state.registerAfterGoogleLogin;
    setGoogleAuthToken(token);
    setRegistrationType("googleAfterLogin");
    // clears token from current state
    window.history.replaceState({}, document.title);

    setRoleModalOpened(true);
  };

  const registerUserViaGoogle = (googleAuthToken, role) => {
    const userRegisterInfo = {
      ga: googleAuthToken,
      role,
      mobile: "9999999999",
      languages: LANGUAGES_SHIRT_TO_LONG[i18n.language] || LANGUAGES_SHIRT_TO_LONG.en,
    };
    startLoader();

    registerUserByGoogleToken(
      { googleToken: googleAuthToken, userRegisterInfo },
      (data) => {
        store.dispatch(setToken(data.authToken));

        navigate("/login");
        stopLoader();
        emitAnalyticsRegisterEvent();
      },
      (err) => {
        stopLoader();
      }
    );
  };

  return (
    <>
      <Formik
        initialValues={{
          name: "",
          email: "",
          password: "",
          confirmPassword: "",
          newsLetter: false,
          companyName: "",
          phone: "",
          phoneCode: "",
          companyWebsite: "",
          industry: "",
          invitationCode: "",
        }}
        validationSchema={RegistrationSchema}
        onSubmit={submitAndTriggerRoleSelect}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          resetForm,
        }) => (
          <AuthForm onSubmit={handleSubmit}>
            <AuthContainer>
              <div className="left">
                <p className="title">{t("signup.signup_now")}</p>
                <p className="text">
                  <span>{t("signup.already_member")} </span>
                  <Link to="/login">{t("login.login")}</Link>
                </p>
                <>
                  <Fields
                    values={values}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />
                  <ReCAPTCHA
                    sitekey={REACT_APP_GOOGLE_RECAPTCHA_KEY}
                    onChange={handleReCaptchaChange}
                  />

                  <Button full type="submit" variant="primary" disabled={isDisabled(values)}>
                    {registrationLoader ? <MiniLoader /> : t("signup.signup_now")}
                  </Button>

                  {(accountType !== ROLES.admin || accountType !== ROLES.judge) && (
                    <>
                      <span className="or_divider">{t("common.or")}</span>

                      <GoogleLoginButton
                        onSuccess={(resp) => {
                          if (!resp.credential) return;

                          setGoogleAuthToken(resp.credential);
                          triggerGoogleLoginOrRegister(resp.credential);
                        }}
                        onError={(err) => {
                          console.log("error", err);
                        }}
                      />
                    </>
                  )}
                </>
              </div>
              <div className="right">
                <Link to={AUTH_ROUTES.login}>
                  <img src="/register.png" alt="register" loading="lazy" />
                </Link>
              </div>
              <div className="lines">
                <div className="line" />
                <div className="line" />
              </div>
            </AuthContainer>
            <AuthActions>
              <div className="socials">
                <SocialIcons />
              </div>
            </AuthActions>
            {roleModalOpened && (
              <ChooseRoleModal
                show={roleModalOpened}
                onCloseError={() => {
                  setRoleModalOpened(false);
                }}
                onCloseSuccess={(role) => {
                  setRoleModalOpened(false);
                  if (registrationType === "regular") {
                    handleRegister(values, role, resetForm);
                    setRegistrationType(undefined);
                    return;
                  }

                  if (registrationType === "googleAfterLogin") {
                    setRegistrationType(undefined);
                    registerUserViaGoogle(googleAuthToken, role);
                  }
                }}
              />
            )}
          </AuthForm>
        )}
      </Formik>
      <OverlayLoader isLoading={registrationLoader} />
    </>
  );
});

export default RegistrationForm;
