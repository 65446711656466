import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "store/store";
import { IGiftingCreator } from "types/gifting";

interface IInitialState {
  data: IGiftingCreator[];
  total: number;
}

const initialState: IInitialState = {
  data: [],
  total: 0,
};

const giftingSlice = createSlice({
  name: "gifting",
  initialState,
  reducers: {
    setGifting: (state, action) => {
      state.data = action.payload.result;
      state.total = action.payload.totalCount;
    },
    resetGifting: () => {
      return { ...initialState };
    },
  },
});

export const { setGifting, resetGifting } = giftingSlice.actions;

export default giftingSlice.reducer;

export const getGifting = ({ creator }: RootState) => creator.gifting;
