import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { Option } from "Components/SelectField/types";

import { RootState } from "store/store";

import { IAriTransactions, ITokenGroup, ITransactions, IWallet } from "types/wallet";

interface IInitialState {
  wallet: IWallet[];
  groups: ITokenGroup[];
  fee: {
    feeAmount: number;
    totalAmount: number;
  };
  transactions: {
    data: ITransactions[];
    total: number;
  };
  withdrawalsHistory: {
    data: ITransactions[];
    total: number;
  };
  ariHistory: {
    data: IAriTransactions[];
    total: number;
  };
  userWallet: any;
  totalWallet: number;
  currencies: Option[];
  tokenGroupsOption: Option[];
  tokens: { [x: string]: string };
  tokenGroups: { [x: string]: string };
}

const initialState: IInitialState = {
  wallet: [],
  groups: [],
  fee: {
    feeAmount: 0,
    totalAmount: 0,
  },
  transactions: {
    total: 0,
    data: [],
  },
  withdrawalsHistory: {
    total: 0,
    data: [],
  },
  ariHistory: {
    total: 0,
    data: [],
  },
  userWallet: null,
  totalWallet: 0,
  currencies: [],
  tokenGroupsOption: [],
  tokens: {},
  tokenGroups: {},
};

const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    setWallet: (state, action: PayloadAction<IWallet[]>) => {
      const currencies = action.payload.map((item) => {
        return {
          label: item.name,
          value: item.assetId,
        };
      });

      state.wallet = action.payload;
      state.totalWallet = action.payload.reduce((acc, item) => acc + item.refCurrency, 0);
      state.currencies = currencies.reduce<Option[]>((acc, item) => {
        if (!acc.find((value) => value.value === item.value)) {
          acc.push(item);
        }
        return acc;
      }, []);
      state.tokens = currencies.reduce((obj, value) => {
        return { ...obj, [value.value]: value.label };
      }, {});
    },
    setFee: (state, action) => {
      state.fee.feeAmount = action.payload.feeAmount;
      state.fee.totalAmount = action.payload.totalAmount;
    },
    setTransactions: (state, action) => {
      const { data, total } = action.payload;
      state.transactions.total = total;
      state.transactions.data = [...state.transactions.data, ...data];
    },
    setWithdrawalsHistory: (state, action) => {
      const { data, total } = action.payload;
      state.withdrawalsHistory.total = total;
      state.withdrawalsHistory.data = [...state.withdrawalsHistory.data, ...data];
    },
    setAriHistory: (state, action: PayloadAction<{ data: IAriTransactions[]; total: number }>) => {
      const { data, total } = action.payload;
      state.ariHistory.total = total;
      state.ariHistory.data = [...state.ariHistory.data, ...data];
    },
    setTokenGroups: (state, action: PayloadAction<ITokenGroup[]>) => {
      const options = action.payload.map((item) => {
        return { value: item.key, label: item.name };
      });

      state.groups = action.payload;
      state.tokenGroupsOption = options;
      state.tokenGroups = options.reduce((obj, value) => {
        return { ...obj, [value.value]: value.label };
      }, {});
    },
    resetWallet: (state) => {
      state = initialState;
    },
    setUserWallet: (state, action) => {
      state.userWallet = action.payload;
    },
    resetWithdraw: (state) => {
      state.withdrawalsHistory.data = [];
    },
    resetTransactions: (state) => {
      state.transactions.data = [];
    },
    resetAri: (state) => {
      state.ariHistory.data = [];
    },
    resetFee: (state) => {
      state.fee.feeAmount = 0;
      state.fee.totalAmount = 0;
    },
  },
});

export const {
  setWallet,
  setTokenGroups,
  setAriHistory,
  setWithdrawalsHistory,
  setTransactions,
  resetWallet,
  setUserWallet,
  resetWithdraw,
  resetAri,
  resetTransactions,
  setFee,
  resetFee,
} = walletSlice.actions;

export default walletSlice.reducer;

export const getWallet = ({ wallet }: RootState) => wallet.wallet;
export const getGroups = ({ wallet }: RootState) => wallet.groups;
export const getTokenGroups = ({ wallet }: RootState) => wallet.tokenGroups;
export const getTokenGroupsOption = ({ wallet }: RootState) => wallet.tokenGroupsOption;
export const getTransactions = ({ wallet }: RootState) => wallet.transactions;
export const getWithdrawalsHistory = ({ wallet }: RootState) => wallet.withdrawalsHistory;
export const getAriHistory = ({ wallet }: RootState) => wallet.ariHistory;
export const getUserWallet = ({ wallet }: RootState) => wallet.userWallet;
export const getFee = ({ wallet }: RootState) => wallet.fee;
export const getWalletBalance = ({ wallet }: RootState) => wallet.totalWallet;
export const getCurrencies = ({ wallet }: RootState) => wallet.currencies;
export const getTokens = ({ wallet }: RootState) => wallet.tokens;
