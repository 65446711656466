import { FC, UIEvent, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { DrawerContainer, Header, Wrapper } from "./style";

import Close from "assets/svg/Close";
import LogoutComponent from "Components/Logout";
import SideBarMenu from "Components/SideBar";

import SidebarFooter from "Components/SideBar/components/SidebarFooter";
import { ROLES } from "constants/appConstants";
import { ROUTES } from "routes/routesData";
import { getLoggedInStatus } from "store/slices/authSlice";
import { getUser } from "store/slices/global/userSlice";

const SCROLL_OFFSET_TO_DISPLAY_HEADER_BLUR = 15;

interface Props {
  drawer: boolean;
  closeDrawer: () => void;
}

const Drawer: FC<Props> = ({ drawer: show, closeDrawer }) => {
  const isLoggedIn = useSelector(getLoggedInStatus);
  const user = useSelector(getUser);
  const navigate = useNavigate();
  const [isHeaderHasBg, setIsHeaderHasBg] = useState<boolean>(false);

  const redirectBaseOnRole = () => {
    if (!user) return;
    navigate(
      user.role === ROLES.admin
        ? ROUTES.adminSearchPeople
        : user.role === ROLES.judge
        ? ROUTES.judgeSearchCreators
        : user.role === ROLES.publisher
        ? ROUTES.publisherStandaloneCampaign
        : `/${user.role}${ROUTES.dashboard}`
    );
  };

  const onWrapperScroll = (ev: UIEvent<HTMLDivElement>) => {
    const wrapper = ev.target! as HTMLDivElement;
    const scrollOffset = wrapper.scrollTop;
    setIsHeaderHasBg(scrollOffset >= SCROLL_OFFSET_TO_DISPLAY_HEADER_BLUR);
  };

  return (
    <Wrapper
      style={{ transform: show ? "translateX(0)" : "translateX(100%)" }}
      show={show}
      onScrollCapture={onWrapperScroll}
      onClick={closeDrawer}
    >
      <DrawerContainer style={{ transform: show ? "translateX(0)" : "translateX(100%)" }}>
        <div className="content">
          <Header className={`${isHeaderHasBg && "withBlur"}`}>
            {user && user.generalTheme === "dark" ? (
              <img
                className="cursor-pointer light"
                onClick={() => {
                  if (isLoggedIn) {
                    redirectBaseOnRole();
                    closeDrawer();
                  }
                }}
                src="/logoLight.svg"
                alt="LEWK"
              />
            ) : (
              <img
                className="cursor-pointer dark"
                onClick={() => {
                  if (isLoggedIn) {
                    redirectBaseOnRole();
                    closeDrawer();
                  }
                }}
                src="/logoDark.svg"
                alt="LEWK"
              />
            )}
            <div className="times" onClick={closeDrawer}>
              <Close />
            </div>
          </Header>
          <SideBarMenu closeDrawer={closeDrawer} drawer />
          {isLoggedIn && <LogoutComponent />}
        </div>
        <SidebarFooter show />
      </DrawerContainer>
    </Wrapper>
  );
};

export default Drawer;
