import { FC, useMemo } from "react";

import { AvatarContainer } from "./style";
import { AvatarSizes } from "./types";

interface Props {
  src?: string;
  role?: string;
  alt: string;
  size?: AvatarSizes;
  border?: boolean;
  className?: string;
}

const Avatar: FC<Props> = ({ src, alt, size = "sm", role, border, className }) => {
  const imageSrc = useMemo(() => {
    switch (role) {
      case "creator":
        return "/defaultAvatarCreator.png";

      case "fan":
        return "/defaultAvatarFan.png";

      default:
        return "/defaultAvatarCreator.png";
    }
  }, [role]);

  return (
    <AvatarContainer size={size} border={border} className={className}>
      <img className="avatar" src={src || imageSrc} alt={alt} loading="lazy" />
    </AvatarContainer>
  );
};

export default Avatar;
