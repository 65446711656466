import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

import { deviceQuery } from "styles/mediaSizes";

export const Date = styled.div`
  white-space: nowrap;
  color: ${(p) => p.theme.colors.text.secondary};
  font-size: ${(p) => p.theme.typography.subText3};

  span {
    color: ${(p) => p.theme.colors.text.primary};
  }
`;

export const ProfileSection = styled.div<{
  level?: boolean;
  allowUpdateUsername?: boolean;
  hasPublicLinkSection?: boolean;
}>`
  width: 100%;

  .cancel {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 12px;

    .info {
      display: flex;
      font-size: ${(p) => p.theme.typography.subText3};
      align-items: center;
      gap: 8px;
      .icon,
      .yellow {
        color: ${(p) => p.theme.colors.icon.fifth};
      }
    }
  }

  .profile {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-bottom: ${(p) => (p.hasPublicLinkSection ? "12px" : "0px")};

    & > .divider {
      height: 1px;
      align-self: center;
      width: calc(100% - 24px);
      padding: 0px 12px;
      background: ${(p) => p.theme.colors.divider};
    }

    .random {
      display: flex;
      gap: 12px;
      padding: 12px;
      font-size: ${(p) => p.theme.typography.subText3};
      align-items: center;
      border-bottom: 1px solid ${(p) => p.theme.colors.divider};

      a {
        color: ${(p) => p.theme.colors.green.primary};
        text-decoration: none;
      }

      .icon {
        color: ${(p) => p.theme.colors.icon.fifth};
      }
    }

    .mainInfo {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .picture {
        padding: ${(p) => (p.allowUpdateUsername ? "4px 12px 0px" : "20px 12px 0px")};
        align-self: center;
      }

      .info {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: ${(p) => p.theme.typography.subText5};
        padding: 0px 12px;
        gap: 8px;

        .name {
          color: ${(p) => p.theme.colors.text.primary};
          opacity: 0.8;
        }

        .role {
          border-radius: 6px;
          color: ${(p) => p.theme.colors.text.secondary};
          font-size: ${(p) => p.theme.typography.subText3};
          padding: 2px 8px;
          background: ${(p) => p.theme.colors.background.fifth};
        }
      }
    }

    .public {
      padding: 0px 12px 12px;
      display: flex;
      flex-direction: column;
      font-size: ${(p) => p.theme.typography.subText3};

      &.small {
        display: none;
      }

      & > p {
        color: ${(p) => p.theme.colors.text.secondary};
      }

      .link {
        color: ${(p) => p.theme.colors.green.primary};
        gap: 12px;
        display: flex;
        align-items: center;

        .text {
          max-width: 90%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .icon {
          cursor: pointer;
          color: ${(p) => p.theme.colors.text.secondary};

          &:hover {
            color: ${(p) => p.theme.colors.green.primary};
          }
        }
      }
    }

    .publicSmall {
      display: none;
      padding: 0px 16px 16px;
      gap: 16px;

      a,
      .link {
        cursor: pointer;
        flex: 1;
        display: inline-flex;
        justify-content: center;
        text-decoration: none;
        gap: 12px;
        color: ${(p) => p.theme.colors.text.primary};
      }

      .divider {
        width: 1px;
        background: ${(p) => p.theme.colors.divider};
      }
    }
  }

  ${deviceQuery.desktopS} {
    margin: 0 auto;

    .profile {
      .mainInfo {
        padding: ${(p) => (p.allowUpdateUsername ? "0px 16px" : "16px 16px 0px")};
        flex-direction: row;

        .picture,
        .info {
          padding: 0px;
        }

        .info {
          flex: 1;
          align-items: start;
        }
      }

      .public {
        display: none;
      }
      .publicSmall {
        display: flex;
      }
    }
  }
`;

export const TakeALewkHiglihted = styled.div`
  font-size: ${(p) => p.theme.typography.subText3};
  color: ${(p) => p.theme.colors.text.third}!important;
  padding: 2px 8px;
  border-radius: 6px;
  background-color: rgba(37, 195, 116, 0.1);
`;

export const SidebarNav = styled.nav<{ drawer?: boolean }>`
  display: flex;
  flex-direction: column;
  transition: 350ms;
  z-index: 10;
  gap: 16px;
  min-height: ${({ drawer }) => (drawer ? "unset" : "100%")};
  position: relative;

  ${deviceQuery.desktopS} {
    width: 90%;
    margin: auto;
  }
`;

export const SidebarWrap = styled.div<{ sidebarData?: any; drawer?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .side_bar_sub_menu {
    div {
      color: ${(p) => p.theme.colors.text.secondary};
    }
    span {
      color: ${(p) => p.theme.colors.text.secondary};
    }
  }

  ${deviceQuery.laptopM} {
    ${(p) =>
      p.sidebarData &&
      css`
        margin: 0;
      `};
  }

  ${(p) =>
    !p.drawer &&
    css`
      flex: 1;
    `};
`;

export const SidebarLinkStyle = css<{ drawer: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  // height: ${(p) => (p.drawer ? "45px" : "60px")};
  text-decoration: none;
  border-radius: 16px;
  font-size: ${(p) => p.theme.typography.subText5};
  color: ${(p) => p.theme.colors.text.primary};
  position: relative;
  gap: 12px;
  transition: all 0.3s;

  .withoutActive {
    .icon {
      width: 12px;
    }
  }
  .icon {
    width: 24px;
  }

  &:hover {
    background: ${(p) => p.theme.colors.background.secondary};
    cursor: pointer;

    span {
      color: ${(p) => p.theme.colors.text.third};
    }

    svg {
      color: ${(p) => p.theme.colors.text.third} !important;
    }

    .withoutActive:not(.active) {
      color: ${(p) => p.theme.colors.text.primary};

      span {
        color: ${(p) => p.theme.colors.text.primary};
      }

      &:hover {
        color: ${(p) => p.theme.colors.text.third};

        span {
          color: ${(p) => p.theme.colors.text.third};
        }
      }
    }
  }
`;

export const SidebarLink = styled(NavLink)`
  ${SidebarLinkStyle}
  padding: 12px;

  &.active {
    background: ${(p) => p.theme.colors.background.secondary};
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.05);
    color: ${(p) => p.theme.colors.text.third};

    div.icon,
    span {
      color: ${(p) => p.theme.colors.text.third};
    }

    &.withoutActive {
      background: transparent;
      box-shadow: unset;
    }
  }

  &.pl-5 {
    padding-left: 5rem;
  }
`;

export const TabWithSubTab = styled.div<{ active: boolean; drawer: boolean }>`
  ${SidebarLinkStyle}
  padding: 12px;

  ${(p) =>
    p.active &&
    css`
      background: ${p.theme.colors.background.secondary};
      box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.05);

      .item {
        color: ${p.theme.colors.text.third};

        .icon {
          color: ${p.theme.colors.text.third};
        }

        span {
          color: ${p.theme.colors.text.third};
        }
      }
    `}

  .link {
    width: 100%;

    .expandable {
      display: flex;
      flex-direction: column;
    }

    .item {
      color: ${(p) => p.theme.colors.text.secondary};
      justify-content: space-between;
      display: flex;
      align-items: center;
      width: 100%;
      gap: 12px;

      & > div {
        align-items: center;
        display: flex;
        gap: 12px;
      }

      .arrow {
        transition: all 0.3s;

        &.show {
          transform: rotate(180deg);
        }
      }
    }
  }
`;

export const SidebarLabel = styled.span<{ drawer: boolean }>`
  color: ${(p) => p.theme.colors.text.secondary};
  font-size: ${(p) => p.theme.typography.subText5};
`;
