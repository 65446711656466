import { memo, useRef } from "react";

import Plus from "assets/svg/Plus";
import Restore from "assets/svg/Restore";
import { EmptyContentInputStyles } from "pages/public/chat/Components/Chat/AddContentModal/EmptyContentInput/styles";

interface EmptyContentInputProps {
  buttonText: string;
  hasError?: string;
  onFileSelected: (file: File) => void;
  acceptExtensions: string;
  className?: string;
}

export const EmptyContentInput = memo(
  ({
    buttonText,
    onFileSelected,
    hasError,
    className,
    acceptExtensions,
  }: EmptyContentInputProps) => {
    const ref = useRef<HTMLInputElement>(null);

    return (
      <EmptyContentInputStyles className={className}>
        {hasError ? (
          <>
            <Restore />
            <p>{hasError}</p>
          </>
        ) : (
          <>
            <Plus />
            <span>{buttonText}</span>
          </>
        )}
        <input
          ref={ref}
          id="file_upload"
          type="file"
          accept={acceptExtensions}
          onChange={(e) => {
            const file = e.target.files![0] as File | undefined;
            if (!file) return;
            onFileSelected(file);
            // clear input after uploading the file
            if (!ref.current) return;
            ref.current.value = "";
          }}
        />
      </EmptyContentInputStyles>
    );
  }
);
