import styled, { css } from "styled-components";

import { deviceQuery } from "styles/mediaSizes";
import { GreenDot, OutfitRegular } from "styles/mixins";

export const Wrapper = styled.section`
  max-width: 470px;
`;
export const SubHeading = styled.h4`
  font-size: 1rem;
  text-transform: uppercase;
  color: ${(p) => p.theme.colors.icon.secondary};
  font-family: ${(p) => p.theme.ff.outfit.bold};

  ${deviceQuery.mobileM} {
    padding: 0 2rem;
  }
`;
export const Heading = styled.h2`
  font-size: 3rem;
  text-transform: capitalize;
  color: ${(p) => p.theme.colors.text.primary};
  width: fit-content;
  font-family: my;
  margin: 1rem auto 0 0;
  font-family: ${(p) => p.theme.ff.outfit.bold};
  letter-spacing: -1px;

  span {
    display: inline-block;
    position: relative;

    &::before {
      ${GreenDot};
      right: -10px;
      bottom: 15px;
    }
  }

  ${deviceQuery.laptopS} {
    margin: 2rem auto 0;
    font-size: 2.5rem;
  }

  ${deviceQuery.mobileM} {
    font-size: 2rem;
  }
`;
export const Para = styled.p`
  margin: 2rem 0;
  color: ${(p) => p.theme.colors.text.primary};
  font-family: ${(p) => p.theme.ff.outfit.light};

  ${deviceQuery.laptopS} {
    margin: 1rem 0;
  }
`;
export const DotsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  ${(p) =>
    p.bottom &&
    css`
      display: none;
    `};

  ${deviceQuery.laptopS} {
    ${(p) =>
      p.top &&
      css`
        display: none;
      `};

    ${(p) =>
      p.bottom &&
      css`
        display: flex;
        margin: 1rem 0 2rem 0;
      `};
    justify-content: center;
  }
`;

export const Dot = styled.div`
  width: 15px;
  height: 15px;
  background: ${(p) => p.theme.colors.background.secondary};
  border-radius: 50%;

  &.selected {
    background: ${(p) => p.theme.colors.text.third};
  }
`;

export const MovingCard = styled.div`
  overflow: hidden;
  width: 200%;
  display: flex;
  height: 100%;

  > div {
    transition: all 0.3s ease;
    width: 100%;
  }
`;

export const SendEmailCard = styled.div`
  gap: 20px;
  flex-direction: column;
  display: flex;
  justify-content: center;
`;

export const NewPasswordBox = styled.div`
  gap: 20px;
  flex-direction: column;
  display: flex;
  justify-content: center;

  ${deviceQuery.mobileM} {
    padding-top: 40px;
  }
`;

export const ResendMsg = styled.p`
  ${OutfitRegular};
  color: ${(p) => (p.disabled ? p.theme.colors.text.secondary : p.theme.colors.text.third)};
  font-size: 0.85rem;
  margin: 5px 0;
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
  width: fit-content;

  ${(p) =>
    !p.disabled &&
    css`
      &:hover {
        text-decoration: underline;
      }
    `};
`;
