import { useEffect } from "react";

import { hideToast } from "store/slices/featureSlice";
import store from "store/store";

const useRemoveToast = () => {
  useEffect(() => {
    return () => {
      store.dispatch(hideToast());
    };
  }, []);
};

export default useRemoveToast;
