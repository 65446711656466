import React, { FC, useState } from "react";

import { AfterLoadImageContainer } from "./style";

interface Props {
  src: string;
  alt: string;
  className?: string;
  loading?: "eager" | "lazy";
}

const AfterLoadImage: FC<Props> = ({ src, alt, loading, className }) => {
  const fullyLoaded = useState<boolean>(false);
  return (
    <AfterLoadImageContainer>
      <img
        className={!fullyLoaded || !src ? "hidden" : undefined}
        src={src}
        loading={loading}
        alt={alt}
      />
    </AfterLoadImageContainer>
  );
};

export default AfterLoadImage;
