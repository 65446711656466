import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { LogoutButton } from "./style";

import Exit from "assets/svg/Exit";
import { clearSlicesData } from "common/helpers/helper";
import { CustomSocket } from "common/helpers/socket";
import { logout } from "store/slices/authSlice";
import { emitUserLogoutEvent } from "store/slices/eventBusSlice";

const LogoutComponent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const signOut = () => {
    clearSlicesData();
    dispatch(logout());
    dispatch(emitUserLogoutEvent({ randomId: Math.random() }));
    CustomSocket.socket.disconnect();
  };

  return (
    <LogoutButton onClick={signOut} className="nav-link text-center">
      <Exit />
      <span> {t("login.logout")}</span>
    </LogoutButton>
  );
};

export default LogoutComponent;
