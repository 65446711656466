export const setLocalStorageItem = ({ key, value }) => {
  const stringify = JSON.stringify(value);
  localStorage.setItem(key, stringify);
};

export const setLocalStorageItems = (arr) => {
  arr.forEach((item) => {
    setLocalStorageItem({ key: item.key, value: item.value });
  });
};

export const removeLocalStorageItem = ({ key }) => localStorage.removeItem(key);

export const getLocalStorageItem = ({ key }) => {
  const item = localStorage.getItem(key);
  return JSON.parse(item);
};

export const getLocalStorageItems = (arr) =>
  arr.map((item) => getLocalStorageItem({ key: item.key }));
