import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "store/store";

interface IInititalStateNotification {
  notificationCount: number;
  unreadNotification: number;
  notifications: {
    data: any[];
    total: number;
  };
}

const initialState: IInititalStateNotification = {
  notificationCount: 0,
  unreadNotification: 0,
  notifications: {
    data: [],
    total: 0,
  },
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    addNotification: (state, action) => {
      state.notifications.data = [{ ...action.payload, isNew: true }, ...state.notifications.data];
      state.notifications.total += 1;
    },
    setUnreadNotification: (state, action) => {
      state.unreadNotification = action.payload;
    },
    setNotificationCount: (state, action) => {
      state.notificationCount = action.payload;
    },
    setNotifications: (state, action) => {
      const { data, total } = action.payload;

      state.notifications = {
        total,
        data: [...state.notifications.data, ...data],
      };
    },
    resetNotifications: (state) => {
      state.notifications.data = [];
      state.notifications.total = 0;
    },
    setLatestNotifications: (state, action) => {
      const { data, total } = action.payload;
      state.notifications = {
        total,
        data: [...data, ...state.notifications.data],
      };
    },
    readNotification: (state, action) => {
      state.unreadNotification -= 1;
      state.notifications.data = state.notifications.data.map((item) => {
        if (item._id === action.payload) {
          return { ...item, isNew: false };
        }
        return item;
      });
    },
  },
});

export const {
  readNotification,
  setNotificationCount,
  setUnreadNotification,
  addNotification,
  setNotifications,
  setLatestNotifications,
  resetNotifications,
} = notificationSlice.actions;

export default notificationSlice.reducer;

export const getNotifications = ({ global: { notification } }: RootState) =>
  notification.notifications;
export const getNotificationCount = ({ global: { notification } }: RootState) =>
  notification.notificationCount;
export const getUnreadNotification = ({ global: { notification } }: RootState) =>
  notification.unreadNotification;
