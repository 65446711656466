import { FC, FocusEventHandler, useMemo } from "react";
import { useSelector } from "react-redux";
import Select, { SingleValue } from "react-select";

import { ErrorMsg, Label, Wrapper } from "./style";

import { Option } from "Components/SelectField/types";
import {
  selectStyleDark,
  selectStyleLight,
  sortSubmissionStyleDark,
  sortSubmissionStyleLight,
} from "constants/appConstants";
import { getPublicUser } from "store/slices/global/previewProfileSlice";
import { getUser } from "store/slices/global/userSlice";

interface Props {
  placeholder: string;
  sort: boolean;
  wrapperStyle: any;
  className: string;
  label: string;
  required: boolean;
  error: string | false;
  name: string;
  value: Option;
  maxMenuHeight: number;
  handleChange: (value: SingleValue<Option>) => void;
  handleBlur: FocusEventHandler<HTMLInputElement>;
  noNeedError: boolean;
  isDisabled: boolean;
  sizeAdjust: boolean;
  async: boolean;
  isSearchable: boolean;
  options: Option[];
  isLoading: boolean;
  isWarning: boolean;
  menuPlacement: "auto" | "top" | "bottom";
}

const SingleSelect: FC<Partial<Props>> = ({
  placeholder,
  menuPlacement = "auto",
  sort,
  maxMenuHeight,
  wrapperStyle,
  className,
  label,
  required,
  error,
  name,
  value,
  handleChange,
  handleBlur,
  isDisabled,
  isSearchable,
  options,
  isLoading,
  isWarning,
}) => {
  const user = useSelector(getUser);
  const publicUser = useSelector(getPublicUser);

  const theme = useMemo(() => {
    if (publicUser._id) {
      switch (publicUser.bioTheme) {
        case "dark":
          return sort ? sortSubmissionStyleDark : selectStyleDark;
        case "light":
          return sort ? sortSubmissionStyleLight : selectStyleLight;
        default:
          return sort ? sortSubmissionStyleLight : selectStyleLight;
      }
    }

    if (!user) return;
    switch (user.generalTheme) {
      case "dark":
        return sort ? sortSubmissionStyleDark : selectStyleDark;
      case "light":
        return sort ? sortSubmissionStyleLight : selectStyleLight;
      default:
        return sort ? sortSubmissionStyleLight : selectStyleLight;
    }
  }, [user]);

  return (
    <Wrapper style={{ ...wrapperStyle }} className={className || ""}>
      {label && (
        <Label htmlFor={label} isDisabled={isDisabled}>
          {required && <span className="green">*</span>}
          {label}
        </Label>
      )}
      <Select
        menuPlacement={menuPlacement}
        isMulti={false}
        styles={theme}
        isDisabled={isDisabled}
        isLoading={!!isLoading}
        isSearchable={isSearchable}
        placeholder={placeholder || ""}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        name={name}
        options={options}
        maxMenuHeight={maxMenuHeight}
      />
      {error && <ErrorMsg color={isWarning ? "#969696" : ""}>{error || ""}</ErrorMsg>}
    </Wrapper>
  );
};

export default SingleSelect;
