import { ChangeEvent, FC, memo, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { EditBlockContainer } from "./style";

import RemoveAvatarModal from "../../../RemoveAvatarModal";

import Delete from "assets/svg/Delete";
import Edit from "assets/svg/Edit";
import { dataURLtoFile, makeToast } from "common/helpers/helper";
import Button from "Components/Button";
import CropModal from "Components/CropModal";
import { STRINGS } from "constants/appConstants";

interface Props {
  title: string;
  description1: string;
  maxImageWidth?: number;
  minImageWidth?: number;
  downScaleToWidth?: number;
  aspectWidth: number;
  aspectHeight: number;
  description2?: string;
  pictureUrl: string | undefined;
  handleSubmit: (img: File) => void;
  handleDelete: () => void;
  hideRemove?: boolean;
}

const EditBlock: FC<Props> = memo(
  ({
    title,
    description1,
    maxImageWidth,
    minImageWidth,
    aspectHeight,
    aspectWidth,
    downScaleToWidth,
    description2,
    handleSubmit,
    pictureUrl,
    hideRemove = false,
    handleDelete,
  }) => {
    const { t } = useTranslation();
    const profileInputRef = useRef<HTMLInputElement>(null);
    const [showModal, setShowModal] = useState(false);
    const [showCropModal, setShowCropModal] = useState(false);
    const [showRemoveBtn, setShowRemoveBtn] = useState(false);
    const [uploadCount, setUploadCount] = useState(0);
    const [image, setImage] = useState<string | null>(null);

    useEffect(() => {
      if (pictureUrl) {
        setShowRemoveBtn(true);
        if (uploadCount === 0) {
          setTimeout(() => {
            setUploadCount(100);
          }, 1000);
        }
      } else {
        setShowRemoveBtn(false);
        if (uploadCount === 100) {
          setUploadCount(0);
        }
      }
    }, [pictureUrl, uploadCount]);

    const closeFundModal = () => {
      setShowModal(false);
    };

    const onImageChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const eggFiles = e.target.files[0] as File;
        const eggFileSize = eggFiles.size / 1024 / 1024;
        const extension = eggFiles.name.split(".").pop()!;
        const fileExtension = extension.toLowerCase();
        if (eggFileSize < 10) {
          if (fileExtension === "png" || fileExtension === "jpeg" || fileExtension === "jpg") {
            setShowRemoveBtn(true);

            const reader = new FileReader();
            reader.onload = () => {
              setImage(reader.result as any);
            };
            reader.readAsDataURL(eggFiles);
            setShowCropModal(true);
          } else if (fileExtension === "gif") {
            setShowRemoveBtn(true);
            handleSubmit(eggFiles);
            setImage(null);
          } else {
            makeToast({
              message: t("common.file_upload.pro_pic_type_warning"),
              type: STRINGS.toastType.error,
            });
          }
        } else {
          makeToast({
            message: t("common.file_upload.upload_size_warning"),
            type: STRINGS.toastType.error,
          });
        }
        if (profileInputRef.current) {
          profileInputRef.current.value = "";
        }
      }
    };

    const onDelete = () => {
      handleDelete();
      setShowModal(false);
    };

    const onCropperSubmit = (img: string) => {
      const pictureAsFile = dataURLtoFile(img, Date.now().toString());
      handleSubmit(pictureAsFile);
      setShowCropModal(false);
      setImage(null);
    };

    const onCropperCancel = () => {
      setImage(null);
      setShowCropModal(false);

      // if there is a prev image, show edit and delete btn
      setShowRemoveBtn(Boolean(pictureUrl));
    };

    return (
      <>
        <EditBlockContainer>
          <div className="info">
            <p className="title">{title}</p>
            <p>
              {description1} {description2 && <span>{description2}</span>}
            </p>
          </div>
          <div className="actions">
            {showRemoveBtn && !hideRemove ? (
              <div className="buttons">
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    if (profileInputRef.current) {
                      profileInputRef.current.click();
                    }
                  }}
                >
                  <Edit />
                </div>
                <div className="divider" />
                <div className="delete cursor-pointer" onClick={() => setShowModal(true)}>
                  <Delete />
                </div>
              </div>
            ) : (
              <Button
                variant="primary"
                size="sm"
                type="button"
                onClick={() => {
                  if (profileInputRef.current) {
                    profileInputRef.current.click();
                  }
                }}
              >
                {t("common.upload_photo")}
              </Button>
            )}
          </div>
          <input
            ref={profileInputRef}
            onChange={(e) => onImageChange(e)}
            value=""
            type="file"
            accept="image/png, image/jpeg, image/jpg, image/gif"
            hidden
          />
        </EditBlockContainer>
        <RemoveAvatarModal
          show={showModal}
          closeModal={() => closeFundModal()}
          onConfirmDelete={onDelete}
        />
        {showCropModal && image && (
          <CropModal
            aspectHeight={aspectHeight}
            aspectWidth={aspectWidth}
            maxWidth={maxImageWidth}
            minWidth={minImageWidth}
            downScaleToWidth={downScaleToWidth}
            descriptionHeight={t("common.maxHeightBanner")}
            descriptionWidth={t("common.maxWidthBanner")}
            areaDesc={t("common.desktopBanner")}
            areaDescMobile={t("common.mobileBanner")}
            show={showCropModal}
            image={image}
            closeModal={() => {
              onCropperCancel();
            }}
            onConfirm={onCropperSubmit}
          />
        )}
      </>
    );
  }
);

export default EditBlock;
