import ReactGA from "react-ga";
import ReactGA4 from "react-ga4";

const TrackGoogleAnalyticsEvent = (category: string, action: string) => {
  // Send UA Event
  ReactGA.event({
    category,
    action,
  });
  // Send GA4 Event
  ReactGA4.event({
    category,
    action,
  });
};

export default TrackGoogleAnalyticsEvent;
