const CoinLoading = () => (
  <div className="purse">
    <div className="coin">
      <div className="front">
        <div className="one" />
        <div className="two" />
        <div className="three" />
      </div>
      <div className="back">
        <div className="one" />
        <div className="two" />
        <div className="three" />
      </div>
      <div className="side">
        <div className="spoke" />
        <div className="spoke" />
        <div className="spoke" />
        <div className="spoke" />
        <div className="spoke" />
        <div className="spoke" />
        <div className="spoke" />
        <div className="spoke" />
        <div className="spoke" />
        <div className="spoke" />
        <div className="spoke" />
        <div className="spoke" />
        <div className="spoke" />
        <div className="spoke" />
        <div className="spoke" />
        <div className="spoke" />
      </div>
    </div>
  </div>
);

export default CoinLoading;
