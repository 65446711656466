import { ChangeEvent, FC, memo, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { useDispatch } from "react-redux";

import { ChangeAvatarContainer } from "./style";

import Check from "assets/svg/Check";
import { dataURLtoFile, makeToast } from "common/helpers/helper";
import Avatar from "Components/Avatar";
import Button from "Components/Button";
import CropModal from "Components/CropModal";
import { ROLES, STRINGS } from "constants/appConstants";
import RemoveAvatarModal from "pages/creator/EditProfile/components/General/components/RemoveAvatarModal";
import { onFanImageRemove, updateProfileInfoAPI, updateProfilePic } from "services/UserServices";
import { removeProfilePicture } from "store/slices/global/userSlice";
import { BlockCard } from "styles/mixins";

interface Props {
  pictureUrl: string;
}

const ChangeAvatar: FC<Props> = memo(({ pictureUrl }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [image, setImage] = useState<string | null>();
  const [showModal, setShowModal] = useState(false);
  const [showRemoveBtn, setShowRemoveBtn] = useState(false);
  const [uploadCount, setUploadCount] = useState(0);
  const [showCropModal, setShowCropModal] = useState(false);
  const profileInputRef = useRef<HTMLInputElement>(null);

  const handleSubmitAvatar = (image: File) => {
    const mediaData = new FormData();
    mediaData.append("image", image);

    updateProfilePic({ apiData: mediaData }, (res: any) => {
      updateProfileInfoAPI({
        data: {
          pictureUrl: res.key,
        },
        part: "general",
      });
    });
  };

  const onImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const eggFiles = e.target.files[0] as File;
      const eggFileSize = eggFiles.size / 1024 / 1024;
      const extension = eggFiles.name.split(".").pop()!;
      const fileExtension = extension.toLowerCase();
      if (eggFileSize < 10) {
        if (fileExtension === "png" || fileExtension === "jpeg" || fileExtension === "jpg") {
          setShowRemoveBtn(true);
          const reader = new FileReader();
          reader.onload = () => {
            setImage(reader.result as any);
          };
          reader.readAsDataURL(eggFiles);
          setShowCropModal(true);
        } else if (fileExtension === "gif") {
          setShowRemoveBtn(true);
          handleSubmitAvatar(eggFiles);
          setImage(null);
        } else {
          makeToast({
            message: t("common.file_upload.pro_pic_type_warning"),
            type: STRINGS.toastType.error,
          });
        }
      } else {
        makeToast({
          message: t("common.file_upload.upload_size_warning"),
          type: STRINGS.toastType.error,
        });
      }
      if (profileInputRef.current) {
        profileInputRef.current.value = "";
      }
    }
  };

  const onCropperSubmit = (img: string) => {
    const pictureAsFile = dataURLtoFile(img, Date.now().toString());
    handleSubmitAvatar(pictureAsFile);
    setShowCropModal(false);
    setImage(null);
  };

  useEffect(() => {
    if (pictureUrl) {
      setShowRemoveBtn(true);
      if (uploadCount === 0) {
        setTimeout(() => {
          setUploadCount(100);
        }, 1000);
      }
    } else {
      setShowRemoveBtn(false);
      if (uploadCount === 100) {
        setUploadCount(0);
      }
    }
  }, [pictureUrl, uploadCount]);

  const onDelete = () => {
    onFanImageRemove();
    dispatch(removeProfilePicture());
    setImage(null);
    setShowModal(false);
  };

  return (
    <ChangeAvatarContainer>
      <BlockCard className="card">
        <p className="title">{t("common.editAvatarFan")}</p>
        <div className="preview">
          <div className="image">
            <Avatar size="lg" src={image || pictureUrl} role={ROLES.fan} alt="avatar" />
            <div className="actions">
              <Button
                variant="primary"
                size="sm"
                full
                type="button"
                onClick={() => {
                  if (profileInputRef.current) {
                    profileInputRef.current.click();
                  }
                }}
              >
                {t("common.upload_photo")}
              </Button>
              <Button
                full
                variant="secondary"
                disabled={!showRemoveBtn}
                size="sm"
                type="button"
                onClick={() => setShowModal(true)}
              >
                {t("common.delete")}
              </Button>
            </div>
          </div>
          <div className="infoContainer">
            <div className="infoBlock">
              <Check currentColor />
              <div className="rule">
                <p>{t("common.user_profile.recommend_size")}:</p>
                <p>{t("common.user_profile.size_per_side")}</p>
              </div>
            </div>
            <div className="divider" />
            <div className="infoBlock">
              <Check currentColor />
              <div className="rule">
                <p>{t("common.user_profile.file_type")}:</p>
                <p>{t("common.user_profile.file_type_list")}</p>
              </div>
            </div>
          </div>
        </div>
      </BlockCard>
      <input
        ref={profileInputRef}
        onChange={(e) => onImageChange(e)}
        value=""
        type="file"
        accept="image/png, image/jpeg, image/jpg, image/gif"
        hidden
      />
      <RemoveAvatarModal
        show={showModal}
        closeModal={() => setShowModal(false)}
        onConfirmDelete={onDelete}
      />
      {showCropModal && image && (
        <CropModal
          aspectHeight={1}
          aspectWidth={1}
          maxWidth={0}
          minWidth={0}
          downScaleToWidth={200}
          descriptionHeight={t("common.maxHeightBanner")}
          descriptionWidth={t("common.maxWidthBanner")}
          areaDesc={t("common.desktopBanner")}
          areaDescMobile={t("common.mobileBanner")}
          show={showCropModal}
          image={image}
          closeModal={() => setShowCropModal(false)}
          onConfirm={onCropperSubmit}
        />
      )}
    </ChangeAvatarContainer>
  );
});

export default ChangeAvatar;
