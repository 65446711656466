import { useTranslation } from "react-i18next";

const TikTok = (props) => {
  const { t } = useTranslation();
  return (
    <svg
      width={22}
      height={22}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      fill={props.color || "currentColor"}
      {...props}
    >
      <path fill="none" d="M0 0H256V256H0z" />
      <title>{t("common.user_profile.tiktok")}</title>
      <path d="M232 84v40a8 8 0 01-8 8 103.2 103.2 0 01-48-11.7V156a76 76 0 11-89.4-74.8 8 8 0 016.5 1.7 7.8 7.8 0 012.9 6.2v41.6a7.9 7.9 0 01-4.6 7.2A20 20 0 10120 156V28a8 8 0 018-8h40a8 8 0 018 8 48 48 0 0048 48 8 8 0 018 8z" />
    </svg>
  );
};

export default TikTok;
