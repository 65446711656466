import { GoogleLogin, GoogleLoginProps } from "@react-oauth/google";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { GoogleButtonContainer } from "./styles";

export const GoogleLoginButton: FC<GoogleLoginProps> = (props) => {
  const { t } = useTranslation();

  return (
    <GoogleButtonContainer text={t("auth.google")}>
      <GoogleLogin
        {...props}
        containerProps={{
          className: "google_custom_button",
        }}
      />
    </GoogleButtonContainer>
  );
};
