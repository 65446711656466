import React, { FC } from "react";

import { Progress, UploadProgressBarStyles } from "./style";

interface Props {
  progress: number;
  message?: string;
  className?: string;
}

export const UploadProgressBar: FC<Props> = ({ message, progress, className }) => {
  return (
    <UploadProgressBarStyles className={className}>
      {message && <div className="message">{message}</div>}
      <Progress progress={progress} />
    </UploadProgressBarStyles>
  );
};
