import { FC, memo, useEffect, useRef, useState } from "react";
import Cropper from "react-cropper";
import { useTranslation } from "react-i18next";

import { Content, CropModalContainer, ValueBlock } from "./style";

import "cropperjs/dist/cropper.css";

import { downscaleImage } from "common/downscale-image";
import Button from "Components/Button";
import { ErrorMsg } from "Components/Input/style";
import { SimpleRangeInput } from "Components/SimpleRangeInput";

const MIN_ROTATE_DEG = -180;
const MAX_ROTATE_DEG = 180;
const ROTATE_BY_STEP = 1;

interface Props {
  show: boolean;
  image: string;
  minWidth?: number;
  maxWidth?: number;
  downScaleToWidth?: number;
  aspectHeight: number;
  aspectWidth: number;
  descriptionHeight?: string;
  descriptionWidth?: string;
  areaDesc: string;
  areaDescMobile?: string;
  closeModal: () => void;
  onConfirm: (img: string) => void;
}

interface CustomCropper extends Cropper {
  minSupportedZoom: number;
  maxSupportedZoom: number;
}

const CropModal: FC<Props> = memo(
  ({
    show,
    image,
    areaDesc,
    areaDescMobile = "",
    aspectHeight,
    aspectWidth,
    minWidth,
    maxWidth,
    downScaleToWidth,
    closeModal,
    onConfirm,
  }) => {
    const { t } = useTranslation();
    const cropperRef = useRef<any>(null);
    const [width, setWidth] = useState(0);
    const [rotate, setRotate] = useState(0);

    const handleConfirm = () => {
      if (typeof cropperRef.current?.cropper !== "undefined") {
        const cropperImageAsBase64 = cropperRef.current?.cropper.getCroppedCanvas().toDataURL();
        if (downScaleToWidth) {
          downscaleImage(cropperImageAsBase64, downScaleToWidth, (image) => {
            onConfirm(image);
          });
          return;
        }

        onConfirm(cropperImageAsBase64);
      }
    };

    useEffect(() => {
      handleRotate(rotate);
    }, [rotate]);

    const handleRotate = (value: number) => {
      cropperRef.current?.cropper?.rotateTo(value);
    };

    const handleZoom = (event: any) => {
      const cropper = event.target?.cropper as CustomCropper;
      if (!cropper) return;

      const croppedImageWidthTest = event.target.cropper.getData().width;

      if (event.type === "zoom" && event.detail) {
        const detail: Cropper.ZoomEventData = event.detail;
        const currentZoom = detail.oldRatio;
        const nextZoom = detail.ratio;

        const maxSupportedZoom = cropper.maxSupportedZoom;
        const minSupportedZoom = cropper.minSupportedZoom;

        // only for wheel event
        // handle case if zoom step doesn't allow to zoom to the smallest or biggest point, we calculate and zoom it manually
        if (detail?.originalEvent?.type === "wheel") {
          if (nextZoom > minSupportedZoom) {
            const step = nextZoom - currentZoom;

            if (nextZoom - minSupportedZoom < step) {
              cropper.zoomTo(minSupportedZoom);
            }
          }

          // if we zoom out currentZoom is greater that nextZoom
          if (currentZoom > maxSupportedZoom) {
            const step = currentZoom - nextZoom;

            if (currentZoom - maxSupportedZoom < step) {
              cropper.zoomTo(maxSupportedZoom);
            }
          }
        }

        if (!nextZoom || nextZoom > minSupportedZoom || nextZoom < maxSupportedZoom) {
          event.preventDefault();
        }
      }
      setWidth(Math.ceil(croppedImageWidthTest));
    };

    const onCanvasReady = () => {
      const cropper = cropperRef.current.cropper as CustomCropper;
      if (!cropper) return;
      if (!minWidth || !maxWidth) return;
      const cropperCanvasWidth = cropper.getCanvasData().width;
      const naturalWidth = cropper.getImageData().naturalWidth;

      const minSupportedZoom = cropperCanvasWidth / minWidth;
      const maxSupportedZoom = cropperCanvasWidth / maxWidth;

      cropper.minSupportedZoom = minSupportedZoom;
      cropper.maxSupportedZoom = maxSupportedZoom;

      if (naturalWidth > maxWidth) {
        cropper.zoomTo(maxSupportedZoom);
        setWidth(maxWidth);
        return;
      }

      setWidth(naturalWidth);
    };

    const hasMinMax = (): boolean => {
      return Boolean(minWidth && maxWidth);
    };

    const isConfirmDisabled = () => {
      if (!maxWidth || !minWidth) return false;
      return width > maxWidth || width < minWidth;
    };

    return (
      <CropModalContainer show={show} showCloseButton onHide={() => closeModal()}>
        <p className="title">{t("common.cropImage")}</p>
        <Content lines={!!areaDescMobile}>
          <Cropper
            ref={cropperRef}
            className="editor"
            dragMode="move"
            initialAspectRatio={aspectWidth / aspectHeight}
            aspectRatio={aspectWidth / aspectHeight}
            cropBoxResizable={false}
            ready={onCanvasReady}
            zoom={handleZoom}
            src={image}
            viewMode={1}
            autoCropArea={1}
            background={false}
            checkOrientation={false}
            toggleDragModeOnDblclick={false}
            rotatable
            responsive
            guides
          />
          <div className="actions">
            <div className="info_block">
              {hasMinMax() && (
                <div className="info_block_child">
                  <div className="width_block">
                    <div> {t("common.current_width")}</div>
                    <div className="value_info">
                      {(width > maxWidth! || width < minWidth!) && (
                        <ErrorMsg>Invalid width</ErrorMsg>
                      )}
                      <ValueBlock error={width > maxWidth! || width < minWidth!}>
                        {width}
                      </ValueBlock>
                    </div>
                  </div>
                  <div className="width_info">{t("common.maxWidthBanner")}</div>
                </div>
              )}

              <div className="info_block_child">
                <div className="width_block">
                  <div> {t("common.rotate")}</div>

                  <div className="value_info">
                    <Button
                      variant="secondary"
                      className="reset_btn"
                      onClick={() => {
                        setRotate(0);
                      }}
                    >
                      {t("common.reset")}
                    </Button>
                    <ValueBlock error={false}>{rotate}</ValueBlock>
                  </div>
                </div>

                <SimpleRangeInput
                  min={MIN_ROTATE_DEG}
                  max={MAX_ROTATE_DEG}
                  step={ROTATE_BY_STEP}
                  value={rotate}
                  valueChange={setRotate}
                />
              </div>
            </div>

            <div className="description">
              <div className="info">
                <span>{areaDesc}</span>
                <div className="rect desktop" />
              </div>

              {areaDescMobile && (
                <div className="info">
                  <span>{areaDescMobile}</span>
                  <div className="rect mobile" />
                </div>
              )}
            </div>
            <div className="buttons">
              <Button variant="secondary" full onClick={() => closeModal()}>
                {t("common.cancel")}
              </Button>
              <Button variant="primary" disabled={isConfirmDisabled()} full onClick={handleConfirm}>
                {t("common.save")}
              </Button>
            </div>
          </div>
        </Content>
      </CropModalContainer>
    );
  }
);

export default CropModal;
