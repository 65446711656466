import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { Option } from "Components/SelectField/types";

import { RootState } from "store/store";
import { IPlans, IPost, IPublicUser, IWallOfFame } from "types/previewProfile";
import { IWallSettings } from "types/user";

interface IInititalState {
  userData: IPublicUser;
  assets?: Option[];
  feed: {
    data: IPost[];
    total: number;
    subscribedFeedCount: number;
  };
  plans: {
    data: IPlans[];
    total: number;
  };
  wallOfFame: IWallOfFame[];
  wallSettings: IWallSettings;
}

const initialState: IInititalState = {
  userData: {
    bioShowSendGift: true,
    _id: "",
    bioTheme: "light",
    sensitive: false,
    lastLoginAt: "",
    aboutMe: "",
    pictureUrl_100_100: "",
    displayname: "",
    level: 0,
    picture: "",
    role: "",
    socialmedia: [],
    publicLinks: [],
    username: "",
    walletAssets: [],
    walletId: "",
  },
  feed: {
    data: [],
    total: 0,
    subscribedFeedCount: 0,
  },
  plans: {
    data: [],
    total: 0,
  },
  wallOfFame: [],
  wallSettings: {
    show: false,
    showAmount: true,
    period: "",
    topSupportersNames: {
      gold: "",
      silver: "",
      bronze: "",
    },
  },
};

const previewProfileSlice = createSlice({
  name: "previewProfile",
  initialState,
  reducers: {
    setPublicUser: (state, action: PayloadAction<IPublicUser>) => {
      state.userData = action.payload;
      state.assets = action.payload.walletAssets.map((item) => {
        return {
          value: item.address,
          label: item.name,
        };
      });
    },
    setWallOfFame: (state, action) => {
      state.wallOfFame = action.payload;
    },
    setWallSettings: (state, action) => {
      state.wallSettings = action.payload;
    },
    setPlans: (state, action) => {
      if (action.payload.replaceAllPlans) {
        state.plans.data = action.payload.data;
      } else {
        state.plans.data = [...state.plans.data, ...action.payload.data];
      }
      state.plans.total = action.payload.total;
      state.feed.subscribedFeedCount = action.payload.subscribedFeedCount;
    },
    subscribePreviewPlan: (state, action) => {
      state.plans.data = state.plans.data.map((item) => {
        if (item._id === action.payload.id) {
          return {
            ...item,
            subscribed: true,
            mySubscription: action.payload.subscription,
          };
        }
        return item;
      });
    },
    setFeed: (state, action) => {
      state.feed.data = [...state.feed.data, ...action.payload.data];
      state.feed.total = action.payload.total;
    },
    resetPlans: (state) => {
      state.plans.data = [];
    },
    resetFeed: (state) => {
      state.feed.data = [];
    },
    resetWallOfFame: (state) => {
      state.wallOfFame = [];
      state.wallSettings = {
        show: true,
        showAmount: true,
        period: "monthly",
        topSupportersNames: {
          gold: "",
          silver: "",
          bronze: "",
        },
      };
    },
    resetPublicUser: () => {
      return { ...initialState };
    },
  },
});

export const {
  setPublicUser,
  setWallOfFame,
  setWallSettings,
  setPlans,
  setFeed,
  resetPlans,
  subscribePreviewPlan,
  resetFeed,
  resetPublicUser,
  resetWallOfFame,
} = previewProfileSlice.actions;

export default previewProfileSlice.reducer;

export const getPublicUser = ({ global }: RootState) => global.publicUser.userData;
export const getPublicAssets = ({ global }: RootState) => global.publicUser.assets;
export const getPublicPlans = ({ global }: RootState) => global.publicUser.plans;
export const getPublicFeed = ({ global }: RootState) => global.publicUser.feed;
export const getWallOfFame = ({ global }: RootState) => global.publicUser.wallOfFame;
export const getWallSettigns = ({ global }: RootState) => global.publicUser.wallSettings;
