const Campaigns = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={props.color || "currentColor"}
    width="22"
    height="25"
  >
    <defs>
      <filter id="joiysuhnaa">
        <feFlood
          className="flood-color campaigns"
          floodColor="currentColor"
          floodOpacity="1"
          result="floodOut"
        />
        <feComposite operator="atop" in="floodOut" in2="SourceGraphic" result="compOut" />
        <feBlend in="compOut" in2="SourceGraphic" />
      </filter>
    </defs>
    <g filter="url(#joiysuhnaa)">
      <path
        className="fill campaigns"
        fillRule="evenodd"
        fill="currentColor"
        d="m21.8 7.343.17.592c0 .046-.003.345.001.815.659.62-.237 1.079-.471 1.707-.617.105-.756.192-.894.291l.07.243c1.064 1.385.778 2.776 1.202 4.154.462.509-.138 1.815-.723 1.465-2.784-.711-5.57-.852-8.355-1-.214-.011-.429-.018-.663-.027l.075.262c.456 1.59.917 3.375 1.366 4.768a2.877 2.877 0 0 1-1.286 3.26c-.12.074.188.287-.366.997-.223-.719-.447-.654-.67-.591-.02-.004-.04-.013-.059-.011-1.659.139-2.817-.662-3.277-1.968-.47-1.932-.939-3.568-1.408-4.33-.03-.975-.031-1.08-.202-1.061-.935.231-1.873.191-2.809.296-.322.037-.594-.02-.8-.285C2.37 14.607 1.373 12.294.71 9.982c.025-.327.201-.53.497-.665.353-.16.691-.356 1.041-.526C3.57 8.398 3.847 7.958 4.72 7.71c2.18-.367 2.576-.736 3.861-1.111.141-.041.281-.109.401-.193 2.308-1.613 4.614-3.231 6.919-4.849.349-.246.69-.017 1.035-.755.474-.03.211-.06.316-.09.38.048.564.276.664.639.385 1.394.795 2.781 1.187 4.172.043.153.107.196.264.202.953.033 2.233.456 2.218 1.253.078.982.144.243.215.365zm-1.328 7.442c-1.191-4.153-1.512-8.281-3.564-12.421-.075.048-.139.086-.199.129-2.274 2.367-4.546 3.188-6.822 4.778-.127.088-.138.167-.099.303.63 2.18 1.642 4.361 1.88 6.542l.06.207 8.744.462zM7.659 16.616c.517 1.802 1.006 3.587 1.548 5.356.249.817 1.138 1.253 1.956 1.268.939-.447 1.491-1.365 1.229-2.295-.482-1.709-.977-3.414-1.467-5.121-.012-.044-.033-.084-.052-.13-1.073.506-2.134.612-3.214.922zm-1.485-.911-1.88-6.552c-.689.342-1.357.682-2.033 1.005-.158.075.509.145-.136.308.511 1.758 1.013 3.518 1.518 5.277l.062.215 2.469-.253zm4.315-1.2c-.635-2.211-1.265-3.525-1.897-6.61-.32.092-.63.855-.949.272l1.897 6.61.949-.272zm-2.156.622c-.635-2.214-.653-4.411-1.896-6.611l-.937.27 1.897 6.61c.315-.091.733-.178.936-.269zm11.915-5.669c.416-.287.611-.923.46-1.459-.155-.547-.218-.869-1.168-1.009.235.821 1.36 1.637.708 2.468z"
      />
    </g>
  </svg>
);

export default Campaigns;
