import moment from "moment";
import { memo, useCallback, useMemo, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";

import MiniLoader from "Components/MiniLoader";
import {
  ChatAreaContainerStyles,
  ChatAreaMessagesListStyles,
  ChatAreaScrollableStyles,
} from "pages/public/chat/Components/Chat/ChatArea/styles";
import { GroupedMessagesDateLabel } from "pages/public/chat/Components/Chat/GroupedMessagesDateLabel";
import { MessageContainer } from "pages/public/chat/Components/Chat/MessageContainer";
import { getChatsSearch } from "store/slices/global/chatsSlice";
import { getUser } from "store/slices/global/userSlice";
import {
  MESSAGES_LIMIT,
  selectCanLoadMoreNext,
  selectCanLoadMorePrev,
  selectSearchMessageId,
  setRepliedMessage,
} from "store/slices/newMessages/messagesSlice";
import { getChatMessagesByChatId } from "store/thunks/messages-thunks";
import { colors } from "styles/theme";
import { MOMENT_TO_I18N } from "types/language.types";
import { NewMessageItem } from "types/new-messages.types";

function groupMessagesByDay(messages: NewMessageItem[]): [string, NewMessageItem[]][] {
  const groupedMessages = new Map<string, NewMessageItem[]>();

  messages.forEach((message: NewMessageItem) => {
    const createdAtDate = message.createdAt.split("T")[0]; // Extract date part
    if (!groupedMessages.has(createdAtDate)) {
      groupedMessages.set(createdAtDate, [message]); // Create new entry with date key
    } else {
      groupedMessages.get(createdAtDate)?.push(message); // Add message to existing entry
    }
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return [...groupedMessages.entries()];
}

interface Props {
  messages: NewMessageItem[];
  isLoading: boolean;
  scrollContainerRef: any;
  goToSearchedMessage: (searchMessageId: string) => void;
}

const MESSAGES_BEFORE_TO_TRIGGER_LOAD = 10;

export const ChatArea = memo(
  ({ messages, isLoading, scrollContainerRef, goToSearchedMessage }: Props) => {
    const topLoadMoreRef = useRef<IntersectionObserver>(null);
    const bottomLoadMoreRef = useRef<IntersectionObserver>(null);
    const dispatch = useDispatch();

    const user = useSelector(getUser);
    const canLoadMorePrev = useSelector(selectCanLoadMorePrev);
    const canLoadMoreNext = useSelector(selectCanLoadMoreNext);
    const searchMessageId = useSelector(selectSearchMessageId);
    const search = useSelector(getChatsSearch);

    const groupedMessages = useMemo<ReturnType<typeof groupMessagesByDay>>(() => {
      return groupMessagesByDay(messages);
    }, [messages]);

    const topMessageChatRef = useCallback(
      (chat: HTMLDivElement) => {
        if (topLoadMoreRef.current) topLoadMoreRef.current.disconnect();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        topLoadMoreRef.current = new IntersectionObserver((intersection) => {
          const entry = intersection[0];

          if (entry.isIntersecting && canLoadMorePrev && !isLoading) {
            const firstMessageOfTheFirstGroup = groupedMessages[0][1][0];
            dispatch(
              getChatMessagesByChatId({
                chatId: firstMessageOfTheFirstGroup.chatId,
                limit: MESSAGES_LIMIT,
                prevOffsetId: firstMessageOfTheFirstGroup._id,
              })
            );
          }
        });

        if (chat) topLoadMoreRef.current.observe(chat);
      },
      [groupedMessages, canLoadMorePrev, isLoading]
    );

    const bottomMessageChatRef = useCallback(
      (chat: HTMLDivElement) => {
        let timoutID: number | undefined;
        if (bottomLoadMoreRef.current) {
          bottomLoadMoreRef.current.disconnect();
          timoutID && clearTimeout(timoutID);
        }

        // we need to add small delay in case if we scroll to some element to omit triggering intersection
        timoutID = setTimeout(() => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          bottomLoadMoreRef.current = new IntersectionObserver((intersection) => {
            const entry = intersection[0];

            if (entry.isIntersecting && canLoadMoreNext && !isLoading) {
              const lastBottomGroup = groupedMessages[groupedMessages.length - 1][1];
              const lastMessageOfTheLastGroup = lastBottomGroup[lastBottomGroup.length - 1];
              dispatch(
                getChatMessagesByChatId({
                  chatId: lastMessageOfTheLastGroup.chatId,
                  limit: MESSAGES_LIMIT,
                  nextOffsetId: lastMessageOfTheLastGroup._id,
                })
              );
            }
          });

          if (chat) bottomLoadMoreRef.current.observe(chat);
        }, 200) as unknown as number;
      },
      [groupedMessages, canLoadMoreNext, isLoading]
    );

    const getRefForMessageByIndex = (
      messageIndex: number,
      groupIndex: number,
      groupLength: number
    ): undefined | typeof topMessageChatRef => {
      // returns ref for message if we scroll top
      if (groupIndex === 0) {
        if (!canLoadMorePrev) return; // no point to add observer

        // if less than MESSAGES_BEFORE_TO_TRIGGER_LOAD messages apply trigger for the last message
        if (groupLength < MESSAGES_BEFORE_TO_TRIGGER_LOAD && messageIndex === 0) {
          return topMessageChatRef;
        }
        if (messageIndex === MESSAGES_BEFORE_TO_TRIGGER_LOAD - 1) {
          return topMessageChatRef;
        }
      }
      // returns ref for message if we scroll bottom
      if (groupIndex === groupedMessages.length - 1) {
        if (!canLoadMoreNext) return; // no point to add observer

        if (groupLength < MESSAGES_BEFORE_TO_TRIGGER_LOAD && messageIndex === groupLength - 1) {
          return bottomMessageChatRef;
        }
        if (messageIndex === groupLength - MESSAGES_BEFORE_TO_TRIGGER_LOAD - 1) {
          return bottomMessageChatRef;
        }
      }

      return undefined;
    };

    const setMessageAsReplyTo = (message: NewMessageItem) => {
      dispatch(setRepliedMessage(message));
    };

    if (!user) return null;

    return (
      <ChatAreaContainerStyles>
        <ChatAreaScrollableStyles ref={scrollContainerRef}>
          <div className="message_render_container">
            {!groupedMessages.length && isLoading && <MiniLoader color={colors.green.primary} />}

            {groupedMessages.map(([groupDate, messagesByDate], groupIndex) => (
              <ChatAreaMessagesListStyles key={groupDate}>
                <GroupedMessagesDateLabel
                  date={moment(groupDate).locale(MOMENT_TO_I18N[user.languages]).format("MMMM D")}
                />
                {messagesByDate.map((message, messageIndex) => (
                  <MessageContainer
                    ref={getRefForMessageByIndex(messageIndex, groupIndex, messagesByDate.length)}
                    key={message._id}
                    message={message}
                    highlighted={searchMessageId === message._id}
                    isYours={message.userId === user?._id}
                    replyToMessage={setMessageAsReplyTo}
                    goToReplied={goToSearchedMessage}
                    search={search}
                  />
                ))}
              </ChatAreaMessagesListStyles>
            ))}
          </div>
        </ChatAreaScrollableStyles>
      </ChatAreaContainerStyles>
    );
  }
);
